<template>
  <div
    class="resultprev"
    :class="{
      'mobile-preview': mobileView,
    }"
  >
    <div class="prev_image px-0 m-0 pb-0 position-relative">
      <span class="info-image">
        <img src="@/assets/displayIcon.svg" />
      </span>
      <span class="pb-0">
        <span class="pb-0 ad-asset-nocontent" v-if="adImage == null"> </span>
        <span class="pb-0" v-else>
          <img
            :src="adImage.url"
            class="ad-asset"
            :class="{ 'ad-image-mobile': mobileView }"
          />
        </span>
      </span>
    </div>
    <div class="prev_info">
      <div
        class="content"
        :class="{
          prev_info_text_mobile: mobileView,
          prev_info_text: !mobileView,
        }"
      >
        <span class="prev_info_headline" v-if="headline">
          {{ headline }}
        </span>
        <span class="prev_info_headline" v-else>
          {{ headlinePlaceholder }}
        </span>
      </div>
      <div class="d-flex">
        <div class="logo">
          <img :src="logo.url" class="logo-img" v-if="logo" />
          <img src="@/assets/logo-sample.png" class="logo-img" v-else />
        </div>
        <div class="prev_text">
          <span
            :class="{
              'intro-text-mobile': mobileView,
            }"
          >
            {{ introText ? introText : introTextPlaceholder }}
          </span>
        </div>
        <div class="ms-auto">
          <canvas
            height="100"
            width="100"
            x-ns-pubgt-e="12"
            x-score="1"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultprev {
  background: #fff;
  border: 1px solid rgb(219, 219, 219);
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  margin: 0 auto;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Lucida Grande, Helvetica, Arial, sans-serif;
  .prev_image {
    .info-image {
      position: absolute;
      width: 30px;
      right: 0;
      img {
        width: 100%;
      }
    }
    background: #e1e1e1;
    .ad-asset-nocontent {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: inline-block;
    }
    .ad-asset {
      width: 100%;
    }
    .ad-asset.ad-image-mobile {
      overflow: hidden;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .prev_info {
    align-items: flex-start;
    border-top: 1px solid #e6e9ec;
    flex-grow: 1;
    justify-content: space-between;
    padding: 8px 12px;
    overflow-wrap: break-word;
    .prev_info_text_mobile {
      max-width: 250px;
      .prev_info_headline {
        max-height: 22px;
      }
    }
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .prev_info_headline {
        color: rgba(0, 0, 0, 0.9);
        font-family: inherit !important;
        font-size: 17px !important;
        font-weight: 600;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        box-sizing: border-box;
        flex-shrink: 0;
        line-height: 1.4em;
        margin: 6.25px 0;
      }
      .prev_info_url {
        color: rgba(0, 0, 0, 0.6);
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 8px;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
    .logo {
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 48px;
      max-height: 100%;
      overflow: hidden;
      position: relative;
      width: 48px;
      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
    .prev_text {
      color: #2b2b2b;
      display: block;
      line-height: 20px;
      font-size: 14px;
      margin: 0 16px 8px;
      overflow: hidden;
      position: relative;
      white-space: pre-line;
      .intro-text {
        font-size: 13px;
        word-wrap: break-word;
        white-space: -moz-pre-wrap;
        white-space: pre-wrap;
        color: var(--fds-black);
        padding: 8px 8px 0px;
      }
    }
    canvas {
      background-color: rgba(0, 0, 0, 1);
      border-radius: 50%;
      animation: fadein_keyframes 0.5s both;
      text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48cG9seWxpbmUgZmlsbD0ibm9uZSIgcG9pbnRzPSI0MiwzMiA2MCw1MCA0Miw2OCIgc3Ryb2tlLXdpZHRoPSI2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0icmdiYSgyNTUsMjU1LDI1NSwxKSI+PC9wb2x5bGluZT48L3N2Zz4=);
      flex-shrink: 0;
      flex-grow: 0;
      display: block;
      height: 37.5px;
      border: none;
    }
  }
  .social-data {
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 16px;
    padding: 8px 0;
    .social-like-container {
      flex-grow: 1;
      .icon-like {
        position: relative;
        top: 3px;
      }
    }
  }
  .linkedin-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    margin: 0;
    padding: 4px 12px;
    .linkedin-action-btn {
      align-items: center;
      background: none;
      background-color: transparent;
      border: 0;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      display: inline-flex;
      flex: 0 1 auto;
      font-size: 20px;
      font-weight: 600;
      justify-content: center;
      line-height: 28px;
      min-height: 32px;
      padding: 10px 8px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      max-width: 480px;
      .linkedin-action-btn-text {
        display: flex;
        flex: 1 1 auto;
        font-size: 10px;
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }
}
</style>

<script>
export default {
  name: "GoogleDisplayPreviewResult",
  setup(props) {
    return {};
  },
  props: [
    "logo",
    "adImage",
    "headline",
    "introText",
    "headlinePlaceholder",
    "introTextPlaceholder",
    "mobileView",
  ],
  computed: {},
  methods: {},
  mounted() {},
  destoyed() {},
};
</script>
