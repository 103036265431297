<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4">
          <PropertyDashboardContentHeading
            :propertyId="propertyId"
            :showUploadContentButton="false"
          />
        </div>
        <div class="col-12 col-lg-8 d-flex justify-content-end">
          <div class="switches-wrapper">
            <div class="switches">
              <div
                class="switch"
                :class="{ active: tabIndex == 1 }"
                @click="switchTab(1)"
              >
                Campaign Audiences
              </div>
              <div
                class="switch"
                :class="{ active: tabIndex == 2 }"
                @click="switchTab(2)"
              >
                All Contact/Company Lists
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end buttons">
        <button
          class="btn btn-edit mx-2"
          @click="viewOnly = false"
          v-if="viewOnly && tabIndex === 1"
        >
          Edit
        </button>
      </div>
      <div class="tabs">
        <div class="tab-content">
          <div class="tab-content-item" v-if="tabIndex === 1">
            <div class="section" v-if="walkthruitCampaigns.length > 0">
              <div class="multiselect-wrapper">
                <label class="form-label">Ad Name</label>
                <div class="header col-12 col-md-3">
                  <p v-if="walkthruitCampaigns.length == 1">
                    {{ activeCampaign.name }}
                  </p>
                  <select
                    class="form-select"
                    v-if="walkthruitCampaigns.length > 1"
                    v-model="activeCampaign"
                  >
                    <option
                      v-for="(item, index) in walkthruitCampaigns"
                      :key="index"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="walkthruitCampaigns.length > 0">
              <form
                class="preview-form"
                @submit.prevent=""
                novalidate
                @keydown.enter="$event.preventDefault()"
              >
                <AudienceSection
                  v-model="audienceData"
                  :campaignName="campaignName"
                  :isAdmin="isAdmin"
                  :viewOnly="viewOnly"
                  :isLoading="isLoading"
                  v-if="activeCampaign"
                  :key="audienceKey"
                  :propertyId="propertyId"
                />
                <div class="my-4 buttons">
                  <button
                    type="submit"
                    class="btn submit-button wti-btn"
                    @click="campaignSubmit"
                    v-if="!viewOnly"
                  >
                    <span
                      v-if="submitting"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ submitting ? "" : "Submit" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="tab-content-item" v-if="tabIndex === 2">
            <ContactCompanyListTab
              :propertyId="propertyId"
              :tabIndex="tabIndex"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal id="uploadContactListCSV">
    <template v-slot:title> Add New Tenant Contact Targets</template>
    <template v-slot:body>
      <Input
        v-model="contactName"
        type="text"
        label="Tenant Contact Targets Name"
        v-if="!audienceData.tempcontactList.length"
      />
      <p v-else class="upload-audience-name">
        {{ contactName }}
      </p>
      <input
        type="file"
        id="contactList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 1)"
      />
      <div class="action-wrapper" v-if="!audienceData.tempcontactList.length">
        <label for="contactList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of audienceData.tempcontactList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 1)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="audienceData.tempcontactList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(1)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="uploadBrokerContactListCSV">
    <template v-slot:title> Add New Broker Targets</template>
    <template v-slot:body>
      <Input
        v-model="brokerContactName"
        type="text"
        label="Broker Targets Name"
        v-if="!audienceData.tempbrokerContactList.length"
      />
      <p v-else class="upload-audience-name">
        {{ brokerContactName }}
      </p>
      <input
        type="file"
        id="brokerContactList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 4)"
      />
      <div
        class="action-wrapper"
        v-if="!audienceData.tempbrokerContactList.length"
      >
        <label for="brokerContactList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of audienceData.tempbrokerContactList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 4)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="audienceData.tempbrokerContactList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(4)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="uploadCompanyListCSV">
    <template v-slot:title> Add New Tenant Company Targets</template>
    <template v-slot:body>
      <Input
        v-model="companyName"
        type="text"
        label="Tenant Company Targets Name"
        v-if="!audienceData.tempcompanyList.length"
      />
      <p v-else class="upload-audience-name">
        {{ companyName }}
      </p>
      <input
        type="file"
        id="companyList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 2)"
      />
      <div class="action-wrapper" v-if="!audienceData.tempcompanyList.length">
        <label for="companyList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of audienceData.tempcompanyList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 2)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="audienceData.tempcompanyList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(2)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="uploadActivityReport">
    <template v-slot:title>Upload New Activity Report</template>
    <template v-slot:body>
      <Input
        v-model="reportName"
        type="text"
        label="Activity Report Name"
        v-if="!audienceData.tempActivityReportList.length"
      />
      <p v-else class="upload-audience-name">
        {{ reportName }}
      </p>
      <input
        type="file"
        id="activityReport"
        class="form-control hide-file-input"
        @change="onInputChange($event, 3)"
      />
      <div
        class="action-wrapper"
        v-if="!audienceData.tempActivityReportList.length"
      >
        <label for="activityReport" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of audienceData.tempActivityReportList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" src="@/assets/pdf.png" />
          <span @click="removeUploadFile(file, 3)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="audienceData.tempActivityReportList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(3)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="sccessSaveMsg">
    <template v-slot:title> Success! </template>
    <template v-slot:body>
      <div class="instruction">Your changes have been successfully saved!</div>
      <button type="button" class="btn wti-btn-black" data-bs-dismiss="modal">
        Close
      </button>
    </template>
  </Modal>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
.switches-wrapper
  text-align: right
.tab-content
  margin-top: 50px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import ContactCompanyListTab from "@/components/ContactCompanyListTab.vue";
import AudienceSection from "@/components/AudienceSection.vue";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import * as XLSX from "xlsx";
// File Management
import useFileList from "@/compositions/file-list";
import messages from "@/utils/messages.json";

export default {
  name: "PropertyAudiences",
  setup(props) {
    const { addFiles } = useFileList();
    var defaultAudience = {
      existedAudiencesList: {
        companyList: [],
        contactList: [],
        brokerContactList: [],
      },
      tempcontactList: [],
      tempbrokerContactList: [],
      contactList: [],
      brokerContactList: [],
      tempcompanyList: [],
      tempActivityReportList: [],
      companyList: [],
      audienceInfo: {},
      priorityInfo: {
        brokerContactList: [],
        contactList: [],
        companyList: [],
      },
      // LocalBrokerList: null,
      activityReport: [],
      top10Broker: false,
      locations: ref(null),
    };
    return {
      walkthruitCampaigns: ref([]),
      activeCampaign: ref(null),
      audienceKey: ref(1),
      contactName: ref(null),
      brokerContactName: ref(null),
      companyName: ref(null),
      submitting: ref(false),
      isLoading: ref(false),
      audienceSubmit: ref(false),
      audienceData: ref({ ...defaultAudience }),
      defaultAudienceData: ref({ ...defaultAudience }),
      reportName: ref(null),
      // newActivityReport: ref(null),
      uploadErr: ref(false),
      uploadErrMsg: messages.uploadErr,
      tabIndex: ref(2),
      uploading: ref(false),
      viewOnly: ref(true),
    };
  },
  components: {
    Modal,
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    ContactCompanyListTab,
    AudienceSection,
    Input,
  },
  props: ["propertyId"],
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
    campaignId() {
      if (this.activeCampaign) {
        return this.activeCampaign.id;
      }
    },
    hasChanged() {
      return Object.keys(this.audienceData).some((field) => {
        if (field != "existedAudiencesList") {
          return this.audienceData[field] !== this.defaultAudienceData[field];
        }
      });
    },
  },
  methods: {
    switchTab(index) {
      this.tabIndex = index;
    },
    getWalkthruitCampaigns() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .getWalkthruitCampaigns(info)
        .then((res) => {
          if (res.data) {
            if (res.data.length > 0) {
              _this.walkthruitCampaigns = res.data;
              _this.activeCampaign = res.data[0];
            }
          }
        });
    },
    async onInputChange(e, fileType) {
      this.uploadErr = false;
      this.uploadErrMsg = messages.uploadErr;
      var files = useFileList().addFiles(
        e.target.files || e.dataTransfer.files,
        []
      );
      var fileName;
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (file.file.size > 0) {
          const fileExtension = file.file.name.split(".").pop().toLowerCase();
          var allowedExtensions = ["csv", "xlsx", "xls"];
          if (this.getFileType(file.type)) {
            var rows = await this.parseFile(file.file);
            file.rows = rows;
          }
          if (fileType == 1) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.contactName) {
                if (this.contactName.split(".").length <= 1) {
                  this.contactName =
                    this.contactName + "." + file.file.name.split(".").pop();
                }
              } else {
                this.contactName = file.file.name;
              }
              this.audienceData.contactList.forEach((item) => {
                if (item.originalname == this.contactName) {
                  this.contactName =
                    this.contactName.split(".")[0] +
                    "-" +
                    Date.now().toString() +
                    "." +
                    this.contactName.split(".")[1];
                }
              });
              fileName = this.contactName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.audienceData.audienceInfo[fileName] = rows;
              this.audienceData.tempcontactList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          } else if (fileType == 2) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.companyName) {
                if (this.companyName.split(".").length <= 1) {
                  this.companyName =
                    this.companyName + "." + file.file.name.split(".").pop();
                }
              } else {
                this.companyName = file.file.name;
              }
              this.audienceData.companyList.forEach((item) => {
                if (item.originalname == this.companyName) {
                  this.companyName =
                    this.companyName.split(".")[0] +
                    "-" +
                    Date.now().toString() +
                    "." +
                    this.companyName.split(".")[1];
                }
              });
              fileName = this.companyName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.audienceData.audienceInfo[fileName] = rows;
              this.audienceData.tempcompanyList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          } else if (fileType == 3) {
            //allowedExtensions = ["pdf"];
            // if (allowedExtensions.includes(fileExtension)) {
            if (this.reportName) {
              if (this.reportName.split(".").length <= 1) {
                this.reportName =
                  this.reportName + "." + file.file.name.split(".").pop();
              }
            } else {
              this.reportName = file.file.name;
            }
            this.audienceData.activityReport.forEach((item) => {
              if (item.originalname == this.reportName) {
                this.reportName =
                  this.reportName.split(".")[0] +
                  "-" +
                  Date.now().toString() +
                  "." +
                  this.reportName.split(".")[1];
              }
            });
            fileName = this.reportName;
            file.file = new File([file.file], fileName);
            file.id = fileName;
            file.originalname = fileName;
            file.priority = false;
            this.audienceData.tempActivityReportList.push(file);
            // } else {
            //   this.uploadErr = true;
            //   this.uploadErrMsg = messages.invalidTypeErr;
            //   e.target.value = "";
            // }
          } else if (fileType == 4) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.brokerContactName) {
                if (this.brokerContactName.split(".").length <= 1) {
                  this.brokerContactName =
                    this.brokerContactName +
                    "." +
                    file.file.name.split(".").pop();
                }
              } else {
                this.brokerContactName = file.file.name;
              }
              this.audienceData.brokerContactList.forEach((item) => {
                if (item.originalname == this.brokerContactName) {
                  this.brokerContactName =
                    this.brokerContactName.split(".")[0] +
                    "-" +
                    Date.now().toString() +
                    "." +
                    this.brokerContactName.split(".")[1];
                }
              });
              fileName = this.brokerContactName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.audienceData.audienceInfo[fileName] = rows;
              this.audienceData.tempbrokerContactList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          }
        } else {
          this.uploadErr = true;
        }
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    parseFile(file) {
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          var data = new Uint8Array(reader.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheet = workbook.Sheets[workbook.SheetNames[0]];
          var range = XLSX.utils.decode_range(sheet["!ref"]);
          resolve(range.e.r);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    getFileType(fileType) {
      if (fileType.includes("csv")) {
        return "csv";
      } else if (fileType.includes("spreadsheetml.sheet")) {
        return "xlsx";
      } else if (fileType.includes("ms-excel")) {
        return "xls";
      }
      return null;
    },
    campaignSubmit(e) {
      e.preventDefault();
      var _this = this;
      _this.submitting = true;
      _this.audienceData.priorityInfo = {
        brokerContactList: [],
        contactList: [],
        companyList: [],
        activityReport: [],
      };
      var info;
      this.audienceData.brokerContactList.forEach((item) => {
        if (item.priority) {
          _this.audienceData.priorityInfo.brokerContactList.push(
            item.originalname
          );
        }
      });
      this.audienceData.contactList.forEach((item) => {
        if (item.priority) {
          _this.audienceData.priorityInfo.contactList.push(item.originalname);
        }
      });
      this.audienceData.companyList.forEach((item) => {
        if (item.priority) {
          _this.audienceData.priorityInfo.companyList.push(item.originalname);
        }
      });
      this.audienceData.activityReport.forEach((item) => {
        if (item.priority) {
          _this.audienceData.priorityInfo.activityReport.push(
            item.originalname
          );
        }
      });
      info = {
        propertyId: this.propertyId,
        campaignId: this.campaignId,
        locations: this.audienceData.locations
          ? this.audienceData.locations.join("+")
          : null,
        top10Broker: this.audienceData.top10Broker,
        contactList: this.audienceData.contactList,
        brokerContactList: this.audienceData.brokerContactList,
        companyList: this.audienceData.companyList,
        activityReport: this.audienceData.activityReport,
        audienceInfo: this.audienceData.audienceInfo,
        priorityInfo: this.audienceData.priorityInfo,
        updateAudience: true,
        isAdmin: this.isAdmin,
      };

      const formData = new FormData();
      Object.keys(info).forEach((key) => {
        if (Array.isArray(info[key])) {
          if (info[key].length > 0) {
            info[key].forEach((item) => {
              if (typeof item == "string") {
                formData.append(key + "[]", item);
              } else if (typeof item == "object") {
                if (item && item.file) formData.append(key, item.file);
                formData.append(key + "[]", JSON.stringify(item));
              }
            });
          } else {
            formData.append(key, info[key]);
          }
        } else {
          if (typeof info[key] == "object") {
            if (info[key] && info[key].file)
              formData.append(key, info[key].file);
            else formData.append(key, JSON.stringify(info[key]));
          } else {
            formData.append(key, info[key]);
          }
        }
      });

      api()
        .postClientCampaignData(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.submitting = false;
            _this.audienceKey++;
            _this.viewOnly = true;
            if (typeof window !== undefined) {
              import("bootstrap").then((bootstrap) => {
                new bootstrap.Modal(
                  document.getElementById("sccessSaveMsg")
                ).show();
              });
            }
          }
        });
    },
    getCampaignData() {
      var _this = this;
      var info = {
        campaignId: this.campaignId,
        token: this.token,
      };
      api()
        .getWalkthruitCampaign(info)
        .then((res) => {
          if (res.data && Object.keys(res.data).length > 0) {
            _this.campaignName =
              res.data.campaignName !== "null" ? res.data.campaignName : "";
            _this.audienceData.contactList = res.data.contactList;
            _this.audienceData.brokerContactList = res.data.brokerContactList;
            _this.audienceData.companyList = res.data.companyList;
            _this.audienceData.locations = res.data.locations
              ? res.data.locations.split("+")
              : [];
            _this.audienceData.top10Broker = res.data.top10Broker;
            _this.audienceData.activityReport = res.data.activityReport;
            var audience = _this.audienceData;
            _this.defaultAudienceData = { ...audience };
          }
        });
    },
    rerender(ids = []) {
      this.contactName = null;
      this.brokerContactName = null;
      this.companyName = null;
      // this.newActivityReport = null;
      this.reportName = null;
      this.audienceData.tempcontactList = [];
      this.audienceData.tempbrokerContactList = [];
      this.audienceData.tempcompanyList = [];
      this.audienceData.tempActivityReportList = [];
      ids.forEach((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).click();
        }
      });
    },
    removeUploadFile(file, fileType) {
      if (fileType === 1) {
        this.contactName = null;
        const index = this.audienceData.tempcontactList.indexOf(file);
        if (index > -1) this.audienceData.tempcontactList.splice(index, 1);
      } else if (fileType === 2) {
        this.companyName = null;
        const index = this.audienceData.tempcompanyList.indexOf(file);
        if (index > -1) this.audienceData.tempcompanyList.splice(index, 1);
      } else if (fileType === 3) {
        this.reportName = null;
        const index = this.audienceData.tempActivityReportList.indexOf(file);
        if (index > -1)
          this.audienceData.tempActivityReportList.splice(index, 1);
      } else if (fileType === 4) {
        this.brokerContactName = null;
        const index = this.audienceData.tempbrokerContactList.indexOf(file);
        if (index > -1)
          this.audienceData.tempbrokerContactList.splice(index, 1);
      }
    },
    getImgUrl(file) {
      var fileType = this.getFileType(file.type);
      return new URL(`../assets/${fileType}.png`, import.meta.url).href;
    },
    updateList(fileType) {
      if (fileType === 1) {
        this.audienceData.contactList = [
          ...this.audienceData.tempcontactList,
          ...this.audienceData.contactList,
        ];
        this.rerender(["uploadContactListCSVclose"]);
      } else if (fileType === 2) {
        this.audienceData.companyList = [
          ...this.audienceData.tempcompanyList,
          ...this.audienceData.companyList,
        ];
        this.rerender(["uploadCompanyListCSVclose"]);
      } else if (fileType === 3) {
        this.audienceData.activityReport = [
          ...this.audienceData.tempActivityReportList,
          ...this.audienceData.activityReport,
        ];
        this.rerender(["uploadActivityReportclose"]);
      } else if (fileType === 4) {
        this.audienceData.brokerContactList = [
          ...this.audienceData.tempbrokerContactList,
          ...this.audienceData.brokerContactList,
        ];
        this.rerender(["uploadBrokerContactListCSVclose"]);
      }
    },
  },
  mounted() {
    var _this = this;
    this.getWalkthruitCampaigns();
    var myModals = document.getElementsByClassName("modal");
    for (let i = 0; i < myModals.length; i++) {
      var myModal = myModals[i];
      myModal.addEventListener("hidden.bs.modal", function () {
        _this.uploadErr = false;
      });
    }
  },
  watch: {
    activeCampaign() {
      this.getCampaignData();
    },
    audienceKey() {
      this.getCampaignData();
    },
  },
};
</script>
