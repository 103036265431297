<template>
  <div class="welcome-screen">
    <div class="welcome-image">
    </div>
    <div class="form-wrapper">

      <form class="form-app" @submit.prevent="onSubmit">

        <img
            src="@/assets/walkthruit-full-black.png"
            class="form-logo"
        />
        <div class="title">Reset Password:</div>
        <div class="sub-title">Enter your email below:</div>
        <div class="form-content">

          <Input
              v-model="email"
              type="email"
              label="Email address"
          />
          <button type="submit" class="btn btn-primary wti-btn">Reset Password</button>
          <div class="alert alert-info" v-if="notice">{{ notice }}</div>
          <div class="alert alert-danger" v-if="error">{{ error }}</div>
        </div>
      </form>
    </div>

  </div>
</template>

<style lang="scss">
@media (min-width: 1024px) {
}
</style>

<script>
import api from "@/services/BackendConnection";
import Input from "@/components/Input.vue";

export default {
  name: "ForgotPasswordView",
  data() {
    return {
      email: null,
      notice: null,
      error: null,
    };
  },
  components: {
    Input,
  },
  methods: {
    onSubmit(e) {
      var _this = this;
      e.preventDefault();
      var user = {
        email: _this.email,
      };
      api()
          .resetPasswordRequest(user)
          .then((res) => {
            if (res.data) {
              var userInfo = res.data;
              if (userInfo.success) {
                _this.notice = userInfo.data;
              } else {
                _this.error = userInfo.data;
              }
            }
          });
    },
  },
  created() {
  },
};
</script>
