<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    :aria-labelledby="id + 'ModalLabel'"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="customClass">
      <div class="modal-content">
        <div class="modal-header mx-3">
          <h5 class="modal-title" :id="id + 'ModalLabel'">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :id="id + 'close'"
          ></button>
        </div>
        <div class="alert alert-info" v-if="notice">{{ notice }}</div>
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="modal-body"><slot name="body"></slot></div>
        <div class="modal-footer border-0"><slot name="footer"></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {};
  },
  props: ["id", "notice", "error", "customClass"],
  methods: {},
};
</script>
