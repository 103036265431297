<template>
  <div
    @dragenter.prevent
    @dragover.prevent
    @dragleave.prevent
    @drop.prevent="dragFile"
  >
    <div
      v-if="validAdTypes.includes(adType) || validAdTypes.includes(adType.toString())"
      class="input-group"
      :class="{ disabled: disabled }"
    >
      <div
        class="input-group-text border-end-0 add-image"
        @click="$refs.assets.click()"
      >
        <img src="@/assets/add-image.svg" />
        <input
          type="file"
          ref="assets"
          accept="video/mp4, video/mov, image/gif"
          class="d-none"
          @change="onInputChange"
          v-if="adType == 1"
          :disabled="disabled"
        />
        <input
          type="file"
          ref="assets"
          accept="image/png, image/jpg, image/gif"
          class="d-none"
          @change="onInputChange"
          v-else
          :disabled="disabled"
        />
      </div>
      <div class="form-control border-start-0 image-uploader-container">
        <span v-if="totalFiles.length > 0">
          <span
            v-for="(item, index) in totalFiles"
            :key="index"
            class="active-image"
          >
            {{ item.file ? item.file.name : item.originalname }}
          </span>
          <span
            v-if="totalFiles[0] && !disabled"
            class="active-image-remove"
            @click="removeFile(totalFiles[0])"
          ></span>
        </span>
      </div>
    </div>
    <div v-else class="card-area my-4 mx-0">
      <div
        class="drop-label"
        :for="'file-input-' + adType"
        v-if="totalFiles.length && totalFiles[cardId]"
      >
        <div class="row file-preview">
          <span
            @click="removeFile(totalFiles[cardId])"
            class="close-icon"
            v-if="!disabled"
          >
            &times;
          </span>
          <img
            :src="totalFiles[cardId].url"
            class="card-asset"
            v-if="totalFiles[cardId].type.includes('image')"
          />
          <video
            :src="totalFiles[cardId].url"
            controls
            class="card-asset"
            v-if="totalFiles[cardId].type.includes('video')"
          ></video>
        </div>
      </div>
      <label
        class="drop-label position-absolute top-50 start-50 translate-middle"
        :for="'file-input-' + '-' + cardId + '-' + adType"
        v-else
      >
        <div class="card-label">
          <span><font-awesome-icon icon="camera" /></span>
          <input
            type="file"
            :id="'file-input-' + '-' + cardId + '-' + adType"
            accept="image/png, image/jpg , video/mp4, video/mov, image/gif"
            @change="onInputChange"
            :disabled="disabled"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<style scoped lang="scss">
.drop-area {
  width: 96%;
  margin: 0 0 auto;
  padding: 35px;
  border: 1px dashed #333;
}

.card-area {
  width: 96%;
  height: 120px;
  border: 1px dashed #333;
  position: relative;
}

label.drop-label,
div.drop-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  input[type="file"]:not(:focus-visible) {
    // Visually Hidden Styles taken from Bootstrap 5
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .card-label {
    font-size: 40px;
  }
}
.file-preview {
  margin: 1rem 2.5%;
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;
  img,
  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  img.card-asset,
  video.card-asset {
    max-height: 85px;
  }

  .close-icon,
  .status-indicator {
    --size: 20px;
    position: absolute;
    line-height: var(--size);
    height: var(--size);
    border-radius: var(--size);
    box-shadow: 0 0 5px currentColor;
    right: 0.25rem;
    appearance: none;
    border: 0;
    padding: 0;
    z-index: 1;
  }

  .close-icon {
    width: var(--size);
    font-size: var(--size);
    background: #933;
    color: #fff;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .status-indicator {
    font-size: calc(0.75 * var(--size));
    bottom: 0.25rem;
    padding: 0 10px;
  }
}
</style>

<script>
import { ref } from "vue";
// File Management
import useFileList from "@/compositions/file-list";
export default {
  name: "ImageUploader",
  setup(props) {
    const { addFiles } = useFileList();
    return {
      files: ref([]),
      validAdTypes: ["1", "2", "4", "5", "6", "7", "8"],
    };
  },
  props: ["adType", "parentFiles", "cardId", "disabled"],
  computed: {
    totalFiles() {
      let files = this.files;
      let parentFiles = this.parentFiles;
      var totalFiles;
      if (files.length === 0) {
        totalFiles = [...files, ...parentFiles];
      } else {
        totalFiles = parentFiles;
      }
      totalFiles = [...new Set(totalFiles)];
      return totalFiles;
    },
  },
  methods: {
    onInputChange(e) {
      var files = useFileList().addFiles(e.target.files, []);
      var updatedFiles = [];
      files.forEach((file) => {
        if (file.file.size > 0) {
          const fileExtension = file.file.name.split(".").pop().toLowerCase();
          var allowedExtensions = ["png", "jpg", "mp4", "mov", "gif", "jpeg"];
          if (allowedExtensions.includes(fileExtension)) {
            updatedFiles.push(file);
          } else {
            if (typeof window !== undefined) {
              import("bootstrap").then((bootstrap) => {
                new bootstrap.Modal(
                  document.getElementById("invalidFileTypeError")
                ).show();
              });
            }
            e.target.value = "";
          }
        } else {
          if (typeof window !== undefined) {
            import("bootstrap").then((bootstrap) => {
              new bootstrap.Modal(
                document.getElementById("uploadError")
              ).show();
            });
          }
        }
      });
      var info = { files: updatedFiles };
      if (this.cardId != undefined) {
        info["cardId"] = this.cardId;
      }
      this.$emit("updateFiles", info);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    dragFile(e) {
      var files = useFileList().addFiles(e.dataTransfer.files, []);
      var updatedFiles = [];
      files.forEach((file) => {
        if (file.file.size > 0) {
          const fileExtension = file.file.name.split(".").pop().toLowerCase();
          var allowedExtensions = ["png", "jpg", "mp4", "mov", "gif", "jpeg"];
          if (allowedExtensions.includes(fileExtension)) {
            updatedFiles.push(file);
          } else {
            if (typeof window !== undefined) {
              import("bootstrap").then((bootstrap) => {
                new bootstrap.Modal(
                  document.getElementById("invalidFileTypeError")
                ).show();
              });
            }
            e.target.value = "";
          }
        } else {
          if (typeof window !== undefined) {
            import("bootstrap").then((bootstrap) => {
              new bootstrap.Modal(
                document.getElementById("uploadError")
              ).show();
            });
          }
        }
      });
      var info = { files: updatedFiles };
      if (this.cardId != undefined) {
        info["cardId"] = this.cardId;
      }
      this.$emit("updateFiles", info);
      e.dataTransfer.value = null;
    },
    removeFile(file) {
      const index = this.parentFiles.indexOf(file);
      if (index > -1) this.parentFiles.splice(index, 1);
      this.$emit("removeFile", {
        files: this.parentFiles,
        cardId: this.cardId,
      });
    },
  },
  mounted() {},
  onUnmounted() {},
};
</script>