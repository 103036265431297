<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container">
      <PropertyDashboardContentHeading :propertyId="propertyId" />
      <div v-show="project">
        <div class="mb-3 walkthru-heading row justify-content-between">
          <div class="header col-12 col-md-3 my-auto">
            <p v-if="projects.length == 1" class="project-name">
              {{ projects[0].projectName }}
            </p>
            <select
              class="form-select"
              v-if="projects.length > 1"
              v-model="project"
            >
              <option
                v-for="(item, index) in projects"
                :key="index"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-3 text-end">
            <button
              class="btn btn-primary wti-btn"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#embedCode"
            >
              Embed Code
            </button>
          </div>
        </div>
        <div class="section kpi-section">
          <div class="form-label mb-2">Views by Source</div>
          <p class="form-info no-margin-bottom">
            Copy the unique 3D Walkthru URL and separately add to your marketing
            to get views tracking by source.
          </p>
          <div class="row kpi-container position-relative">
            <loading
              v-model:active="isLoading"
              :is-full-page="false"
              loader="dots"
              :opacity="1"
            />
            <div
              class="col-12 col-md-3 kpi"
              v-for="(item, key) in kpis"
              :key="key"
            >
              <div class="kpi-border">
                <div class="title">{{ key }}</div>
                <div class="value">{{ item.value }}</div>
                <div class="input-group url">
                  <input
                    class="form-control form-input col-9"
                    type="text"
                    :value="item.url"
                    :aria-describedby="'group-' + key"
                  />
                  <span
                    class="input-group-text popper-container"
                    :id="'group-' + key"
                    ><Popper content="Copied!" class="dark">
                      <button
                        type="button"
                        v-clipboard:copy="item.url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        class="btn btn-dark wti-btn-black"
                      >
                        Copy
                      </button>
                    </Popper>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs section target-section">
          <ul class="nav nav-tabs" id="walkthruTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="target-tab"
                data-bs-toggle="tab"
                data-bs-target="#target"
                type="button"
                role="tab"
                aria-controls="target"
                aria-selected="true"
                @click="tuggleFeed(false)"
              >
                Target
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="feed-tab"
                data-bs-toggle="tab"
                data-bs-target="#feed"
                type="button"
                role="tab"
                aria-controls="feed"
                aria-selected="false"
                @click="tuggleFeed()"
              >
                Feed
              </button>
            </li>
            <JsonCSV
              :data="feedInfo"
              :name="project.name + '.csv'"
              v-if="showFeed && feedInfo.length > 0"
              class="export-btn"
            >
              <button class="btn btn-dark wti-btn-black my-2">
                Export to CSV
              </button>
            </JsonCSV>
          </ul>
          <div class="tab-content" id="walkthruTabContent">
            <div
              class="tab-pane fade show active"
              id="target"
              role="tabpanel"
              aria-labelledby="target-tab"
            >
              <Targets
                :project="project"
                :propertyId="propertyId"
                :targets="targets"
                :targetsLoaded="targetsLoaded"
                :key="targetKey"
                @updateKey="updateKey"
              />
            </div>
            <div
              class="tab-pane fade"
              id="feed"
              role="tabpanel"
              aria-labelledby="feed-tab"
            >
              <div class="position-relative feed-total-container">
                <loading
                  v-model:active="feedInfoLoading"
                  :is-full-page="false"
                  loader="dots"
                  :opacity="1"
                  class="mx-auto p-0 m-0"
                />
                <div class="feed-container mt-3" v-if="!feedInfoLoading">
                  <DatatableComponent
                    :columns="columns"
                    :data="feedInfo"
                    :options="{
                      responsive: true,
                      order: [0, 'desc'],
                      dom: 'frtl',
                      lengthMenu: [
                        [10, 25, 50, -1],
                        [10, 25, 50, 'All'],
                      ],
                    }"
                    :keys="keys"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal id="embedCode">
    <template v-slot:title>Embed Code</template>
    <template v-slot:body>
      <p class="border section mt-2">{{ iframe }}</p>
      <button
        type="button"
        v-clipboard:copy="iframe"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="btn wti-btn-black"
      >
        Copy
      </button>
    </template>
  </Modal>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.walkthru-heading
  .btn
    background-color: $Blue
    color: white !important
    font-weight: 600
    font-size: 16px
    line-height: 20px
    padding: 16px 58px
    +mobile
      font-size: 12px
      padding: 10px
      margin-top: 10px
      border-radius: 0.5rem
  .header
    .project-name
      font-size: 18px
      line-height: 18px
      color: $Main
.kpi-section
  padding: 24px
  .kpi-container
    margin-top: 24px
    .kpi-border
      border: 1px solid $LightGreyBorder
      border-radius: 12px
    .title
      font-weight: 600
      font-size: 16px
      line-height: 20px
      color: $Main
      padding: 23px 30px 12px
    .value
      font-weight: 600
      font-size: 18px
      line-height: 23px
      color: $Main
      padding: 0 30px 18px
    .url
      width: 90%
      margin: 0 auto 28px
      .form-input
        border: 1px solid $LightGreyBorder
        padding: 10px
        font-weight: 400
        font-size: 9px !important
        line-height: 11px !important
      .popper-container
        padding: 0
        margin-left: 0 !important
        vertical-align: middle
        text-align: start
        .btn
          font-weight: 600
          font-size: 14px
          line-height: 18px
          padding: 8px
          width: 100%
        .dark
          --popper-theme-background-color: #333333
          --popper-theme-background-color-hover: #333333
          --popper-theme-text-color: white
          --popper-theme-border-width: 0px
          --popper-theme-border-radius: 6px
          --popper-theme-padding: 10px
          --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25)
          border-left: 2px solid transparent !important
.tabs.target-section
  padding: 25px 30px 40px
  #walkthruTab
    position: relative
    .export-btn
      position: absolute
      right: 0
      bottom: 7px
      .btn
        font-weight: 600
        font-size: 14px
        line-height: 18px
        +mobile
          font-size: 12px
          border-radius: 0.375rem
  .nav-link
    color: $Main
    border: none
    font-weight: 400
    font-size: 16px
    font-weight: 600
    line-height: 20px
    padding: 0 0 26px
    margin: 0 30px 0 0
  .nav-link.active
    color: $Main
    border-bottom: 3px solid $Blue
  #feed
    .feed-total-container
      min-height: 300px
      .feed-container .datatable
        +mobile
          font-size: 12px !important
        .page-link
          font-size: 12px !important

@media(max-width:767px)
  .section
    padding: 15px
  .kpi-container
    .kpi
      margin-bottom: 15px
    .kpi-border
      border-right: none
    .url
      width: 100%
      .btn
        font-size: 12px
        padding: 4px 5px

.page-link
  font-size: 12px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import DatatableComponent from "@/components/DatatableComponent.vue";
import Targets from "@/components/Targets.vue";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import JsonCSV from "vue-json-csv";
import moment from "moment";
import $ from "jquery";

export default {
  name: "ThreeDWalkthru",
  setup(props) {
    return {
      project: ref(null),
      projects: ref([]),
      domain: "https://3d.walkthruit.com/",
      kpis: ref({
        Total: {
          value: 0,
          url: null,
          urlApend: "/#autoplay&flipmouse",
        },
        Brochure: {
          value: 0,
          url: null,
          urlApend: "/#autoplay&flipmouse&source=infopackage",
        },
        Email: {
          value: 0,
          url: null,
          urlApend: "/#autoplay&flipmouse&source=email",
        },
        Social: {
          value: 0,
          url: null,
          urlApend: "/#autoplay&flipmouse&source=social",
        },
      }),
      targets: ref([]),
      targetsLoaded: ref(false),
      targetKey: ref(0),
      feedInfo: ref([]),
      feedInfoLoading: ref(true),
      isLoading: ref(true),
      showFeed: ref(false),
      keys: ref(["Date", "Time", "Target", "City", "Device"]),
      columns: ref([
        {
          data: "date",
          type: "date",
          defaultContent: "-",
          width: "20%",
          className: "text-break",
          render: function (data, type, row, meta) {
            return moment(new Date(data)).format("MM/DD/YY");
          },
        },
        {
          data: "time",
          type: "date",
          orderable: false,
          defaultContent: "",
          width: "20%",
          className: "text-break",
          render: function (data, type, row, meta) {
            return data;
          },
        },
        {
          data: "target",
          defaultContent: "-",
          width: "20%",
          className: "text-break",
          render: function (data, type, row, meta) {
            var style = "height: 25px; width: auto;";
            if (data == "N/A") {
              return data;
            } else {
              return (
                '<span class="d-flex align-items-center"><img src="' +
                data +
                '" style="' +
                style +
                '" /></span>'
              );
            }
          },
        },
        {
          data: "city",
          defaultContent: "-",
          width: "20%",
          className: "text-break",
        },
        {
          data: "device",
          defaultContent: "-",
          width: "20%",
          className: "text-break",
        },
      ]),
    };
  },
  components: {
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    Targets,
    Modal,
    Input,
    JsonCSV,
    DatatableComponent,
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    iframe() {
      if (this.project) {
        var url = this.domain + this.project.name + "/#autoplay&flipmouse";
        return (
          '<iframe src="' +
          url +
          '" style="border:0px #ffffff none;" name="3dWalkthruiFrame" ' +
          'scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" ' +
          ' height="400px" width="600px" allowfullscreen></iframe>'
        );
      }
    },
  },
  methods: {
    getProjects() {
      if (this.$store.state.user) {
        var _this = this;
        var info = {
          propertyId: _this.propertyId,
        };
        api()
          .get3DWalkthruitProjects(info)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              _this.projects = res.data;
              _this.project = res.data[0];
              Object.keys(_this.kpis).forEach((key) => {
                _this.kpis[key].url =
                  _this.domain + _this.project.name + _this.kpis[key].urlApend;
              });
            }
          });
      }
    },
    onCopy(e) {
      // console.log("You just copied: " + e.text);
    },
    onError(e) {
      // console.log("Failed to copy texts");
    },
    getAnalyticsInfo() {
      if (this.$store.state.user) {
        var _this = this;
        var info = {
          propertyId: _this.propertyId,
          name: this.project.name,
          viewId: this.project.viewId,
        };
        api()
          .getGoogleAnalyticsReport(info)
          .then((res) => {
            if (res.data) {
              Object.keys(_this.kpis).forEach((key) => {
                _this.kpis[key].value = res.data[key].toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                );
              });
              this.isLoading = false;
            }
          });
      }
    },
    getFeedInfo() {
      if (this.$store.state.user) {
        var _this = this;
        var info = {
          propertyId: _this.propertyId,
          name: this.project.name,
          viewId: this.project.viewId,
        };
        api()
          .getGoogleAnalyticsFeedReport(info)
          .then((res) => {
            if (res.data) {
              _this.feedInfo = res.data;
              _this.feedInfoLoading = false;
            }
          });
      }
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    updateKey() {
      this.targetKey++;
      this.targetsLoaded = false;
      this.getTargets();
    },
    tuggleFeed(show = null) {
      if (show == false) {
        this.showFeed = show;
      } else {
        this.showFeed = !this.showFeed;
        if (this.feedInfo.length <= 0 && this.project) {
          this.getFeedInfo();
        }
      }
    },
    getTargets() {
      var _this = this;
      var info = {
        propertyId: this.propertyId,
        threeDWalkthruId: this.project._id,
        viewId: this.project.viewId,
        name: this.project.name,
      };
      api()
        .get3DWalkthruitTargets(info)
        .then((res) => {
          if (res.data) {
            _this.targets = res.data;
            _this.targetsLoaded = true;
          }
        });
    },
  },
  mounted() {
    this.getProjects();
  },
  watch: {
    project() {
      var targetTab = document.getElementById("target-tab");
      targetTab.click();
      this.isLoading = true;
      this.targetsLoaded = false;
      this.feedInfo = [];
      this.feedInfoLoading = true;
      this.getAnalyticsInfo();
      this.getTargets();
    },
  },
};
</script>
