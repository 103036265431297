<template>
  <div class="mt-3">
    <form class="preview-form" @submit.prevent="" novalidate id="campaignform">
      <div class="row">
        <div class="col-12 col-xxl-6">
          <div class="section">
            <div class="large-input">
              <Input
                v-model="campaignInfo.campaignName"
                type="text"
                maxlength="50"
                label="Ad Name"
                childclass="multiselect__input"
                :disable="viewOnly || !isAdmin"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-6">
          <div class="section">
            <div class="form-label platform-lable">
              Ad Platforms
              <VTooltip
                class="form-tooltip"
                :triggers="['click']"
                :autoHide="true"
              >
                <img src="@/assets/question-mark.svg" />

                <template #popper>
                  {{ tooltips.adPlatforms }}
                </template>
              </VTooltip>
            </div>
            <div class="row custom-checkboxes">
              <div
                class="col custom-checkbox-col"
                v-for="(item, index) in platformList"
                :key="index"
              >
                <div class="custom-checkbox">
                  <label
                    style="cursor: default"
                    class="border-0 position-relative"
                  >
                    <div class="platform-logo mx-0">
                      <img
                        src="@/assets/optimizeforme.png"
                        v-if="item == 'Optimize For Me'"
                      />
                      <img
                        src="@/assets/linkedin.png"
                        v-if="item == 'Linkedin'"
                      />
                      <img
                        src="@/assets/facebook.png"
                        v-if="item == 'Facebook'"
                      />
                      <img
                        src="@/assets/instagram.png"
                        v-if="item == 'Instagram'"
                      />
                      <img
                        src="@/assets/twitter.png"
                        v-if="item == 'Twitter'"
                      />
                      <img src="@/assets/search.png" v-if="item == 'Search'" />
                      <img src="@/assets/google.png" v-if="item == 'Display'" />
                      <button
                        class="v-popper v-popper--theme-tooltip form-tooltip image-tooltip btn"
                        v-if="item == 'Display'"
                        @click="showDisplayTooltip = 'block'"
                      >
                        <img
                          src="/src/assets/question-mark.svg"
                          class="position-relative"
                        />
                        <div
                          class="alert alert-dismissible fade show"
                          id="display-tooltip"
                          role="alert"
                        >
                          <button
                            type="button"
                            class="btn btn-close"
                            @click.stop="showDisplayTooltip = 'none'"
                            aria-label="Close"
                          ></button>
                        </div>
                      </button>
                      <!-- <VTooltip
                        class="form-tooltip image-tooltip"
                        :triggers="['click']"
                        placements="top-end"
                        :autoHide="true"
                        v-if="item == 'Display'"
                      >
                        <img src="@/assets/question-mark.svg" />

                        <template #popper>
                          <img
                            style="width: 350px"
                            src="@/assets/display-tooltip.png"
                          />
                        </template>
                      </VTooltip> -->
                    </div>
                    <div class="platform-name">
                      {{ item == "Twitter" ? "X (Twitter)" : item }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAdmin" class="section">
        <div class="form-label mb-2">Admin Field</div>
        <div class="row">
          <div class="col">
            <label class="pt-4 pb-2" for="selectionItem">Campaign Status</label>
            <Select
              v-model="campaignInfo.status"
              label=""
              :options="statusList"
            />
          </div>
          <div class="col">
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.showTrueSpend"
                value=""
                id="trueSpend"
                :disabled="!isSuperAdmin"
              />
              <label class="form-check-label" for="trueSpend"
                >Show True Spend (Meta/LI)
              </label>
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.showTrueGoogleSpendAndView"
                value=""
                id="trueGoogleSpendAndView"
                :disabled="!isSuperAdmin"
              />
              <label class="form-check-label" for="trueGoogleSpendAndView"
                >Show Google Views/Spend
              </label>
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.hideReportContractViews"
                value=""
                id="hideReportContractViews"
              />
              <label class="form-check-label" for="hideReportContractViews"
                >Remove contract views - reporting
              </label>
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.hideTableContractedViews"
                value=""
                id="hideTableContractedViews"
              />
              <label class="form-check-label" for="hideTableContractedViews"
                >Remove contracted views - reporting table
              </label>
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.includesAudienceNetwork"
                value=""
                id="includesAudienceNetwork"
              />
              <label class="form-check-label" for="includesAudienceNetwork"
                >Includes Facebook Audience Network data
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.previewInfo.showExtraSAd"
                value=""
                id="extraSingleImage"
              />
              <label class="form-check-label" for="extraSingleImage"
                >Show extra Single Image Ads</label
              >
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.hideMonthlyCostField"
                value=""
                id="hideMonthlyCostField"
              />
              <label class="form-check-label" for="trueSpend"
                >Hide Monthly Cost & Projections</label
              >
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.previewInfo.showCarouselText"
                value=""
                id="showCarouselText"
              />
              <label class="form-check-label" for="showCarouselText"
                >Show Carousel Text
              </label>
            </div>
            <div class="form-check pt-2">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="campaignInfo.addToDropdown"
                value=""
                id="addToDropdown"
              />
              <label class="form-check-label" for="addToDropdown"
                >Add to VTS Reporting dropdown
              </label>
            </div>
          </div>
        </div>
        <Input
          v-model="campaignInfo.endDate"
          type="date"
          label="End Date"
          v-if="campaignInfo.status === 'Ended'"
        />
        <div class="row row-cols-1 row-cols-md-4">
          <div class="col">
            <label class="pt-4 pb-2" for="clientCPMs">Client CPM</label>
            <select
              class="form-select"
              v-model="campaignInfo.clientCPM"
              id="clientCPMs"
            >
              <option
                v-for="(value, key) in clientCPMs"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="customCPM">Custom CPM</label>
            <input
              id="customCPM"
              class="form-control"
              v-model="campaignInfo.customCPM"
              type="number"
              :disabled="!isCampaignAdmin"
            />
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="customCPMduration">
              Custom duration(month)
            </label>
            <select
              class="form-select"
              v-model="campaignInfo.contractType"
              id="customCPMduration"
              :disabled="!isCampaignAdmin"
            >
              <option :value="3">1 month</option>
              <option :value="4">3 month</option>
              <option :value="1">6 month</option>
              <option :value="2">12 month</option>
            </select>
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="customGoogleDailyImpressions"
              >Google Daily Impressions</label
            >
            <input
              id="customGoogleDailyImpressions"
              class="form-control"
              v-model="campaignInfo.customGoogleDailyImpressions"
              type="number"
            />
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-4">
          <div class="col">
            <label class="pt-4 pb-2" for="extendDuration"
              >Extend Duration(month)</label
            >
            <input
              id="extendDuration"
              class="form-control"
              v-model="campaignInfo.extendDuration"
              type="number"
            />
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="customCPMExtend"
              >Extended Custom CPM</label
            >
            <input
              id="customCPMExtend"
              class="form-control"
              v-model="campaignInfo.customCPMExtend"
              type="number"
              :disabled="!isCampaignAdmin"
            />
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="extendFrom">Extend From</label>
            <input
              id="extendFrom"
              class="form-control"
              v-model="campaignInfo.extendFrom"
              type="date"
              :disabled="!isCampaignAdmin"
            />
          </div>
          <div class="col">
            <label class="pt-4 pb-2" for="extendTo">Extend To</label>
            <input
              id="extendTo"
              class="form-control"
              v-model="campaignInfo.extendTo"
              type="date"
              :disabled="!isCampaignAdmin"
            />
          </div>
        </div>
      </div>
      <div
        class="section"
        v-if="
          !publicView &&
          currentUser != mockUserId &&
          !campaignInfo.hideMonthlyCostField
        "
      >
        <div class="form-label mb-2">Monthly Cost & Projections</div>
        <p class="form-info">
          Our pricing is typically 25% more cost-effective than the industry
          benchmark. No penalty to cancel contract if leased/sold.
        </p>
        <div class="row-wrapper">
          <div
            class="row row-cols-2 row-cols-md-3 btn-group w-100"
            role="group"
          >
            <div class="col">
              <div
                class="cost-wrapper"
                :class="{ 'disabled-wrapper': !isAdmin }"
              >
                <div class="custom-field">
                  <div class="custom-field-wrapper">
                    <label>
                      Monthly Spend
                      <VTooltip
                        class="form-tooltip"
                        :triggers="['click']"
                        :autoHide="true"
                      >
                        <img src="@/assets/question-mark.svg" />

                        <template #popper>
                          {{ tooltips.monthlycost }}
                        </template>
                      </VTooltip>
                    </label>
                    <div class="value">
                      <span class="custom-value align-middle">{{
                        monthlyCost
                      }}</span>
                      <span class="cost-icons" v-if="isAdmin">
                        <button
                          type="button"
                          class="btn px-0 border-0 plus-minus-button"
                          @click="reduceBudget"
                          :disabled="viewOnly"
                        >
                          <img src="@/assets/minus.svg" />
                        </button>
                        <button
                          type="button"
                          class="btn px-0 border-0 ms-1 plus-minus-button"
                          @click="addBudget"
                          :disabled="viewOnly"
                        >
                          <img src="@/assets/plus.svg" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="cost-wrapper"
                :class="{ 'disabled-wrapper': !isAdmin }"
              >
                <div class="custom-field">
                  <div class="custom-field-wrapper">
                    <label>
                      {{ getContractDurationLabel(campaignInfo.contractType) }}
                    </label>
                    <div class="value">
                      <span class="cost-value">
                        ~{{ viewsByCPM }} views/mo.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="cost-wrapper"
                :class="{ 'disabled-wrapper': !isAdmin }"
              >
                <div class="form-group custom-field">
                  <div class="custom-field-wrapper">
                    <label>Start Date</label>
                    <div class="value" v-if="viewOnly || !isAdmin">
                      <span class="cost-value">
                        {{ startDateStr }}
                      </span>
                    </div>
                    <input
                      type="date"
                      class="form-control"
                      id="startDate"
                      v-model="campaignInfo.startDate"
                      v-else
                      :disabled="viewOnly"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AudienceSection
        v-model="campaignInfo.audienceData"
        :campaignName="campaignInfo.campaignName"
        :isAdmin="isAdmin"
        :viewOnly="viewOnly"
        :isLoading="isLoading"
        :propertyId="propertyId"
        @audiencesLoading="updateFetchStatus"
      />
      <div class="section">
        <div class="accordion accordion-flush">
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-ads">
              <button
                class="accordion-button collapsed px-0 pb-2 mb-0 form-label"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-adcontent"
                aria-expanded="false"
                aria-controls="collapse-adcontent"
              >
                Ad Content
              </button>
            </h2>
          </div>
          <p class="form-info">
            Select an ad format and add your content/copy below.
          </p>
          <div
            id="collapse-adcontent"
            class="accordion-collapse collapse show"
            aria-labelledby="flush-ads"
            data-bs-parent="#accordionFlushExample"
          >
            <Preview v-model="campaignInfo.previewInfo" :disabled="viewOnly" />
          </div>
        </div>
      </div>
      <div class="error-msg" v-if="updateErr">{{ updateErrMsg }}</div>
      <div class="my-4 buttons">
        <button
          type="submit"
          class="btn submit-button btn-save"
          @click="campaignSubmit"
          v-if="!viewOnly"
        >
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Save & Review" }}
        </button>
      </div>
    </form>
  </div>
  <Modal id="uploadContactListCSV">
    <template v-slot:title> Add New Tenant Contact Targets</template>
    <template v-slot:body>
      <Input
        v-model="contactName"
        type="text"
        label="Contact List Name"
        v-if="!campaignInfo.audienceData.tempcontactList.length"
      />
      <p v-else class="upload-audience-name">
        {{ contactName }}
      </p>
      <input
        type="file"
        id="contactList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 1)"
      />
      <div
        class="action-wrapper"
        v-if="!campaignInfo.audienceData.tempcontactList.length"
      >
        <label for="contactList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of campaignInfo.audienceData.tempcontactList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 1)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="campaignInfo.audienceData.tempcontactList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(1)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="uploadCompanyListCSV">
    <template v-slot:title> Add New Tenant Company Targets</template>
    <template v-slot:body>
      <Input
        v-model="companyName"
        type="text"
        label="Tenant Company Targets Name"
        v-if="!campaignInfo.audienceData.tempcompanyList.length"
      />
      <p v-else class="upload-audience-name">
        {{ companyName }}
      </p>
      <input
        type="file"
        id="companyList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 2)"
      />
      <div
        class="action-wrapper"
        v-if="!campaignInfo.audienceData.tempcompanyList.length"
      >
        <label for="companyList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of campaignInfo.audienceData.tempcompanyList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 2)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="campaignInfo.audienceData.tempcompanyList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(2)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="shareURL">
    <template v-slot:title>Shareable URL</template>
    <template v-slot:body>
      <p class="border section mt-2 text-break">{{ campaignInfo.publicUrl }}</p>
      <button
        type="button"
        v-clipboard:copy="campaignInfo.publicUrl"
        class="btn btn-dark wti-btn-black"
      >
        Copy
      </button>
    </template>
  </Modal>
  <Modal id="uploadActivityReport">
    <template v-slot:title>Upload New Activity Report</template>
    <template v-slot:body>
      <Input
        v-model="reportName"
        type="text"
        label="Activity Report Name"
        v-if="!campaignInfo.audienceData.tempActivityReportList.length"
      />
      <p v-else class="upload-audience-name">
        {{ reportName }}
      </p>
      <input
        type="file"
        id="activityReport"
        class="form-control hide-file-input"
        @change="onInputChange($event, 3)"
      />
      <div
        class="action-wrapper"
        v-if="!campaignInfo.audienceData.tempActivityReportList.length"
      >
        <label for="activityReport" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of campaignInfo.audienceData.tempActivityReportList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" src="@/assets/pdf.png" />
          <span @click="removeUploadFile(file, 3)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="campaignInfo.audienceData.tempActivityReportList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(3)"
      >
        Save
      </button>
    </template>
  </Modal>
  <Modal id="uploadBrokerContactListCSV">
    <template v-slot:title> Add New Broker List CSV</template>
    <template v-slot:body>
      <Input
        v-model="brokerContactName"
        type="text"
        label="Broker Targets Name"
        v-if="!campaignInfo.audienceData.tempbrokerContactList.length"
      />
      <p v-else class="upload-audience-name">
        {{ brokerContactName }}
      </p>
      <input
        type="file"
        id="brokerContactList"
        class="form-control hide-file-input"
        accept=".csv,.xlsx,.xls"
        @change="onInputChange($event, 4)"
      />
      <div
        class="action-wrapper"
        v-if="!campaignInfo.audienceData.tempbrokerContactList.length"
      >
        <label for="brokerContactList" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div class="file-previews">
        <div
          v-for="file of campaignInfo.audienceData.tempbrokerContactList"
          :key="file.originalname"
          class="file-preview"
        >
          <img class="file-thumbnail" :src="getImgUrl(file)" />
          <span @click="removeUploadFile(file, 4)" class="close-icon">
            &times;
          </span>
        </div>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      <button
        type="submit"
        v-if="campaignInfo.audienceData.tempbrokerContactList.length"
        data-dismiss="modal"
        class="btn btn-submit wti-btn-black"
        @click="updateList(4)"
      >
        Save
      </button>
    </template>
  </Modal>
</template>

<style>
#display-tooltip {
  display: v-bind("showDisplayTooltip");
}
</style>

<script>
import { ref, reactive, toRaw } from "vue";
// Components
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Modal from "@/components/Modal.vue";
import Preview from "@/components/Preview.vue";
import AudienceSection from "@/components/AudienceSection.vue";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";

// Tools
import api from "@/services/BackendConnection";
import campaignData from "@/utils/campaignData.json";
// File Management
import useFileList from "@/compositions/file-list";
// external libs
import VueMultiselect from "./../../../node_modules/vue-multiselect/src/Multiselect.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import * as XLSX from "xlsx";
import moment from "moment";
import tooltips from "@/utils/tooltips.json";
import messages from "@/utils/messages.json";

export default {
  name: "WalkthruitCampaignForm",
  props: [
    "propertyId",
    "campaignId",
    "isAdmin",
    "viewOnly",
    "publicView",
    "token",
  ],
  emits: ["campaignStatusUpdate"],
  setup(props) {
    const { addFiles } = useFileList();
    function addBusinessDays(originalDate, numDaysToAdd) {
      const Sunday = 0;
      const Saturday = 6;
      let daysRemaining = numDaysToAdd;

      const newDate = moment(originalDate).clone();

      while (daysRemaining > 0) {
        newDate.add(1, "days");
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
          daysRemaining--;
        }
      }

      return newDate.format("YYYY-MM-DD");
    }
    return {
      platformList: [
        "Linkedin",
        "Facebook",
        "Instagram",
        "Twitter",
        "Search",
        "Display",
      ],
      clientCPMs: ref({
        188.00001: "$188 - 1 month",
        188.00002: "$188 - 3 month",
        188: "$188 - 6 month",
        163: "$163 - 12 month",
      }),
      submitting: ref(false),
      isLoading: ref(false),
      // platforms: ref([]),
      // preset: ref(addBusinessDays(new Date(), 3)),
      tooltips: tooltips.campaignForm,
      uploadErr: ref(false),
      uploadErrMsg: messages.uploadErr,
      updateErr: ref(false),
      updateErrMsg: messages.uploadErr,
      mockUserId: ref(import.meta.env.VITE_MOCK_ACCOUNT_ID),
      showDisplayTooltip: ref("none"),
      contactName: ref(null),
      brokerContactName: ref(null),
      companyName: ref(null),
      reportName: ref(null),
      campaignInfo: reactive({
        campaignName: null,
        startDate: moment().format("YYYY-MM-DD"),
        monthlyBudget: 2000,
        showTrueSpend: false,
        includesAudienceNetwork: false,
        addToDropdown: false,
        showTrueGoogleSpendAndView: false,
        hideMonthlyCostField: false,
        hideReportContractViews: false,
        hideTableContractedViews: false,
        status: null,
        endDate: null,
        publicUrl: null,
        audienceData: {
          existedAudiencesList: {
            companyList: [],
            contactList: [],
            brokerContactList: [],
            activityReports: [],
          },
          tempcontactList: [],
          tempbrokerContactList: [],
          contactList: [],
          brokerContactList: [],
          tempcompanyList: [],
          tempActivityReportList: [],
          companyList: [],
          audienceInfo: {},
          priorityInfo: {
            brokerContactList: [],
            contactList: [],
            companyList: [],
          },
          top10Broker: false,
          activityReport: [],
          locations: ref(null),
        },
        previewInfo: {
          adType: 3,
          sameUrl: false,
          showExtraSAd: false,
          showCarouselText: false,
          pageName: null,
          introText: null,
          headline: null,
          description: null,
          ctaType: "Learn More",
          websiteUrl: null,
          logo: null,
          adImage: null,
          adImage2: null,
          adImage3: null,
          adImage4: null,
          adImage5: null,
          adImage6: null,
          adVideo: null,
          files: [],
          cardInfo: [
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
          ],
        },
        contractType: 1,
        clientCPM: 188,
        customCPM: null,
        customCPMExtend: null,
        customGoogleDailyImpressions: null,
        extendDuration: null,
        extendFrom: null,
        extendTo: null,
      }),
      isInitialFetch: ref(true),
      isInitialAudienceFetch: ref(true),
      originalCampaignInfo: ref(null),
    };
  },
  components: {
    Input,
    Select,
    Modal,
    VueMultiselect,
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    PulseLoader,
    Preview,
    AudienceSection,
  },
  computed: {
    startDateStr() {
      var val = this.campaignInfo.startDate;
      return moment(val).format("MM/DD/YYYY");
    },
    statusList() {
      var list = campaignData.campaign.status;
      if (this.campaignInfo.status !== "Client Review") {
        list = list.filter((col) => col !== "Client Review");
      }
      return list;
    },
    viewsByCPM() {
      var cpm = this.campaignInfo.customCPMExtend
        ? this.campaignInfo.customCPMExtend
        : this.campaignInfo.customCPM
        ? this.campaignInfo.customCPM
        : this.campaignInfo.clientCPM
        ? this.campaignInfo.clientCPM
        : 188;
      var cost = (this.campaignInfo.monthlyBudget / cpm) * 1000;
      return cost.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      });
    },
    monthlyCost() {
      if (this.campaignInfo.monthlyBudget) {
        return (
          "$" +
          this.campaignInfo.monthlyBudget.toLocaleString(undefined, {
            maximumFractionDigits: 1,
          })
        );
      } else {
        var cost = 2000;
        return (
          "$" +
          cost.toLocaleString(undefined, {
            maximumFractionDigits: 1,
          })
        );
      }
    },
    componentKey() {
      return this.$store.state.componentKey;
    },
    currentUser() {
      var userId = this.$store.state.user.userId;
      if (this.$store.state.user?.currentUserId) {
        userId = this.$store.state.user.currentUserId;
      }
      return userId;
    },
    isCampaignAdmin() {
      var isCampaignAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isCampaignAdmin = user.isCampaignAdmin;
      }
      return isCampaignAdmin;
    },
    isSuperAdmin() {
      var isSuperAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isSuperAdmin = user.isSuperAdmin;
      }
      return isSuperAdmin;
    },
  },
  methods: {
    updateFetchStatus(value) {
      this.isInitialAudienceFetch = value;
      if (value === false) {
        this.originalCampaignInfo = JSON.parse(
          JSON.stringify(toRaw(this.campaignInfo))
        );
      }
    },
    getContractDurationLabel(contractType) {
      var extend = this.campaignInfo.extendDuration;
      switch (contractType) {
        case 3:
          return extend + 1 + "-Month Contract";
        case 4:
          return extend + 3 + "-Month Contract";
        case 1:
          return extend + 6 + "-Month Contract";
        case 2:
          return extend + 12 + "-Month Contract";
        default:
          return "Unknown Contract Duration";
      }
    },
    async onInputChange(e, fileType) {
      this.uploadErr = false;
      this.uploadErrMsg = messages.uploadErr;
      var files = useFileList().addFiles(
        e.target.files || e.dataTransfer.files,
        []
      );
      var fileName;
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (file.file.size > 0) {
          const fileExtension = file.file.name.split(".").pop().toLowerCase();
          var allowedExtensions = ["csv", "xlsx", "xls"];
          if (this.getFileType(file.type)) {
            var rows = await this.parseFile(file.file);
            file.rows = rows;
          }
          if (fileType == 1) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.contactName) {
                if (this.contactName.split(".").length <= 1) {
                  this.contactName = this.contactName + "." + fileExtension;
                }
              } else {
                this.contactName = file.file.name;
              }
              this.campaignInfo.audienceData.contactList.forEach((item) => {
                if (item.originalname == this.contactName) {
                  this.contactName =
                    this.contactName.split(".")[0] +
                    "-" +
                    Date.now().toString() +
                    "." +
                    this.contactName.split(".")[1];
                }
              });
              fileName = this.contactName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.campaignInfo.audienceData.audienceInfo[fileName] = rows;
              this.campaignInfo.audienceData.tempcontactList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          } else if (fileType == 2) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.companyName) {
                if (this.companyName.split(".").length <= 1) {
                  this.companyName = this.companyName + "." + fileExtension;
                }
              } else {
                this.companyName = file.file.name;
              }
              this.campaignInfo.audienceData.companyList.forEach((item) => {
                if (item.originalname == this.companyName) {
                  this.companyName =
                    this.companyName.split(".")[0] +
                    "-" +
                    Date.now().toString() +
                    "." +
                    this.companyName.split(".")[1];
                }
              });
              fileName = this.companyName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.campaignInfo.audienceData.audienceInfo[fileName] = rows;
              this.campaignInfo.audienceData.tempcompanyList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          } else if (fileType == 3) {
            // allowedExtensions = ["pdf"];
            // if (allowedExtensions.includes(fileExtension)) {
            if (this.reportName) {
              if (this.reportName.split(".").length <= 1) {
                this.reportName = this.reportName + "." + fileExtension;
              }
            } else {
              this.reportName = file.file.name;
            }
            this.campaignInfo.audienceData.activityReport.forEach((item) => {
              if (item.originalname == this.reportName) {
                this.reportName =
                  this.reportName.split(".")[0] +
                  "-" +
                  Date.now().toString() +
                  "." +
                  this.reportName.split(".")[1];
              }
            });
            fileName = this.reportName;
            file.file = new File([file.file], fileName);
            file.id = fileName;
            file.originalname = fileName;
            file.priority = false;
            this.campaignInfo.audienceData.tempActivityReportList.push(file);
            // } else {
            //   this.uploadErr = true;
            //   this.uploadErrMsg = messages.invalidTypeErr;
            //   e.target.value = "";
            // }
          } else if (fileType == 4) {
            if (allowedExtensions.includes(fileExtension)) {
              if (this.brokerContactName) {
                if (this.brokerContactName.split(".").length <= 1) {
                  this.brokerContactName =
                    this.brokerContactName + "." + fileExtension;
                }
              } else {
                this.brokerContactName = file.file.name;
              }
              this.campaignInfo.audienceData.brokerContactList.forEach(
                (item) => {
                  if (item.originalname == this.brokerContactName) {
                    this.brokerContactName =
                      this.brokerContactName.split(".")[0] +
                      "-" +
                      Date.now().toString() +
                      "." +
                      this.brokerContactName.split(".")[1];
                  }
                }
              );
              fileName = this.brokerContactName;
              file.file = new File([file.file], fileName);
              file.id = fileName;
              file.originalname = fileName;
              file.priority = false;
              this.campaignInfo.audienceData.audienceInfo[fileName] = rows;
              this.campaignInfo.audienceData.tempbrokerContactList.push(file);
            } else {
              this.uploadErr = true;
              this.uploadErrMsg = messages.invalidTypeErr;
              e.target.value = "";
            }
          }
        } else {
          this.uploadErr = true;
        }
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    parseFile(file) {
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          var data = new Uint8Array(reader.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheet = workbook.Sheets[workbook.SheetNames[0]];
          var range = XLSX.utils.decode_range(sheet["!ref"]);
          resolve(range.e.r);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    getFileType(fileType) {
      if (fileType.includes("csv")) {
        return "csv";
      } else if (fileType.includes("spreadsheetml.sheet")) {
        return "xlsx";
      } else if (fileType.includes("ms-excel")) {
        return "xls";
      }
      return null;
    },
    async campaignSubmit(e) {
      e.preventDefault();
      var _this = this;
      _this.submitting = true;
      _this.campaignInfo.audienceData.priorityInfo = {
        brokerContactList: [],
        contactList: [],
        companyList: [],
        activityReport: [],
      };
      this.campaignInfo.audienceData.brokerContactList.forEach((item) => {
        if (item.priority) {
          _this.campaignInfo.audienceData.priorityInfo.brokerContactList.push(
            item.originalname
          );
        }
      });
      this.campaignInfo.audienceData.contactList.forEach((item) => {
        if (item.priority) {
          _this.campaignInfo.audienceData.priorityInfo.contactList.push(
            item.originalname
          );
        }
      });
      this.campaignInfo.audienceData.companyList.forEach((item) => {
        if (item.priority) {
          _this.campaignInfo.audienceData.priorityInfo.companyList.push(
            item.originalname
          );
        }
      });
      this.campaignInfo.audienceData.activityReport.forEach((item) => {
        if (item.priority) {
          _this.campaignInfo.audienceData.priorityInfo.activityReport.push(
            item.originalname
          );
        }
      });
      var info = {
        propertyId: this.propertyId,
        campaignId: this.campaignId,
        campaignName: this.campaignInfo.campaignName,
        startDate: this.campaignInfo.startDate,
        monthlyBudget: this.campaignInfo.monthlyBudget,
        locations: this.campaignInfo.audienceData.locations
          ? this.campaignInfo.audienceData.locations.join("+")
          : null,
        top10Broker: this.campaignInfo.audienceData.top10Broker,
        showTrueSpend: this.campaignInfo.showTrueSpend,
        includesAudienceNetwork: this.campaignInfo.includesAudienceNetwork,
        addToDropdown: this.campaignInfo.addToDropdown,
        showTrueGoogleSpendAndView:
          this.campaignInfo.showTrueGoogleSpendAndView,
        hideMonthlyCostField: this.campaignInfo.hideMonthlyCostField,
        hideReportContractViews: this.campaignInfo.hideReportContractViews,
        hideTableContractedViews: this.campaignInfo.hideTableContractedViews,
        contactList: this.campaignInfo.audienceData.contactList,
        brokerContactList: this.campaignInfo.audienceData.brokerContactList,
        companyList: this.campaignInfo.audienceData.companyList,
        activityReport: this.campaignInfo.audienceData.activityReport,
        audienceInfo: this.campaignInfo.audienceData.audienceInfo,
        priorityInfo: this.campaignInfo.audienceData.priorityInfo,
        previewInfo: (({
          logo,
          adImage,
          adImage2,
          adImage3,
          adImage4,
          adImage5,
          adImage6,
          adVideo,
          files,
          ...o
        }) => o)(this.campaignInfo.previewInfo),
        contractType: this.campaignInfo.contractType,
        clientCPM: this.campaignInfo.clientCPM,
        customCPM:
          this.campaignInfo.customCPM == ""
            ? null
            : this.campaignInfo.customCPM,
        customCPMExtend:
          this.campaignInfo.customCPMExtend == ""
            ? null
            : this.campaignInfo.customCPMExtend,
        customGoogleDailyImpressions:
          this.campaignInfo.customGoogleDailyImpressions === ""
            ? null
            : this.campaignInfo.customGoogleDailyImpressions,
        extendDuration:
          this.campaignInfo.extendDuration == ""
            ? null
            : this.campaignInfo.extendDuration,
      };
      if (_this.isAdmin) {
        info.status = this.campaignInfo.status;
        info.endDate = this.campaignInfo.endDate;
        info.extendFrom = this.campaignInfo.extendFrom;
        info.extendTo = this.campaignInfo.extendTo;
      }
      if (info.campaignName == null) {
        info.campaignName = "Empty campaign ";
      }
      info.previewInfo.ctaType = "Learn More";
      const formData = new FormData();
      Object.keys(info).forEach((key) => {
        if (info[key]) {
          if (Array.isArray(info[key])) {
            if (info[key].length > 0) {
              info[key].forEach((item) => {
                if (typeof item == "string") {
                  formData.append(key + "[]", item);
                } else if (typeof item == "object") {
                  if (item && item.file) formData.append(key, item.file);
                  formData.append(key + "[]", JSON.stringify(item));
                }
              });
            } else {
              formData.append(key, info[key]);
            }
          } else {
            if (typeof info[key] == "object") {
              if (info[key] && info[key].file)
                formData.append(key, info[key].file);
              else formData.append(key, JSON.stringify(info[key]));
            } else {
              formData.append(key, info[key]);
            }
          }
        } else {
          if (key !== "campaignId") {
            formData.append(key, info[key]);
          }
        }
      });
      this.campaignInfo.previewInfo.files.forEach((file) => {
        formData.append("allFiles[]", JSON.stringify(file));
        if (file.file) formData.append("assests", file.file);
      });

      if (this.campaignInfo.previewInfo.logo == null) {
        formData.append("previewLogo", null);
      } else {
        if (this.campaignInfo.previewInfo.logo?.file) {
          formData.append(
            "previewLogo",
            this.campaignInfo.previewInfo.logo.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage == null) {
        formData.append("previewImage", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage?.file) {
          formData.append(
            "previewImage",
            this.campaignInfo.previewInfo.adImage.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage2 == null) {
        formData.append("previewImage2", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage2?.file) {
          formData.append(
            "previewImage2",
            this.campaignInfo.previewInfo.adImage2.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage3 == null) {
        formData.append("previewImage3", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage3?.file) {
          formData.append(
            "previewImage3",
            this.campaignInfo.previewInfo.adImage3.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage4 == null) {
        formData.append("previewImage4", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage4?.file) {
          formData.append(
            "previewImage4",
            this.campaignInfo.previewInfo.adImage4.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage5 == null) {
        formData.append("previewImage5", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage5?.file) {
          formData.append(
            "previewImage5",
            this.campaignInfo.previewInfo.adImage5.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adImage6 == null) {
        formData.append("previewImage6", null);
      } else {
        if (this.campaignInfo.previewInfo.adImage6?.file) {
          formData.append(
            "previewImage6",
            this.campaignInfo.previewInfo.adImage6.file
          );
        }
      }
      if (this.campaignInfo.previewInfo.adVideo == null) {
        formData.append("previewVideo", null);
      } else {
        if (this.campaignInfo.previewInfo.adVideo?.file) {
          formData.append(
            "previewVideo",
            this.campaignInfo.previewInfo.adVideo.file
          );
        }
      }
      var _this = this;
      const TIMEOUT_DURATION = 60000; // 1 minute
      const timeout = new Promise((_, reject) =>
        setTimeout(
          () =>
            reject(async () => {
              _this.updateErr = true;
              _this.updateErrMsg =
                "An error occurred while submitting the form, Walkthruit noticed this, will fix it soon!";
              await api().sendCampaignFailedNotification({
                campaignId: _this.campaignId,
              });
            }),
          TIMEOUT_DURATION
        )
      );
      try {
        const res = await Promise.race([
          api().postClientCampaignData(formData),
          timeout,
        ]);

        if (res.data && res.data.success) {
          const routeName = _this.isAdmin ? "edit_campaign" : "show_campaign";
          _this.$router.push({
            name: routeName,
            params: {
              campaignId: res.data.id,
              propertyId: _this.propertyId,
            },
          });
          _this.$store.commit(
            "setComponentKey",
            _this.$store.state.componentKey + 1
          );
        }
      } catch (err) {
        _this.updateErr = true;
        _this.updateErrMsg =
          "An error occurred while submitting the form, Walkthruit noticed this, will fix it soon!";
        await api().sendCampaignFailedNotification({
          campaignId: _this.campaignId,
        });
      } finally {
        _this.submitting = false;
      }
    },
    async getCampaignData() {
      var _this = this;
      var info = {
        campaignId: this.campaignId,
        token: this.token,
      };
      this.isInitialFetch = true;
      var res = await api().getWalkthruitCampaign(info);
      if (res.data && Object.keys(res.data).length > 0) {
        _this.campaignInfo.campaignName =
          res.data.campaignName !== "null" ? res.data.campaignName : "";
        _this.campaignInfo.status = res.data.status;
        if (res.data.startDate) {
          _this.campaignInfo.startDate = new Date(res.data.startDate)
            .toISOString()
            .slice(0, 10);
          // _this.preset = new Date(res.data.startDate)
          //   .toISOString()
          //   .slice(0, 10);
        }
        if (res.data.endDate) {
          _this.campaignInfo.endDate = new Date(res.data.endDate)
            .toISOString()
            .slice(0, 10);
        }
        if (res.data.extendFrom) {
          _this.campaignInfo.extendFrom = new Date(res.data.extendFrom)
            .toISOString()
            .slice(0, 10);
        }
        if (res.data.extendTo) {
          _this.campaignInfo.extendTo = new Date(res.data.extendTo)
            .toISOString()
            .slice(0, 10);
        }
        _this.campaignInfo.monthlyBudget =
          res.data.monthlyBudget == 0 ? 2000 : res.data.monthlyBudget;
        _this.campaignInfo.audienceData.locations =
          res.data.locations && res.data.locations != "null"
            ? res.data.locations.split("+")
            : [];
        _this.url = res.data.url !== "null" ? res.data.url : "";
        _this.campaignInfo.audienceData.contactList = res.data.contactList;
        _this.campaignInfo.audienceData.brokerContactList =
          res.data.brokerContactList;
        _this.campaignInfo.audienceData.companyList = res.data.companyList;
        _this.campaignInfo.audienceData.top10Broker = res.data.top10Broker;
        _this.campaignInfo.showTrueSpend = res.data.showTrueSpend;
        _this.campaignInfo.includesAudienceNetwork =
          res.data.includesAudienceNetwork;
        _this.campaignInfo.addToDropdown = res.data.addToDropdown;
        _this.campaignInfo.showTrueGoogleSpendAndView =
          res.data.showTrueGoogleSpendAndView;
        _this.campaignInfo.hideMonthlyCostField = res.data.hideMonthlyCostField;
        _this.campaignInfo.hideReportContractViews =
          res.data.hideReportContractViews;
        _this.campaignInfo.hideTableContractedViews =
          res.data.hideTableContractedViews;
        _this.campaignInfo.previewInfo = res.data.previewInfo;

        if (_this.campaignInfo.previewInfo.files.length > 0) {
          _this.campaignInfo.previewInfo.adType = 3;
        } else if (_this.campaignInfo.previewInfo.adImage) {
          _this.campaignInfo.previewInfo.adType = 2;
        } else if (_this.campaignInfo.previewInfo.adImage2) {
          _this.campaignInfo.previewInfo.adType = 4;
        } else if (_this.campaignInfo.previewInfo.adImage3) {
          _this.campaignInfo.previewInfo.adType = 5;
        } else if (_this.campaignInfo.previewInfo.adImage4) {
          _this.campaignInfo.previewInfo.adType = 6;
        } else if (_this.campaignInfo.previewInfo.adImage5) {
          _this.campaignInfo.previewInfo.adType = 7;
        } else if (_this.campaignInfo.previewInfo.adImage6) {
          _this.campaignInfo.previewInfo.adType = 8;
        } else if (_this.campaignInfo.previewInfo.adVideo) {
          _this.campaignInfo.previewInfo.adType = 1;
        } else {
          _this.campaignInfo.previewInfo.adType = 3;
        }

        _this.campaignInfo.contractType = res.data.contractType
          ? res.data.contractType
          : 1;
        _this.campaignInfo.clientCPM = res.data.clientCPM;
        _this.campaignInfo.customCPM = res.data.customCPM;
        _this.campaignInfo.customCPMExtend = res.data.customCPMExtend;
        _this.campaignInfo.customGoogleDailyImpressions =
          res.data.customGoogleDailyImpressions;
        _this.campaignInfo.extendDuration = res.data.extendDuration;
        if (_this.campaignInfo.previewInfo.cardInfo.length == 0) {
          _this.campaignInfo.previewInfo.cardInfo = [
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
          ];
        }
        _this.campaignInfo.publicUrl = res.data.publicUrl;
        _this.campaignInfo.audienceData.activityReport =
          res.data.activityReport;
        this.isInitialFetch = false;
        this.originalCampaignInfo = JSON.parse(
          JSON.stringify(toRaw(this.campaignInfo))
        );
      }
    },
    rerender(ids = []) {
      this.contactName = null;
      this.brokerContactName = null;
      this.companyName = null;
      // this.newActivityReport = null;
      this.reportName = null;
      this.campaignInfo.audienceData.tempcontactList = [];
      this.campaignInfo.audienceData.tempbrokerContactList = [];
      this.campaignInfo.audienceData.tempcompanyList = [];
      this.campaignInfo.audienceData.tempActivityReportList = [];
      ids.forEach((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).click();
        }
      });
    },
    removeUploadFile(file, fileType) {
      if (fileType === 1) {
        this.contactName = null;
        const index =
          this.campaignInfo.audienceData.tempcontactList.indexOf(file);
        if (index > -1)
          this.campaignInfo.audienceData.tempcontactList.splice(index, 1);
      } else if (fileType === 2) {
        this.companyName = null;
        const index =
          this.campaignInfo.audienceData.tempcompanyList.indexOf(file);
        if (index > -1)
          this.campaignInfo.audienceData.tempcompanyList.splice(index, 1);
      } else if (fileType === 3) {
        this.reportName = null;
        const index =
          this.campaignInfo.audienceData.tempActivityReportList.indexOf(file);
        if (index > -1)
          this.campaignInfo.audienceData.tempActivityReportList.splice(
            index,
            1
          );
      } else if (fileType === 4) {
        this.brokerContactName = null;
        const index =
          this.campaignInfo.audienceData.tempbrokerContactList.indexOf(file);
        if (index > -1)
          this.campaignInfo.audienceData.tempbrokerContactList.splice(index, 1);
      }
    },
    getImgUrl(file) {
      var fileType = this.getFileType(file.type);
      return new URL(`../assets/${fileType}.png`, import.meta.url).href;
    },
    reduceBudget() {
      if (this.campaignInfo.monthlyBudget > 500) {
        this.campaignInfo.monthlyBudget -= 50;
      }
    },
    addBudget() {
      // if (this.campaignInfo.monthlyBudget < 3000) {
      this.campaignInfo.monthlyBudget += 50;
      // }
    },
    updateList(fileType) {
      if (fileType === 1) {
        this.campaignInfo.audienceData.contactList = [
          ...this.campaignInfo.audienceData.tempcontactList,
          ...this.campaignInfo.audienceData.contactList,
        ];
        this.rerender(["uploadContactListCSVclose"]);
      } else if (fileType === 2) {
        this.campaignInfo.audienceData.companyList = [
          ...this.campaignInfo.audienceData.tempcompanyList,
          ...this.campaignInfo.audienceData.companyList,
        ];
        this.rerender(["uploadCompanyListCSVclose"]);
      } else if (fileType === 3) {
        this.campaignInfo.audienceData.activityReport = [
          ...this.campaignInfo.audienceData.tempActivityReportList,
          ...this.campaignInfo.audienceData.activityReport,
        ];
        this.rerender(["uploadActivityReportclose"]);
      } else if (fileType === 4) {
        this.campaignInfo.audienceData.brokerContactList = [
          ...this.campaignInfo.audienceData.tempbrokerContactList,
          ...this.campaignInfo.audienceData.brokerContactList,
        ];
        this.rerender(["uploadBrokerContactListCSVclose"]);
      }
    },
    isEqual(obj1, obj2) {
      obj1 = toRaw(obj1);
      obj2 = toRaw(obj2);

      if (obj1 === obj2) return true;
      if (obj1 == null || obj2 == null) {
        // console.log(111);
        // console.log(111, obj1);
        // console.log(111111, obj2);
        return false;
      }

      if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        // console.log(222);
        // console.log(222, typeof obj1);
        // console.log(2222222, typeof obj2);
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        // console.log(333);
        // console.log(333, keys1.length);
        // console.log(33333, keys2.length);
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key) || !this.isEqual(obj1[key], obj2[key])) {
          // console.log(444, key);
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    var _this = this;
    if (this.campaignId) {
      this.getCampaignData();
    }
    var myModals = document.getElementsByClassName("modal");
    for (let i = 0; i < myModals.length; i++) {
      var myModal = myModals[i];
      myModal.addEventListener("hidden.bs.modal", function () {
        _this.uploadErr = false;
        _this.uploadErrMsg = messages.uploadErr;
      });
    }
  },
  watch: {
    campaignId(newValue, oldValue) {
      if (oldValue == undefined && newValue !== undefined) {
        this.getCampaignData();
      }
    },
    "campaignInfo.clientCPM"(val) {
      if (val === 188 || val === "188") {
        this.campaignInfo.contractType = 1;
      } else if (val === 163 || val === "163") {
        this.campaignInfo.contractType = 2;
      } else if (val === 188.00001 || val === "188.00001") {
        ƒ;
        this.campaignInfo.contractType = 3;
      } else if (val === 188.00002 || val === "188.00002") {
        this.campaignInfo.contractType = 4;
      }
    },
    campaignInfo: {
      handler(newVal, oldVal) {
        if (
          !this.isInitialFetch &&
          !this.isInitialAudienceFetch &&
          !this.isEqual(newVal, this.originalCampaignInfo)
        ) {
          // console.log(1, this.isInitialFetch);
          // console.log(2, this.isInitialAudienceFetch);
          // console.log(3, this.isEqual(newVal, this.originalCampaignInfo));
          this.$emit("campaignStatusUpdate", true);
        }
      },
      deep: true,
    },
  },
};
</script>
