<template>
  <Modal id="addProperty">
    <template v-slot:title> Add Property </template>
    <template v-slot:body>
      <form @submit.prevent="addProperty">
        <Input v-model="property.name" type="text" label="Property Name" />
        <input v-on:change="onFileChange" type="file" label="Image" />
        <Input v-model="property.location" type="text" label="Property City" />
        <Input v-model="property.size" type="text" label="Property Size" />
        <Input v-model="property.asset" type="text" label="Property Asset" />
        <Input
          v-model="property.walkthru"
          type="text"
          label="3D Walkthru project names(separated by comma)"
        />
        <Input v-model="property.sitUrl" type="text" label="Site URL" />
        <div class="form-group mb-3">
          <label for="isClientSelect" class="form-label"
            >Property Internal/Client</label
          >
          <select
            class="form-select mb-0"
            v-model="property.isClient"
            id="isClientSelect"
          >
            <option
              v-for="(item, key) in isClientList"
              :key="key"
              :value="item"
            >
              {{ key }}
            </option>
          </select>
        </div>
        <p class="error-msg">{{ createErrorMsg }}</p>
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Add" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "CreateProperty",
  setup(props) {
    return {
      property: ref({
        name: null,
        img: null,
        location: null,
        size: null,
        asset: null,
        walkthru: null,
        isClient: null,
      }),
      submitting: ref(false),
      isClientList: ref({
        Client: true,
        Internal: false,
      }),
      createErrorMsg: ref(null),
    };
  },
  props: ["userId"],
  components: {
    Modal,
    Input,
  },
  methods: {
    addProperty(e) {
      e.preventDefault();
      this.submitting = true;
      var _this = this;
      var info = {
        users: [this.userId],
      };
      var clone;
      if (this.property.walkthru) {
        clone = this.property;
      } else {
        clone = (({ walkthru, ...o }) => o)(this.property);
      }
      info = { ...info, ...clone };
      const formData = new FormData();
      Object.keys(info).forEach((key) => {
        formData.append(key, info[key]);
      });
      api()
        .createProperty(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.submitting = false;
            _this.rerender("addPropertyclose");
          } else {
            _this.submitting = false;
            _this.createErrorMsg = res.data.data;
          }
        });
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.property.img = files[0];
    },
    clear() {
      this.property = {
        name: null,
        img: null,
        location: null,
        size: null,
        asset: null,
        walkthru: null,
      };
      this.submitting = false;
    },
  },
  mounted() {
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
