<template>
  <div class="welcome-screen">
    <div class="welcome-image"></div>
    <div class="form-wrapper">
      <form class="form-app" @submit.prevent="onSubmit">
        <img src="@/assets/walkthruit-full-black.png" class="form-logo" />
        <div class="title">Welcome!</div>
        <div class="sub-title">Sign in below:</div>
        <div class="form-content">
          <Input
            v-model="email"
            type="email"
            label="Email"
            childclass="login-email"
          />
          <PasswordInput
            title="Password"
            id="password"
            toggleId="togglePassword"
            v-model="password"
          />
          <p class="forgot-password text-right mt-2 mb-4">
            <router-link to="/forgot-password">Forgot password?</router-link>
          </p>
          <button type="submit" class="btn btn-primary wti-btn">Sign In</button>

          <div class="alert alert-info" v-if="notice">{{ notice }}</div>
          <div class="alert alert-danger" v-if="error">{{ error }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.welcome-screen
  display: flex
  flex-direction: row
  height: 100vh

  .welcome-image
    background-color: #06001A
    flex: 1
    background-image: url('@/assets/bg5.png')
    background-repeat: no-repeat
    background-size: auto 115%
    background-position: 90% 70%

  .form-wrapper
    flex: 1
    display: flex
    flex-direction: column
    align-content: center
    justify-content: center

    .form-app
      padding: 4em

      .form-logo
        height: 37px
        width: auto

      .title
        font-size: 32px
        font-weight: 700
        margin-top: 24px
        line-height: 40px

      .sub-title
        color: $LightGrey
        font-style: normal
        font-weight: 400
        margin-top: 10px
      .form-content
        margin-top: 40px
        .form-label
          margin-bottom: 0.5rem !important
          font-size: 16px !important
        .forgot-password,.sign-in
          text-align: right
          a
            color: $LightGrey
            text-decoration: none
            &:hover
              text-decoration: underline
        .btn-primary
          width: 100%
          border-radius: 8px
          padding: 15px
        .alert
          margin-top: 10px
          text-align: center
          border-radius: 8px
        .btn-auth
          background-color: $Blue
</style>

<script>
import api from "@/services/BackendConnection";
import Input from "@/components/Input.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import * as Sentry from "@sentry/vue";

export default {
  name: "LoginView",
  data() {
    return {
      email: null,
      password: null,
      notice: null,
      error: null,
    };
  },
  components: {
    Input,
    PasswordInput,
  },
  methods: {
    onSubmit(e) {
      var _this = this;
      e.preventDefault();
      var user = {
        email: _this.email,
        password: _this.password,
      };
      api()
        .signIn(user)
        .then((res) => {
          if (res.data) {
            var userInfo = res.data.user;
            if (userInfo && userInfo.exists) {
              _this.$store.commit("setUser", userInfo.data);
              Sentry.setUser({
                id: userInfo.data?.userId,
                email: userInfo.data?.email,
                username: userInfo.data?.userName,
                // Add any other relevant user information
              });
              _this.$store.commit("login");
              if (userInfo.data.isAdmin) {
                if (userInfo.data?.isTestAdmin) {
                  window.location.href =
                    window.location.origin + "/api/auth/facebook?forMeta=true";
                } else {
                  window.location.href =
                    window.location.origin + "/api/auth/facebook";
                }
              } else {
                _this.$router.push({ name: "home" });
              }
            } else {
              _this.error = userInfo.data;
            }
          }
        });
    },
  },
  created() {},
};
</script>
