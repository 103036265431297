<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white nav-public">
    <div class="container">
      <div class="navbar-brand">
        <router-link
          class="nav-link-mobile-home"
          aria-current="page"
          :to="{ name: 'home' }"
        >
          <img
            src="@/assets/walkthruit-full-black.png"
            alt=""
            height="37"
            class="d-inline-block align-text-top"
          />
        </router-link>
      </div>
    </div>
  </nav>
  <div class="inner-dashboard">
    <div class="container h-100 pt-4">
      <div
        class="property-dashboard-content-heading row justify-content-between"
      >
        <div class="heading-text col-12 col-md-4 mb-4">
          <div class="current-property">
            {{ campaignName }}
          </div>
          <div class="current-route">Ad Reporting</div>
        </div>
        <div
          class="col-12 col-lg-5 buttons d-flex justify-content-end"
          v-if="isMockUser"
        >
          <button
            type="button"
            class="btn btn-primary wti-btn wti-btn-white share-button"
            data-bs-toggle="modal"
            data-bs-target="#sharePublicReportURL"
          >
            Share URL
          </button>
        </div>
        <div class="col-12 col-md-3 date-range-picker position-relative">
          <loading
            v-model:active="campaignLoading"
            :is-full-page="false"
            loader="dots"
            :opacity="1"
          />
          <div v-if="!campaignLoading">
            <VueDatePicker
              v-model="dateRange"
              range
              multi-calendars
              :min-date="campaignStartDate"
              :max-date="yesterday"
              v-if="!isLoading"
              :enable-time-picker="false"
            >
              <template #left-sidebar>
                <div v-for="(val, key) in rangeTypes" :key="key">
                  <button
                    type="button"
                    class="btn"
                    :class="{ active: this.rangeType == key }"
                    @click="updateRange(key)"
                  >
                    {{ val }}
                  </button>
                </div>
              </template>
            </VueDatePicker>
          </div>
        </div>
      </div>
      <CampaignReport
        v-if="campaignId && dateRange.length > 0"
        :propertyId="propertyId"
        :campaignId="campaignId"
        :dateRange="dateRange"
        :token="token"
        :users="users"
        @updateIsLoading="updateIsLoading"
      />
    </div>
    <Modal id="sharePublicReportURL" v-if="publicUrl">
      <template v-slot:title>Shareable URL</template>
      <template v-slot:body>
        <p class="border section mt-2 text-break">{{ publicUrl }}</p>
        <button
          type="button"
          v-clipboard:copy="publicUrl"
          class="btn btn-dark wti-btn-black"
        >
          Copy
        </button>
      </template>
    </Modal>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
.buttons .btn.share-button
  line-height: 16px
  +mobile
    margin-bottom: 10px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import moment from "moment";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import CampaignReport from "@/components/CampaignReport.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "PublicWalkthruitCampaignReportView",
  setup(props) {
    var yesterday = moment().subtract(1, "days").toDate();
    return {
      yesterday: yesterday,
      dateRange: ref([]),
      rangeType: ref("thisMonth"),
      rangeTypes: ref({
        yesterday: "Yesterday",
        last7: "Last 7 Days",
        last30: "Last 30 Days",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        all: "All",
      }),
      isLoading: ref(false),
      campaignName: ref(null),
      startDate: ref(null),
      endDate: ref(null),
      propertyId: ref(null),
      campaignId: ref(null),
      token: ref(null),
      users: ref([]),
      campaignLoading: ref(false),
      publicUrl: ref(null),
      mockUserId: ref(import.meta.env.VITE_MOCK_ACCOUNT_ID),
    };
  },
  props: {},
  components: {
    PropertyDashboardContentHeading,
    CampaignReport,
    Modal,
  },
  computed: {
    campaignStartDate() {
      if (this.startDate) {
        return this.startDate;
      } else {
        return moment("2022-10-01").utc().toDate();
      }
    },
    isMockUser() {
      if (this.users && this.users.includes(this.mockUserId)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getCampaignByToken() {
      var _this = this;
      var info = { token: this.token };
      _this.campaignLoading = true;
      api()
        .getCampaignByToken(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.campaignName = res.data.campaignName;
            _this.propertyId = res.data.propertyId;
            _this.campaignId = res.data.campaignId;
            _this.startDate = res.data.startDate;
            _this.endDate = res.data.endDate;
            _this.users = res.data.users;
            var start = _this.startDate
              ? moment(_this.startDate).utc().format("YYYY-MM-DD HH:mm:ss")
              : moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss");
            var endDate = _this.endDate
              ? moment(_this.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
              : _this.yesterday;
            if (res.data.campaignId == "652d4ce096d5ae0503069180") {
              _this.dateRange = [
                moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss"),
                moment("2023-02-28").utc().format("YYYY-MM-DD HH:mm:ss"),
              ];
            } else {
              _this.dateRange = [start, endDate];
              if (moment(start).utc().isSameOrAfter(moment(endDate).utc())) {
                _this.dateRange = [start, start];
              }
            }
            _this.publicUrl = res.data.publicUrl;
            _this.campaignLoading = false;
          }
        });
    },
    updateIsLoading(value) {
      this.isLoading = value;
    },
    updateRange(type) {
      var yesterday = this.yesterday;
      this.rangeType = type;
      switch (type) {
        case "yesterday":
          this.dateRange = [yesterday, yesterday];
          break;
        case "last7":
          this.dateRange = [
            moment(yesterday).subtract(6, "d").toDate(),
            yesterday,
          ];
          break;
        case "last30":
          this.dateRange = [
            moment(yesterday).subtract(29, "d").toDate(),
            yesterday,
          ];
          break;
        case "thisMonth":
          this.dateRange = [
            moment(yesterday).startOf("month").toDate(),
            yesterday,
          ];
          break;
        case "lastMonth":
          this.dateRange = [
            moment(yesterday).subtract(1, "months").startOf("month"),
            moment(yesterday).subtract(1, "months").endOf("month"),
          ];
          break;
        case "all":
          var start = this.startDate
            ? moment(this.startDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss");
          var endDate = this.endDate
            ? moment(this.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : yesterday;
          this.dateRange = [start, endDate];
          break;
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.getCampaignByToken();
  },
  watch: {
    dateRange(val) {
      if (val && !val[1]) {
        this.dateRange[1] = this.dateRange[0];
      }
    },
  },
};
</script>
