import axios from 'axios'
import store from '@/store.js'
import router from '@/router/index.js';

export default () => {
    const axiosInstance = axios.create({
        headers: {
            common: {
                'x-access-token': 'Bearer ' + (store.state.user ? store.state.user.accessToken : ''),
                authorization: true,
            }
        },
        withCredentials: true,
        baseURL: "/api",
    })
    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response?.status) {
            router.push({ name: "logout" });
            return Promise.resolve(error.response);
        } else {
            return Promise.reject(error);
        }
    });
    return axiosInstance;
}