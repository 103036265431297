<template>
  <div class="switches-wrapper d-flex flex-row-reverse">
    <div>
      <button
        type="button"
        class="btn btn-primary wti-btn button-new-audience"
        data-bs-toggle="modal"
        data-bs-target="#addAudience"
        v-show="!isAdmin && !assetSpecific"
      >
        Upload List
      </button>
    </div>
    <div class="switches">
      <div
        class="switch"
        :class="{ active: assetSpecific }"
        @click="changeLevel(true)"
      >
        {{ propertyName }}
      </div>
      <div
        class="switch"
        :class="{ active: !assetSpecific }"
        @click="changeLevel(false)"
      >
        Contact/Company Lists (Account-Wide)
      </div>
    </div>
  </div>
  <addAudienceForm :userId="userId" @updateAudience="updateAudience" />
  <div class="my-3">
    <DatatableComponent
      :data="contactCompanyData"
      :keys="keys"
      v-if="tabIndex == 2"
      :options="{
        dom: 'frtl',
        responsive: true,
        order: [[2, 'desc']],
        lengthMenu: [
          [10, 25, 50, -1],
          [10, 25, 50, 'All'],
        ],
      }"
    />
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
@import 'datatables.net-bs5'
.switches-wrapper
  text-align: center
  margin-bottom: 38px
  .button-new-audience
    font-size: 16px
    line-height: 20px
    font-weight: 600
    padding: 16px 50px
    border-radius: 12px
    +mobile
      font-size: 12px
      padding: 10px
      border-radius: 0.5rem
.switches
  display: inline-flex
  background-color: $LighterGrey
  padding: 4px
  border-radius: 14px
  margin: 0 auto

  .switch
    font-weight: 600
    transition: 0.1s ease-out
    cursor: pointer
    padding: 14px 20px
    border-radius: 14px
    background-color: transparent
    color: $LightGrey
    +mobile
      font-size: 10px
      padding: 10px
      border-radius: 0.375rem

    &.active
      transition: 0.1s ease-out
      background-color: $Main
      color: white
.datatable
  .dataTables_length
    margin-top: 20px
    select.form-select
      padding: 0.375rem 2.25rem 0.375rem 0.75rem
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import Modal from "@/components/Modal.vue";
import downloadIMG from "@/assets/download.png";
import addAudienceForm from "@/components/AddAudienceForm.vue";
import DatatableComponent from "@/components/DatatableComponent.vue";
import moment from "moment";

export default {
  name: "ContactCompanyListTab",
  setup(props) {
    return {
      audiences: ref(null),
      newAudiences: ref(null),
      keys: ["List Name", "Type", "Date Uploaded", "Count"],
      propertyName: ref(null),
    };
  },
  data() {
    return {
      assetSpecific: true,
      uploading: false,
    };
  },
  components: { Modal, addAudienceForm, DatatableComponent },
  props: ["propertyId", "tabIndex"],
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
    userId() {
      var userId = this.$store.state.user?.userId;
      var user = this.$store.state.user;
      var isAdmin = user?.isAdmin ? user.isAdmin : false;
      if (isAdmin) {
        var clientViwe = this.$store.state.user.adminClientView;
        if (clientViwe) {
          userId = this.$store.state.user.currentUserId;
        }
      }
      return userId;
    },
    contactCompanyData() {
      let _this = this;
      if (_this.audiences) {
        let tempArray = [];
        _this.audiences.companyList.map(function (value, key) {
          let name =
            '<span class="">' +
            value.originalname +
            '</span><a target="_blank" download="" href="' +
            value.url +
            '"><img height="20" src="' +
            downloadIMG +
            '"></a>';
          let audienceType = "Tenant Company Targets";
          let createdAt = moment(value.createdAt.split("T")[0])
            .utc()
            .format("MM/DD/YY");
          let rows = value.rows ? value.rows : "-";
          tempArray.push([name, audienceType, createdAt, rows]);
        });
        _this.audiences.contactList.map(function (value, key) {
          let name =
            '<span class="">' +
            value.originalname +
            '</span><a target="_blank" download="" href="' +
            value.url +
            '"><img height="20" src="' +
            downloadIMG +
            '"></a>';
          let audienceType = "Tenant Contact Targets";
          let createdAt = moment(value.createdAt.split("T")[0])
            .utc()
            .format("MM/DD/YY");
          let rows = value.rows ? value.rows : "-";
          tempArray.push([name, audienceType, createdAt, rows]);
        });

        _this.audiences.brokerContactList.map(function (value, key) {
          let name =
            '<span class="">' +
            value.originalname +
            '</span><a target="_blank" download="" href="' +
            value.url +
            '"><img height="20" src="' +
            downloadIMG +
            '"></a>';
          let audienceType = "Broker Targets";
          let createdAt = moment(value.createdAt.split("T")[0])
            .utc()
            .format("MM/DD/YY");
          let rows = value.rows ? value.rows : "-";
          tempArray.push([name, audienceType, createdAt, rows]);
        });

        _this.audiences.activityReports.map(function (value, key) {
          let name =
            '<span class="">' +
            value.originalname +
            '</span><a target="_blank" download="" href="' +
            value.url +
            '"><img height="20" src="' +
            downloadIMG +
            '"></a>';
          let audienceType = "Leasing Activity Report";
          let createdAt = "-";
          if (value.createdAt) {
            createdAt = moment(value.createdAt).utc().format("MM/DD/YY");
          }
          tempArray.push([name, audienceType, createdAt, "-"]);
        });

        return tempArray;
      } else {
        return [];
      }
    },
  },
  methods: {
    changeLevel(assetSpecific) {
      this.assetSpecific = assetSpecific;
      this.getAudiences();
    },
    getAudiences() {
      var _this = this;
      var info = {
        propertyId: this.propertyId,
        assetSpecific: _this.assetSpecific,
        userId: _this.userId,
        isAdmin: _this.isAdmin,
      };
      api()
        .getAudiences(info)
        .then((res) => {
          if (res.data) {
            _this.audiences = res.data;
          }
        });
    },
    getPropertyName() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .getPropertyName(info)
        .then((res) => {
          if (res.data) {
            _this.propertyName = res.data;
          }
        });
    },
    updateAudience() {
      this.getAudiences();
    },
  },
  mounted() {
    this.getAudiences();
    this.getPropertyName();
  },
};
</script>
