<template>
  <Modal id="addProject">
    <template v-slot:title>
      {{ success ? "Success!" : "New Project" }}
    </template>
    <template v-slot:body>
      <div v-if="!success">
        <div class="instruction">
          Fill out the form below and we'll contact you as soon as we can
        </div>
        <form @submit.prevent="addProject" class="needs-validation" novalidate>
          <Input
            v-model="name"
            type="text"
            label="Name"
            childclass="project-name"
            :required="true"
          />
          <Input
            v-model="email"
            type="email"
            label="Email"
            childclass="project-email"
            :required="true"
          />
          <Input
            v-model="phone"
            type="tel"
            label="Phone"
            childclass="project-phone"
            :required="true"
          />
          <Select
            v-model="projectType"
            label="Project Type"
            :options="projectTypes"
            :required="true"
          />
          <Input
            v-model="projectName"
            type="text"
            label="Project Name"
            :required="true"
          />
          <button type="submit" class="btn btn-primary wti-btn-black">
            <span
              v-if="submitting"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ submitting ? "" : "Submit" }}
          </button>
        </form>
      </div>
      <div v-else>
        <div class="instruction">
          Your request has been successfully sent to our team. We'll get back to
          you as soon as we can.
        </div>
        <button type="button" class="btn wti-btn-black" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </template>
  </Modal>
</template>
<style lang="sass">
@import "@/assets/vars.sass"
.modal-content
  padding: 12px
.modal-body
  .form-label
    margin-bottom: 0.5rem !important
  .instruction
    text-align: center
    max-width: 320px
    margin: 0 auto
    padding: 30px 0
    font-size: 18px
    line-height: 30px
    white-space: normal
  .form-control
    padding: 16px 20px
    margin-bottom: 1em
  .btn
    border-radius: 12px
    background-color: $Main
    font-size: 16px
    line-height: 20px
    padding: 16px 44px
    +mobile
      padding: 10px !important
      font-size: 12px !important
      border-radius: 0.5rem !important
</style>
<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "NewProjectForm",
  setup(props) {
    return {
      name: ref(null),
      email: ref(null),
      phone: ref(null),
      projectType: ref(null),
      projectName: ref(null),
      submitting: ref(false),
      success: ref(false),
      projectTypes: ref([
        "Digital Ads",
        "Marketing Video",
        "3D Walkthru",
        "New Development Package",
        "Interactive Space Calculator",
        "Other",
      ]),
    };
  },
  props: ["userId"],
  components: {
    Modal,
    Input,
    Select
  },
  methods: {
    addProject(e) {
      e.preventDefault();
      if (this.phone && this.projectName && this.name && this.email && this.projectType) {
        this.submitting = true;
        var info = {
          userId: this.userId,
          name: this.name,
          email: this.email,
          phone: this.phone,
          projectName: this.projectName,
          projectType: this.projectType,
        };
        api()
          .newProjectRequest(info)
          .then((res) => {
            if (res.data && res.data.success) {
              this.submitting = false;
              this.success = true;
            }
          });
      } else {
        return;
      }
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    clear() {
      this.name = null;
      this.email = null;
      this.phone = null;
      this.projectName = null;
      this.projectType = null;
      this.success = false;
      var forms = document.querySelectorAll(".needs-validation");
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach(function (form) {
        if (form.classList.contains("was-validated")) {
          form.classList.remove("was-validated");
        }
      });
    },
  },
  mounted() {
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
