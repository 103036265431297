<template>
  <DataTable
    :columns="columns"
    :data="data"
    :bAutoWidth="true"
    class="table table-hover table-striped"
    :options="options"
    ref="table"
  >
    <thead>
      <tr>
        <th v-for="(key, index) in keys" :key="index">
          {{ key }}
          <span
            class="table-tooltip"
            v-tooltip="'this is the tooltip'"
            v-if="tooltipKeys.includes(key)"
          >
            <img src="@/assets/tooltip.png" />
          </span>
        </th>
      </tr>
    </thead>
  </DataTable>
</template>

<style lang="sass">
.table-tooltip
  color: #000
  position: absolute
  top: -5px
  right: 26px
</style>

<script setup>
import { ref } from "vue";
const props = defineProps(["data", "columns", "options", "keys", "tooltip"]);
const tooltipKeys = [];
</script>
