<template>
  <div class="container" v-if="data.length > 0">
    <div class="row">
      <div class="col">
        <div class="p-3">
          <h3>{{ platform.charAt(0).toUpperCase() + platform.slice(1) }}</h3>
        </div>
      </div>
    </div>
    <table class="table table-color table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Campaign Name</th>
          <th scope="col">Objective</th>
          <th scope="col">Leads Notification Enabled</th>
          <th scope="col">Created At</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody class="tbody-color">
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <a :href="adManagerUrl + item.campaignId" target="_blank">
              {{ item.campaignName }}</a
            >
          </td>
          <td>{{ item.objective }}</td>
          <td>
            <font-awesome-icon
              :class="{
                checked: item.fbLeadNotificationEnabled,
                unchecked: !item.fbLeadNotificationEnabled,
              }"
              :icon="['fas', 'circle-check']"
            />
          </td>
          <td>{{ item.createdAt }}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary me-4"
              @click="updateCampaign(item, platform)"
              data-bs-toggle="modal"
              :data-bs-target="'#editCampaign' + platform"
            >
              <font-awesome-icon :icon="['fas', 'edit']" />
            </button>
            <button
              type="button"
              class="btn btn-danger me-4"
              @click="updateCampaign(item, platform)"
              data-bs-toggle="modal"
              :data-bs-target="'#deleteCampaign' + platform"
            >
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="updateCampaign(item, platform)"
              data-bs-toggle="modal"
              :data-bs-target="'#registerNotification' + platform"
              v-if="!item.fbLeadNotificationEnabled"
            >
              <font-awesome-icon :icon="['fas', 'link']" />
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="updateCampaign(item, platform)"
              data-bs-toggle="modal"
              :data-bs-target="'#registerNotification' + platform"
              v-else
              disabled
            >
              <font-awesome-icon :icon="['fas', 'link']" />
            </button>
            <button
              type="button"
              class="btn btn-primary ms-4"
              @click="getReportData(item)"
              v-if="isTestAdmin"
            >
              <font-awesome-icon :icon="['fas', 'chart-simple']" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div>{{ reportData }}</div>
  </div>
  <Modal :id="'editCampaign' + platform">
    <template v-slot:title> Edit {{ item.campaignName }} campaign </template>
    <template v-slot:body>
      <form @submit.prevent="editCampaign">
        <Input v-model="item.campaignName" type="text" label="Campaign Name" />
        <Select
          v-model="item.objective"
          label="Campaign Objective"
          :options="facebookObjective"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Update" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal :id="'deleteCampaign' + platform">
    <template v-slot:title>
      Are you sure to delete {{ item.campaignName }} campaign?
    </template>
    <template v-slot:body>
      <form @submit.prevent="deleteCampaign">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Name"
          :disable="true"
        />
        <Select
          v-model="item.objective"
          label="Campaign Objective"
          :options="facebookObjective"
          :selectedItem="item.objective"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Delete" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal :id="'registerNotification' + platform">
    <template v-slot:title>
      Please copy the page id then fill out the form below:
    </template>
    <template v-slot:body>
      <p>You can find the page id follow the example below:</p>
      <img src="@/assets/page-ID-example.png" alt="" style="width: 100%" />
      <form @submit.prevent="registerNotification">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Name"
          :disable="true"
        />
        <Input v-model="item.fbpageId" type="text" label="Page ID" />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Register" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import campaignData from "@/utils/campaignData.json";
import { ref } from "vue";
import api from "@/services/BackendConnection";
import moment from "moment";

export default {
  name: "FacebookCampaignTable",
  setup(props) {
    return {
      facebookObjective: campaignData.campaign.facebook.objective,
      facebookStatus: campaignData.campaign.facebook.effective_status,
      insAdPlacements: campaignData.campaign.instagram.objective,
      insAdPlacement: ref(null),
      item: ref({
        campaignId: null,
        campaignName: null,
        objective: null,
        status: null,
      }),
      submitting: ref(false),
      reportData: ref(null),
    };
  },
  props: ["data", "platform"],
  components: {
    Modal,
    Input,
    Select,
  },
  computed: {
    adManagerUrl() {
      return (
        "https://adsmanager.facebook.com/adsmanager/manage/adsets?act=" +
        import.meta.env.VITE_IDLE_DURATION +
        "&selected_campaign_ids="
      );
    },
    isTestAdmin() {
      var isTestAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isTestAdmin = user?.isTestAdmin ? user.isTestAdmin : false;
      }
      return isTestAdmin;
    },
  },
  methods: {
    updateCampaign(item, platform) {
      this.item.campaignId = item.campaignId;
      this.item.campaignName = item.campaignName;
      this.item.objective = item.objective;
      this.item.status = item.status;
    },
    editCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
        name: this.item.campaignName,
        status: this.item.status,
        objective: this.item.objective,
        special_ad_categories: [],
      };
      api()
        .editFacebookCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("editCampaign" + this.platform + "close");
          }
        });
    },
    deleteCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
      };
      api()
        .deleteFacebookCampaign(info)
        .then((res) => {
          if (!res.data || !res.data.success) {
            alert(res.data.data);
          }
          this.submitting = false;
          this.rerender("deleteCampaign" + this.platform + "close");
        });
    },
    registerNotification(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
        pageId: this.item.fbpageId,
      };
      api()
        .registerFBNotification(info)
        .then((res) => {
          if (!res.data || !res.data.success) {
            alert(res.data.data);
          }
          this.submitting = false;
          this.rerender("registerNotification" + this.platform + "close");
        });
    },
    getReportData(item) {
      var info = {
        campaignId: item.campaignId,
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
      api()
        .facebookCampaignInsight(info)
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.reportData = res.data;
          }
        });
    },
    clear() {
      this.item.campaignId = null;
      this.item.campaignName = null;
      this.item.objective = null;
      this.item.status = null;
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
  },
  mounted() {
    var myModals = document.querySelectorAll(".modal");
    var _this = this;
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
