<template>
  <div>
    <div class="section">
      <div class="accordion accordion-flush">
        <div class="accordion-item border-0">
          <h2 class="accordion-header" id="flush-audience-broker">
            <button
              class="accordion-button collapsed px-0 pb-2 mb-0 form-label"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-broker-audience"
              aria-expanded="false"
              aria-controls="collapse-broker-audience"
            >
              Broker Audiences
            </button>
          </h2>
        </div>
        <p class="form-info">
          Select or add broker audiences you want your ad to be seen by. You do
          not need to fill out all audience options.
        </p>
        <div
          id="collapse-broker-audience"
          class="accordion-collapse collapse show"
          aria-labelledby="flush-audience-broker"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="audience-item">
            <div class="item-content d-flex align-items-baseline">
              <div>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('brokerTargets', false)"
                  v-if="isBrokerTargetsOpen"
                  :disabled="viewOnly"
                >
                  <img src="@/assets/remove-image.svg" />
                </button>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('brokerTargets', true)"
                  :disabled="viewOnly"
                  v-else
                >
                  <img src="@/assets/add-image.svg" />
                </button>
              </div>
              <div class="d-flex flex-wrap">
                <div class="item-title align-self-center">Broker Targets</div>
                <button
                  type="button"
                  class="btn upload-button wti-btn-black"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadBrokerContactListCSV"
                  v-if="!viewOnly"
                >
                  Upload New Broker List CSV
                </button>
                <button
                  type="button"
                  class="btn upload-button wti-btn previous-btn"
                  @click="toogleList('brokerContactList', true)"
                  v-if="!viewOnly"
                >
                  Previous Broker List
                </button>
                <JsonCSV
                  :data="brokerTargets"
                  :name="campaignName + '-broker-targets.csv'"
                  class="export-btn"
                  v-if="isAdmin"
                >
                  <button class="btn upload-button previous-btn wti-btn-black">
                    Export to CSV
                  </button>
                </JsonCSV>
              </div>
            </div>
            <div class="select-box">
              <div class="multiselect__tags" v-show="isBrokerTargetsOpen">
                <input
                  class="multiselect__input custom-input"
                  id="brokerTargets-input"
                  v-model="newBrokerTarget"
                  @keyup.enter="addBrokerTarget"
                />
              </div>
              <VueMultiselect
                ref="brokerContactmultiselect"
                v-model="audienceData.brokerContactList"
                :options="audienceData.existedAudiencesList.brokerContactList"
                :multiple="true"
                :searchable="true"
                :showNoOptions="false"
                :disabled="viewOnly"
                label="originalname"
                track-by="originalname"
                :hide-selected="true"
                placeholder=""
              >
                <template #tag="{ option, remove }">
                  <span
                    class="multiselect__tag"
                    :class="{ csv_tag: viewOnly && option.targetType == 1 }"
                    :id="option.key"
                  >
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      v-if="!option.priority"
                      :disabled="viewOnly"
                    >
                      <img
                        src="@/assets/round-checkbox.png"
                        class="priority-checkbox"
                      />
                    </button>
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      :disabled="viewOnly"
                      v-else
                    >
                      &#128293;
                    </button>
                    {{ option.originalname }}
                    <span v-if="option.targetType == 1">
                      — [{{ option.rows }}]
                    </span>
                    <i
                      tabindex="1"
                      class="multiselect__tag-icon"
                      @click="remove(option)"
                    ></i>
                  </span>
                </template>
              </VueMultiselect>
            </div>
          </div>
          <div class="audience-item multiselect-wrapper">
            <div class="item-content d-flex align-items-center">
              <button
                class="btn select-button"
                type="button"
                @click="toogleList('location', false)"
                v-if="isLocationOpen"
                :disabled="viewOnly"
              >
                <img src="@/assets/remove-image.svg" />
              </button>
              <button
                class="btn select-button"
                type="button"
                @click="toogleList('location', true)"
                v-else
                :disabled="viewOnly"
              >
                <img src="@/assets/add-image.svg" />
              </button>
              <div class="item-title align-self-center">Broker Markets</div>
            </div>
            <div class="select-box">
              <VueMultiselect
                ref="locationmultiselect"
                v-model="audienceData.locations"
                :options="cities"
                openDirection="bottom"
                :multiple="true"
                :searchable="true"
                :hide-selected="true"
                @search-change="asyncFind"
                placeholder=""
                :disabled="viewOnly"
                :showNoResults="isLoading"
                :showNoOptions="false"
                @open="isLocationOpen = true"
                @close="isLocationOpen = false"
              >
                <template #noResult>
                  Loading
                  <pulse-loader :loading="true" size="5px"></pulse-loader>
                </template>
              </VueMultiselect>
            </div>
          </div>
          <div class="audience-item no-pb">
            <div class="item-content d-flex align-items-center">
              <div class="roundCheck">
                <input
                  type="checkbox"
                  v-model="audienceData.top10Broker"
                  value="1"
                  :disabled="viewOnly"
                  id="top10Broker"
                />
                <label for="top10Broker">
                  <span class="roundContainer"></span>
                  <div class="item-title align-self-center">
                    Nation’s Top 10% Grossing Tenant Brokers
                    <span class="audience-info">
                      &nbsp;Get your listing in front of the brokers that
                      represent the most tenants across the country.
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="accordion accordion-flush">
        <div class="accordion-item border-0">
          <h2 class="accordion-header" id="flush-audience">
            <button
              class="accordion-button collapsed px-0 pb-2 mb-0 form-label"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-audience"
              aria-expanded="false"
              aria-controls="collapse-audience"
            >
              Tenant Audiences
            </button>
          </h2>
        </div>
        <p class="form-info">
          Select or add tenant audiences you want your ad to be seen by. You do
          not need to fill out all audience options.
        </p>
        <div
          id="collapse-audience"
          class="accordion-collapse collapse show"
          aria-labelledby="flush-audience"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="audience-item">
            <div class="item-content d-flex align-items-baseline">
              <div>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('tenantTargets', false)"
                  v-if="isTenantTargetsOpen"
                  :disabled="viewOnly"
                >
                  <img src="@/assets/remove-image.svg" />
                </button>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('tenantTargets', true)"
                  :disabled="viewOnly"
                  v-else
                >
                  <img src="@/assets/add-image.svg" />
                </button>
              </div>
              <div class="d-flex flex-wrap">
                <div class="item-title align-self-center">
                  Tenant Contact Targets
                </div>
                <button
                  type="button"
                  class="btn upload-button wti-btn-black"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadContactListCSV"
                  v-if="!viewOnly"
                >
                  Upload New Tenant List CSV
                </button>
                <button
                  type="button"
                  class="btn upload-button wti-btn previous-btn"
                  @click="toogleList('contactList', true)"
                  v-if="!viewOnly"
                >
                  Previous Tenant Contact List
                </button>
              </div>
            </div>
            <div class="select-box">
              <div class="multiselect__tags" v-show="isTenantTargetsOpen">
                <input
                  class="multiselect__input custom-input"
                  id="tenantTargets-input"
                  v-model="newTenantTarget"
                  @keyup.enter="addTenantTarget"
                />
              </div>
              <VueMultiselect
                ref="contactmultiselect"
                v-model="audienceData.contactList"
                :options="audienceData.existedAudiencesList.contactList"
                :multiple="true"
                :searchable="true"
                :showNoOptions="false"
                :disabled="viewOnly"
                label="originalname"
                track-by="originalname"
                :hide-selected="true"
                placeholder=""
              >
                <template #tag="{ option, remove }">
                  <span
                    class="multiselect__tag"
                    :class="{ csv_tag: viewOnly && option.targetType == 1 }"
                    :id="option.key"
                  >
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      :disabled="viewOnly"
                      v-if="!option.priority"
                    >
                      <img
                        src="@/assets/round-checkbox.png"
                        class="priority-checkbox"
                      />
                    </button>
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      :disabled="viewOnly"
                      @click="option.priority = !option.priority"
                      v-else
                    >
                      &#128293;
                    </button>
                    {{ option.originalname }}
                    <span v-if="option.targetType == 1">
                      — [{{ option.rows }}]
                    </span>
                    <i
                      tabindex="1"
                      class="multiselect__tag-icon"
                      @click="remove(option)"
                    ></i>
                  </span>
                </template>
              </VueMultiselect>
            </div>
          </div>
          <div class="audience-item">
            <div class="item-content d-flex align-items-baseline">
              <div>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('companyTargets', false)"
                  v-if="isCompanyTargetsOpen"
                  :disabled="viewOnly"
                >
                  <img src="@/assets/remove-image.svg" />
                </button>
                <button
                  class="btn select-button"
                  type="button"
                  @click="toogleList('companyTargets', true)"
                  v-else
                  :disabled="viewOnly"
                >
                  <img src="@/assets/add-image.svg" />
                </button>
              </div>
              <div class="d-flex flex-wrap">
                <div class="item-title align-self-center">
                  Tenant Company Targets
                </div>
                <button
                  type="button"
                  class="btn upload-button wti-btn-black"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadCompanyListCSV"
                  v-if="!viewOnly"
                >
                  Upload New Tenant Company List CSV
                </button>
                <button
                  type="button"
                  class="btn upload-button wti-btn previous-btn"
                  @click="toogleList('companyList', true)"
                  v-if="!viewOnly"
                >
                  Previous Tenant Company List
                </button>
              </div>
            </div>
            <div class="select-box">
              <div class="multiselect__tags" v-show="isCompanyTargetsOpen">
                <input
                  class="multiselect__input custom-input"
                  id="companyTargets-input"
                  v-model="newCompanyTarget"
                  @keyup.enter="addCompanyTarget"
                />
              </div>
              <VueMultiselect
                ref="companylistmultiselect"
                v-model="audienceData.companyList"
                :options="audienceData.existedAudiencesList.companyList"
                :multiple="true"
                :searchable="true"
                :hide-selected="true"
                :showNoOptions="false"
                :disabled="viewOnly"
                label="originalname"
                track-by="originalname"
                placeholder=""
              >
                <template #tag="{ option, remove }">
                  <span
                    class="multiselect__tag"
                    :class="{ csv_tag: viewOnly && option.targetType == 1 }"
                    :id="option.key"
                  >
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      :disabled="viewOnly"
                      v-if="!option.priority"
                    >
                      <img
                        src="@/assets/round-checkbox.png"
                        class="priority-checkbox"
                      />
                    </button>
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      :disabled="viewOnly"
                      @click="option.priority = !option.priority"
                      v-else
                    >
                      &#128293;
                    </button>
                    {{ option.originalname }}
                    <span v-if="option.targetType == 1">
                      — [{{ option.rows }}]
                    </span>
                    <i
                      tabindex="1"
                      class="multiselect__tag-icon"
                      @click="remove(option)"
                    ></i>
                  </span>
                </template>
              </VueMultiselect>
            </div>
          </div>
          <div class="audience-item">
            <div class="item-content d-flex align-items-baseline">
              <div>
                <button
                  class="btn select-button"
                  type="button"
                  v-if="isReportUploaderOpen"
                  :disabled="viewOnly"
                >
                  <img src="@/assets/remove-image.svg" />
                </button>
                <button
                  class="btn select-button"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadActivityReport"
                  :disabled="viewOnly"
                  v-else
                >
                  <img src="@/assets/add-image.svg" />
                </button>
              </div>
              <div class="d-flex flex-wrap">
                <div class="item-title align-self-center">
                  Leasing Activity Report
                </div>
                <button
                  type="button"
                  class="btn upload-button wti-btn-black"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadActivityReport"
                  v-if="!viewOnly"
                >
                  Upload Activity Report
                </button>
              </div>
            </div>
            <div class="select-box">
              <VueMultiselect
                ref="activiryReportmultiselect"
                v-model="audienceData.activityReport"
                :options="[]"
                :multiple="true"
                :searchable="false"
                :showNoOptions="false"
                :disabled="viewOnly"
                :hide-selected="true"
                label="originalname"
                track-by="originalname"
                placeholder=""
                @open="isReportUploaderOpen = true"
                @close="isReportUploaderOpen = false"
              >
                <template #tag="{ option, remove }">
                  <span class="multiselect__tag">
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      v-if="!option.priority"
                      :disabled="viewOnly"
                    >
                      <img
                        src="@/assets/round-checkbox.png"
                        class="priority-checkbox"
                      />
                    </button>
                    <button
                      type="button"
                      class="btn p-0 priority-btn"
                      @click="option.priority = !option.priority"
                      :disabled="viewOnly"
                      v-else
                    >
                      &#128293;
                    </button>
                    {{ option.originalname }}
                    <i
                      tabindex="1"
                      class="multiselect__tag-icon"
                      @click="remove(option)"
                    ></i>
                  </span>
                </template>
              </VueMultiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal id="csvPreview" customClass="modal-xl">
      <template v-slot:body>
        <div class="position-relative pre-table">
          <loading
            v-model:active="previewLoading"
            :is-full-page="false"
            loader="dots"
            :opacity="1"
          />
          <div v-if="!previewLoading">
            <div v-if="activeAudienceError">
              <p class="error-msg">{{ activeAudienceData }}</p>
            </div>
            <div v-else>
              <DatatableComponent
                :data="activeAudienceData"
                :options="{
                  select: true,
                  paging: false,
                  searching: false,
                  info: false,
                  responsive: true,
                }"
                :keys="keys"
                :columns="columns"
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="sass">
#csvPreview .pre-table
  max-height: 60vh
  min-height: 30vh
  overflow-y: auto
  .datatable
    width: 97%
    thead
      th
        white-space: nowrap
    tbody
      td
        white-space: nowrap
.custom-input
  width: 100%
  border-bottom: 1px solid #e8e8e8
.priority-btn
  font-size: 12px
  pointer-events: auto
.priority-btn:disabled
  border: none
  opacity: 1
.priority-checkbox
  width: 12px
  height: 12px
  margin-bottom: 4px
</style>

<script>
import { computed, ref } from "vue";
// Tools
import api from "@/services/BackendConnection";
import campaignData from "@/utils/campaignData.json";
// File Management
import useFileList from "@/compositions/file-list";
// external libs
import VueMultiselect from "./../../../node_modules/vue-multiselect/src/Multiselect.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Modal from "@/components/Modal.vue";
import $ from "jquery";
import DatatableComponent from "@/components/DatatableComponent.vue";
import JsonCSV from "vue-json-csv";

export default {
  name: "AudienceSection",
  props: ["modelValue", "isAdmin", "viewOnly", "propertyId", "campaignName"],
  emits: ["audiencesLoading"],
  setup(props, { emit }) {
    // File Management
    const { addLogo } = useFileList();
    const audienceData = computed({
      get: () => {
        if (props.modelValue) {
          return props.modelValue;
        } else {
          return "";
        }
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      audienceData,
      iscontactlistOpen: ref(false),
      iscompanylistOpen: ref(false),
      iscompanyOpen: ref(false),
      isBrokerTargetsOpen: ref(false),
      isTenantTargetsOpen: ref(false),
      isCompanyTargetsOpen: ref(false),
      // isLocalBrokerListOpen: ref(false),
      isLocationOpen: ref(false),
      isLoading: ref(false),
      tenantAudiences: campaignData.audiences.tenant,
      brokerAudiences: campaignData.audiences.broker,
      isReportUploaderOpen: ref(false),
      activeAudienceKey: ref(null),
      activeAudienceData: ref(null),
      activeAudienceError: ref(false),
      previewLoading: ref(true),
      newBrokerTarget: ref(""),
      newTenantTarget: ref(""),
      newCompanyTarget: ref(""),
      cities: ref([]),
    };
  },
  components: {
    VueMultiselect,
    PulseLoader,
    Modal,
    DatatableComponent,
    JsonCSV,
  },
  computed: {
    keys() {
      var theads = [];
      if (
        Array.isArray(this.activeAudienceData) &&
        this.activeAudienceData.length > 0 &&
        this.activeAudienceData[0]
      ) {
        theads = Object.keys(this.activeAudienceData[0]);
      }
      return theads;
    },
    columns() {
      var columns = [];
      if (this.keys) {
        const inputArray = this.keys;
        columns = inputArray.map((item) => ({
          data: item,
          default: "-",
          title: item
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        }));
      }
      return columns;
    },
    userId() {
      var userId = this.$store.state.user?.userId;
      var user = this.$store.state.user;
      var isAdmin = user?.isAdmin ? user.isAdmin : false;
      if (isAdmin) {
        var clientViwe = this.$store.state.user.adminClientView;
        if (clientViwe) {
          userId = this.$store.state.user.currentUserId;
        }
      }
      return userId;
    },
    brokerTargets() {
      var list = [];
      if (this.audienceData.brokerContactList) {
        this.audienceData.brokerContactList.forEach((element) => {
          if (element.targetType == 2) {
            list.push({
              "Broker Target": element.originalname,
              priority: element.priority,
            });
          }
        });
        return list;
      }
    },
  },
  methods: {
    asyncFindCompanySuggestion(data) {
      var _this = this;
      var info = {
        userId: _this.$store.state.user.userId,
        query: data,
      };
      _this.isLoading = true;
      api()
        .linkedinGetCompanySuggestion(info)
        .then((res) => {
          if (res.data) {
            _this.companies_options = res.data;
            _this.isLoading = false;
          }
        });
    },
    asyncFindExclusionCompanySuggestion(data) {
      var _this = this;
      var info = {
        userId: _this.$store.state.user.userId,
        query: data,
      };
      _this.isLoading = true;
      api()
        .linkedinGetCompanySuggestion(info)
        .then((res) => {
          if (res.data) {
            _this.exclusion_companies_options = res.data;
            _this.isLoading = false;
          }
        });
    },
    asyncFindJobTitleSuggestion(data) {
      var _this = this;
      var info = {
        userId: _this.$store.state.user.userId,
        query: data,
      };
      _this.isLoading = true;
      api()
        .linkedinGetJobTitleSuggestion(info)
        .then((res) => {
          if (res.data) {
            _this.jobTitle_options = res.data;
            _this.isLoading = false;
          }
        });
    },
    async getAudiences() {
      var _this = this;
      if (_this.$store.state.user) {
        _this.$emit("audiencesLoading", true);
        var info = {
          userId: _this.userId,
          propertyId: this.propertyId,
          isAdmin: _this.isAdmin,
        };
        var res = await api().getAudiences(info);
        if (res.data) {
          _this.audienceData.existedAudiencesList = res.data;
          _this.$emit("audiencesLoading", false);
        } else {
          console.log("error: audience load failed");
        }
      }
    },
    getIndustries() {
      var _this = this;
      api()
        .linkedinIndustries()
        .then((res) => {
          if (res.data) {
            _this.industriesList = res.data;
          }
        });
    },
    toogleList(id, open) {
      this.iscompanyOpen = false;
      this.isBrokerTargetsOpen = false;
      this.isTenantTargetsOpen = false;
      this.isCompanyTargetsOpen = false;
      this.newBrokerTarget = "";
      this.newTenantTarget = "";
      this.newCompanyTarget = "";

      switch (id) {
        case "contactList":
          if (open) {
            this.$refs.contactmultiselect.activate();
          } else {
            this.$refs.contactmultiselect.deactivate();
          }
          break;
        case "brokerContactList":
          if (open) {
            this.$refs.brokerContactmultiselect.activate();
          } else {
            this.$refs.brokerContactmultiselect.deactivate();
          }
          break;
        case "companyList":
          if (open) {
            this.$refs.companylistmultiselect.activate();
          } else {
            this.$refs.companylistmultiselect.deactivate();
          }
          break;
        case "brokerTargets":
          this.isBrokerTargetsOpen = open;
          if (open) {
            setTimeout(function () {
              $("#brokerTargets-input").focus();
            }, 100);
          }
          break;
        case "tenantTargets":
          this.isTenantTargetsOpen = open;
          if (open) {
            setTimeout(function () {
              $("#tenantTargets-input").focus();
            }, 100);
          }
          break;
        case "companyTargets":
          this.isCompanyTargetsOpen = open;
          if (open) {
            setTimeout(function () {
              $("#companyTargets-input").focus();
            }, 100);
          }
          break;
        case "location":
          if (open) {
            this.$refs.locationmultiselect.activate();
          } else {
            this.$refs.locationmultiselect.deactivate();
          }
          break;
        case "activiryReport":
          if (open) {
            this.$refs.activiryReportmultiselect.activate();
          } else {
            this.$refs.activiryReportmultiselect.deactivate();
          }
          break;
      }
    },
    asyncFind(data) {
      var _this = this;
      var info = {
        info: _this.$store.state.user.userId,
        query: data,
      };
      _this.isLoading = true;
      api()
        .getCitySuggestion(info)
        .then((res) => {
          if (res.data) {
            _this.cities = res.data;
            _this.isLoading = false;
          }
        });
    },
    audienceItemOpen(key) {
      switch (key) {
        case "EmployeeGrowthRate":
          return this.isEmployeeGrowthRateOpen;
        case "CompanyRevenue":
          return this.isCompanyRevenueOpen;
        case "CompanySize":
          return this.isCompanySizeOpen;
        case "WalkthruitProprietaryAudiences":
          return this.isWalkthruitProprietaryAudiencesOpen;
        case "LocalBrokerList":
          return this.isLocalBrokerListOpen;
        case "BrokerTitle":
          return this.isBrokerTitleOpen;
      }
    },
    setAudienceItemStatus(key, open) {
      switch (key) {
        case "EmployeeGrowthRate":
          this.isEmployeeGrowthRateOpen = open;
        case "CompanyRevenue":
          this.isCompanyRevenueOpen = open;
        case "CompanySize":
          this.isCompanySizeOpen = open;
        case "WalkthruitProprietaryAudiences":
          this.isWalkthruitProprietaryAudiencesOpen = open;
        case "LocalBrokerList":
          this.isLocalBrokerListOpen = open;
        case "BrokerTitle":
          this.isBrokerTitleOpen = open;
      }
    },
    async getFilePreviewData() {
      var _this = this;
      var info = {
        fileKey: _this.activeAudienceKey,
      };
      var res = await api().getFilePreview(info);
      if (res.data) {
        if (res.data.success) {
          _this.activeAudienceError = false;
        } else {
          _this.activeAudienceError = true;
        }
        _this.activeAudienceData = res.data.data;
        _this.previewLoading = false;
      }
    },
    addCompany() {
      if (this.newCompany != "") {
        this.audienceData.companies.push(this.newCompany);
      }
      this.newCompany = "";
    },
    addBrokerTarget() {
      if (this.newBrokerTarget != "") {
        this.audienceData.brokerContactList.unshift({
          targetType: 2,
          originalname: this.newBrokerTarget,
          priority: false,
        });
      }
      this.newBrokerTarget = "";
    },
    addTenantTarget() {
      if (this.newTenantTarget != "") {
        this.audienceData.contactList.unshift({
          targetType: 2,
          originalname: this.newTenantTarget,
          priority: false,
        });
      }
      this.newTenantTarget = "";
    },
    addCompanyTarget() {
      if (this.newCompanyTarget != "") {
        this.audienceData.companyList.unshift({
          targetType: 2,
          originalname: this.newCompanyTarget,
          priority: false,
        });
      }
      this.newCompanyTarget = "";
    },
  },
  mounted() {
    var _this = this;
    this.getAudiences();
    // this.getIndustries();
    var myModal = document.getElementById("uploadActivityReport");
    if (myModal) {
      myModal.addEventListener("shown.bs.modal", function () {
        _this.isReportUploaderOpen = true;
      });
      myModal.addEventListener("hidden.bs.modal", function () {
        _this.isReportUploaderOpen = false;
      });
    }
    var myCSVPreviewModal = document.getElementById("csvPreview");
    if (myCSVPreviewModal) {
      myCSVPreviewModal.addEventListener("hidden.bs.modal", function () {
        _this.activeAudienceKey = null;
        _this.activeAudienceData = null;
        _this.previewLoading = true;
        _this.activeAudienceError = false;
      });
    }
    $(document)
      .off()
      .on("click", ".csv_tag", function (event) {
        _this.activeAudienceKey = event.currentTarget.id;
        import("bootstrap").then(async (bootstrap) => {
          new bootstrap.Modal(document.getElementById("csvPreview")).show();
          await _this.getFilePreviewData();
        });
      });
  },
};
</script>