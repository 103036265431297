<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container">
      <PropertyDashboardContentHeading
        :propertyId="propertyId"
        :showUploadContentButton="true"
      />
      <div class="container content-prev">
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col" v-for="(file, index) in contents" :key="index">
            <div class="content-item">
              <div class="file-image" v-if="file.type.includes('image')">
                <img :src="file.url" />
              </div>
              <div class="file-video" v-else-if="file.type.includes('video')">
                <video :src="file.url + '#t=0.01'" controls></video>
              </div>
              <div class="file-other" v-else>
                <a :href="file.url" target="_blank">
                  <img
                    class="position-absolute top-50 start-50 translate-middle"
                    src="@/assets/file.png"
                  />
                </a>
              </div>
              <div class="file-name">
                {{ file.name.replace(/\.[^/.]+$/, "") }}
              </div>
              <a
                class="file-download wti-btn-black"
                :download="file.name"
                target="_blank"
                :href="file.url"
                >Download</a
              >
            </div>
          </div>
        </div>
      </div>
      <Modal id="addContent">
        <template v-slot:title> Upload File</template>
        <template v-slot:body>
          <div class="container">
            <div class="row content-prev" v-if="newContents">
              <div v-for="file of newContents" :key="file.id" class="col-12">
                <img
                  :src="file.url"
                  v-if="file.type.includes('image')"
                  class="rounded"
                />
                <video
                  :src="file.url"
                  controls
                  v-else-if="file.type.includes('video')"
                  class="rounded"
                ></video>
                <p v-else>{{ file.file.name }}</p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  v-if="uploading === true"
                  class="btn btn-dark wti-btn-black"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Uploading...
                </button>
                <button
                  v-else
                  class="btn btn-dark wti-btn-black"
                  @click="uploadContent"
                >
                  Save
                </button>
              </div>
            </div>
            <div
              class="file_input d-flex justify-content-center input-group"
              v-else
            >
              <input
                type="file"
                id="add_content"
                accept="*"
                class="d-none"
                @change="onFileChange"
              />
              <label class="btn btn-primary wti-btn-black" for="add_content">
                Select from your Device
              </label>
            </div>
            <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.inner-dashboard
  display: flex
  align-items: flex-start
  flex: 1
.inner-dashboard-content
  flex: 1
  height: 100%
  .inner-dashboard-menu
    +mobile
      padding: 17px 30px
    .menu-item
      max-width: 300px
      width: 100%
      border-radius: 12px
      background-image: url('@/assets/bg.png')
      background-repeat: no-repeat
      background-size: cover
      background-position: center center
      text-align: center
      padding: 30px 54px
      margin: 1em
      +mobile
        padding: 25px 45px
        margin: 1em 0
      a
        background-color: transparent
        border: none
        width: 100%
        .menu-item-name
          margin-bottom: 46px
          border-radius: 12px
          background-color: $Main
          color: white
          font-size: 20px
          line-height: 25px
          font-weight: 600
          padding: 25px 0
          width: 100%
          @media (max-width: 1076px) and (min-width: 990px)
            height: 100px
          +mobile
            font-size: 16px
            line-height: 20px
            padding: 20px 0
            margin-bottom: 26px
        .view
          font-weight: 600
          font-size: 16px
          line-height: 20px
          color: $Main
          padding: 11px 45px
          border: 1px solid $Main
          border-radius: 12px
          display: inline-block
          background-color: white
          +mobile
            font-size: 12px
            line-height: 16px
            padding: 10px
            border-radius: 0.375rem
        &:hover
          .menu-item-name
            background-color: $Blue
.content-prev
  video, img
    width: 100%

  .content-item
    width: 100%
    text-align: center
    margin-right: 2em
    margin-bottom: 2em

    .file-image, .file-video, .file-other
      width: 100%
      height: 230px
      border-radius: 28px
      background-color: $LighterGrey
      overflow: hidden
      position: relative

      &.file-video
        background-color: black

      img, video
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        object-fit: contain
    .file-other img
      width: 80px
    .file-name
      text-align: center
      margin: 1em auto
      overflow: hidden
      white-space: pre-wrap

    .file-download
      text-align: center
      display: inline-block
      margin: 0 auto
      font-weight: 600
      font-size: 16px
      line-height: 20px
      padding: 11px 18px
      background-color: $Main
      color: white
      border-radius: 12px
      text-decoration: none
      +mobile
        padding: 10px
        font-size: 12px
        border-radius: 0.5rem

      &:hover
        background-color: $Blue

#addContent
  .modal-dialog
    padding-top: 15%

    .modal-body
      min-height: 200px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      text-align: center

      .btn-dark
        padding: 16px 27px
        font-size: 16px
        line-height: 20px
        color: white
        border-radius: 12px
        margin-top: 1em

      label
        border-radius: 12px
        font-size: 16px
        line-height: 20px
        color: white
        background-color: $Main
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import Modal from "@/components/Modal.vue";
// File Management
import useFileList from "@/compositions/file-list";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import messages from "@/utils/messages.json";

export default {
  name: "PropertyContents",
  setup(props) {
    const { addFiles } = useFileList();
    return {
      contents: ref(null),
      newContents: ref(null),
      uploadErr: ref(false),
      uploading: ref(false),
      uploadErrMsg: messages.uploadErr,
    };
  },
  components: {
    Modal,
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
  },
  props: ["propertyId"],
  computed: {},
  methods: {
    onFileChange(e) {
      this.uploadErr = false;
      var files = useFileList().addFiles(e.target.files, []);
      if (!files.length) return;
      if (files.length > 0) {
        if (files[0].file.size > 0) {
          this.newContents = files;
        } else {
          this.uploadErr = true;
        }
      }
    },
    uploadContent(e) {
      e.preventDefault();
      const _this = this;
      _this.uploading = true;
      const formData = new FormData();
      formData.append("propertyId", this.propertyId);
      if (this.newContents) {
        this.newContents.forEach((file) => {
          if (file.file) {
            formData.append("contents", file.file);
          }
        });
      }
      api()
        .uploadContents(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.uploading = false;
            this.rerender("addContentclose");
          }
        });
    },
    getContents() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .getContents(info)
        .then((res) => {
          if (res.data) {
            _this.contents = res.data;
          }
        });
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
  },
  mounted() {
    this.getContents();
    var _this = this;
    var myModal = document.getElementById("addContent");
    if (myModal) {
      myModal.addEventListener("hidden.bs.modal", function () {
        _this.newContents = null;
      });
    }
  },
};
</script>
