<template>
  <div class="container">
    <div class="my-4 d-flex justify-content-end align-items-center">
      <div class="me-3 is-client-box">
        <select class="form-select mb-0" v-model="showClient" id="isClientList">
          <option v-for="(item, key) in isClientList" :key="key" :value="item">
            {{ key }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center">
        <label for="admin-client-search">search: </label>
        <input
          class="form-control client-search"
          type="search"
          v-model="searchText"
          id="admin-client-search"
        />
      </div>
    </div>
    <div class="position-relative admin-info-container">
      <loading
        v-model:active="isLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div class="accordion mb-4" id="listClients" v-if="!isLoading">
        <div
          class="accordion-item"
          v-for="(client, name) in filteredData"
          :key="name"
        >
          <h2
            class="accordion-header"
            :id="'heading' + name.replace(/[^A-Z0-9]/gi, '')"
          >
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + name.replace(/[^A-Z0-9]/gi, '')"
              :aria-expanded="false"
              :aria-controls="'collapse' + name.replace(/[^A-Z0-9]/gi, '')"
            >
              <div class="d-flex w-100 align-items-center">
                <div class="flex-grow-1">
                  <p class="mb-0 client-info">
                    <b>{{ name }}</b> - {{ client.email }}
                  </p>
                </div>
                <div class="me-4">
                  <button
                    class="btn btn-info me-2 color-white"
                    @click.stop="loginAsClient(client.id)"
                  >
                    <font-awesome-icon :icon="['fas', 'user']" />
                  </button>
                  <button
                    class="btn btn-warning me-2 color-white"
                    data-bs-toggle="modal"
                    data-bs-target="#resednInvitation"
                    @click.stop="setUser(client)"
                  >
                    <font-awesome-icon :icon="['fas', 'envelope']" />
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#editUser"
                    @click.stop="setUser(client)"
                  >
                    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                  </button>
                  <button
                    type="button"
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#addProperty"
                    @click.stop="setUserId(client.id)"
                  >
                    <font-awesome-icon :icon="['fas', 'plus-square']" />
                  </button>
                </div>
              </div>
            </button>
          </h2>
          <div
            :id="'collapse' + name.replace(/[^A-Z0-9]/gi, '')"
            class="accordion-collapse collapse"
            :aria-labelledby="'heading' + name.replace(/[^A-Z0-9]/gi, '')"
            data-bs-parent="#listClients"
          >
            <div class="accordion-body">
              <DatatableComponent
                :data="client.properties"
                :options="{
                  select: true,
                  paging: false,
                  searching: false,
                  info: false,
                  responsive: true,
                }"
                :keys="keys"
                :columns="columns"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <createProperty :userId="userId" />
  <Modal id="editProperty">
    <template v-slot:title> Edit Property </template>
    <template v-slot:body>
      <form @submit.prevent="editProperty">
        <Input v-model="property.name" type="text" label="Property Name" />
        <div class="form-group mb-3">
          <label class="form-label">Property Logo</label>
          <div v-if="property.img && property.img.url">
            <img
              :src="
                property.img && property.img.url
                  ? property.img.url
                  : property.img
              "
              alt=""
              style="width: 60px"
            />
          </div>
          <input v-on:change="onLogoChange" type="file" class="form-control" />
        </div>
        <Input v-model="property.location" type="text" label="Property City" />
        <Input v-model="property.size" type="text" label="Property Size" />
        <Input v-model="property.asset" type="text" label="Property Asset" />
        <Input
          v-model="property.walkthru"
          type="text"
          label="3D Walkthru project names(separated by comma)"
        />
        <Input v-model="property.sitUrl" type="text" label="Site URL" />
        <div class="form-group mb-3">
          <label for="isClientSelectEdit" class="form-label"
            >Property Internal/Client</label
          >
          <select
            class="form-select mb-0"
            v-model="property.isClient"
            id="isClientSelectEdit"
          >
            <option
              v-for="(item, key) in isClientSelectList"
              :key="key"
              :value="item"
            >
              {{ key }}
            </option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Select From Exist Wistia medias</label>
          <VueMultiselect
            ref="contactmultiselect"
            v-model="property.wistias"
            :options="wistiasList"
            :multiple="true"
            :searchable="true"
            :showNoOptions="false"
            :hide-selected="true"
            label="name"
            track-by="name"
            placeholder=""
            :loading="wistiaLoading"
          >
          </VueMultiselect>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Upload New Wistia Video</label>
          <input
            v-on:change="onWistiaChange"
            class="form-control"
            type="file"
            accept="video/*,video/mp4,video/x-m4v"
          />
          <p class="error-msg" v-if="wistiaUploadFailed">{{ errorMsg }}</p>
          <p class="error-msg">{{ updateErrorMsg }}</p>
        </div>
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="uploading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ uploading ? "" : "Update" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="editUser">
    <template v-slot:title> Edit User </template>
    <template v-slot:body>
      <form @submit.prevent="editUser">
        <Input v-model="user.userName" type="text" label="User Name" />
        <Input v-model="user.email" type="text" label="User Email" />
        <div class="form-group mb-3">
          <label for="isuserClientSelectEdit" class="form-label"
            >Internal/Client</label
          >
          <select
            class="form-select mb-0"
            v-model="user.isClient"
            id="isuserClientSelectEdit"
          >
            <option
              v-for="(item, key) in isClientSelectList"
              :key="key"
              :value="item"
            >
              {{ key }}
            </option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="uploading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ uploading ? "" : "Update" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="resednInvitation">
    <template v-slot:title>
      Are you sure to resend the Invitation email to: {{ user.userName }}
    </template>
    <template v-slot:body>
      <div v-if="invitationInfo">{{ invitationInfo }}</div>
      <button
        type="submit"
        class="btn btn-primary wti-btn-black"
        v-else
        @click="resendInvitation"
      >
        <span
          v-if="sending"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ sending ? "" : "Send!" }}
      </button>
    </template>
  </Modal>
</template>

<script>
import createProperty from "@/components/CreateProperty.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";
import VueMultiselect from "./../../../node_modules/vue-multiselect/src/Multiselect.vue";
import axios from "axios";
import DatatableComponent from "@/components/DatatableComponent.vue";
import { useRouter } from "vue-router";
import $ from "jquery";

// File Management
import useFileList from "@/compositions/file-list";

export default {
  name: "ListClient",
  setup(props) {
    // File Management
    const { addLogo } = useFileList();
    return {
      data: ref(null),
      user: ref({ userName: null, email: null, id: null, isClient: null }),
      userId: ref(null),
      property: ref({
        _id: null,
        name: null,
        img: null,
        location: null,
        size: null,
        asset: null,
        walkthru: null,
        wistias: null,
        isClient: null,
        sitUrl: null,
      }),
      wistiasList: ref([]),
      wistiaLoading: ref(true),
      uploading: ref(false),
      sending: ref(false),
      invitationInfo: ref(false),
      wistiaFile: ref(null),
      wistiaUploadFailed: ref(false),
      errorMsg: ref("Wistia upload failed, please contact the dev team!"),
      updateErrorMsg: ref(null),
      keys: [
        "Property Name",
        "Name-Hidden",
        "Logo",
        "City",
        "3dwt link",
        "Wistia videos",
        "Action",
      ],
      searchText: ref(""),
      filteredData: ref(null),
      isClientSelectList: ref({
        Client: true,
        Internal: false,
      }),
      isClientList: ref({
        All: 1,
        Client: 2,
        Internal: 3,
      }),
      showClient: ref(2),
      isLoading: ref(true),
    };
  },
  data() {
    var self = this;
    const router = useRouter();

    function redirectLink(propertyId) {
      router.push({
        name: "campaigns",
        params: {
          propertyId: propertyId,
        },
      });
    }
    return {
      columns: [
        {
          data: "name",
          className: "text-break align-middle",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          render: function (data, type, row, meta) {
            if (type === "display") {
              $(document).on("click", ".campaign-" + row._id, function () {
                redirectLink(row._id);
              });
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-' +
                row._id +
                '">' +
                data +
                " </a>"
              );
            }
          },
        },
        {
          data: "name",
          visible: false,
          searchable: false,
          targets: [1],
        },
        {
          data: "img",
          orderable: false,
          className: "text-break align-middle",
          defaultContent: "",
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              return '<img src="' + data + '" alt="" style="width: 50px" />';
            }
          },
        },
        {
          data: "location",
          className: "text-break align-middle",
          orderable: false,
        },
        {
          data: "walkthru",
          className: "text-break align-middle",
          defaultContent: "",
          orderable: false,
        },
        {
          data: "wistias",
          className: "text-break align-middle",
          orderable: false,
          defaultContent: "",
          render: function (data, type, row, meta) {
            if (type === "display") {
              if (data.length > 0) {
                var element = "<ul>";
                data.forEach((item) => {
                  element += "<li>" + item.name + "</li>";
                });
                return element + +"</ul>";
              }
            }
          },
        },
        {
          data: null,
          defaultContent: "-",
          orderable: false,
          className: "text-end",
          render: function (data, type, row) {
            if (type === "display") {
              $(document).on("click", ".properyAction-" + row._id, function () {
                self.updateProperty(row);
              });
            }
            var html =
              ' <button type="button" data-bs-toggle="modal" data-bs-target="#editProperty" class="btn btn-primary properyAction-' +
              row._id +
              '" >' +
              '<img src="' +
              self.getImgUrl() +
              '" style="height:12px"/>' +
              "</button>";
            return html;
          },
        },
      ],
    };
  },
  components: {
    createProperty,
    Input,
    Select,
    Modal,
    VueMultiselect,
    DatatableComponent,
  },
  methods: {
    getClientList() {
      var _this = this;
      _this.isLoading = true;
      let user = this.$store.state.user;
      api()
        .listClients()
        .then((res) => {
          if (res.data) {
            _this.data = res.data;
            if (user.isTestAdmin) {
              this.showClient = 1;
            }
            _this.filteredData = Object.fromEntries(
              Object.entries(res.data)
                .filter(([key, value]) => value.isClient)
                .sort(function (a, b) {
                  return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
                })
            );
            _this.isLoading = false;
          }
        });
    },
    setUserId(id) {
      this.userId = id;
    },
    setUser(user) {
      this.user = user;
    },
    updateProperty(item, id) {
      var property = JSON.parse(JSON.stringify(item));
      this.property = property;
      this.userId = property.clientId;
    },
    async editProperty(e) {
      e.preventDefault();
      this.uploading = true;
      var _this = this;
      if (this.wistiaFile?.file) {
        const wistiaFormData = new FormData();
        var fileName = this.wistiaFile.id;
        wistiaFormData.append("file", this.wistiaFile.file);
        wistiaFormData.append(
          "project_id",
          import.meta.env.VITE_WISTIA_PROJECT_ID
        );
        wistiaFormData.append(
          "name",
          fileName.split(".")[0] +
            "-" +
            Date.now() +
            "." +
            fileName.split(".")[1]
        );
        var res = await axios.post(
          "https://upload.wistia.com",
          wistiaFormData,
          {
            headers: {
              Authorization: "Bearer " + import.meta.env.VITE_WISTIA_TOKEN,
            },
          }
        );
        if (res.status == "200") {
          this.property.wistias.push({
            mediaId: res.data.id,
            name: res.data.name,
          });
          this.wistiaUploadFailed = false;
        } else {
          this.wistiaUploadFailed = true;
        }
      }
      var info = this.property;
      info.img = info.img?.file ? info.img.file : info.img;
      const formData = new FormData();
      Object.keys(info).forEach((key) => {
        if (Array.isArray(info[key])) {
          if (info[key].length > 0) {
            info[key].forEach((item) => {
              if (typeof item == "object") {
                formData.append(key + "[]", JSON.stringify(item));
              }
            });
          } else {
            formData.append(key, info[key]);
          }
        } else {
          formData.append(key, info[key]);
        }
      });
      api()
        .updateProperty(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.rerender("editPropertyclose");
            _this.uploading = false;
          } else {
            _this.uploading = false;
            _this.updateErrorMsg = res.data.data;
          }
        });
    },
    editUser(e) {
      e.preventDefault();
      this.uploading = true;
      var _this = this;
      var info = this.user;
      api()
        .editUser(info)
        .then((res) => {
          if (res.data) {
            this.rerender("editUserclose");
            _this.isLoading = false;
          }
        });
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    onLogoChange(e) {
      var file = e.target.files[0];
      this.uploadErr = false;
      if (file.size > 0) {
        this.property.img = useFileList().addLogo(file);
      } else {
        if (typeof window !== undefined) {
          import("bootstrap").then((bootstrap) => {
            new bootstrap.Modal(document.getElementById("uploadError")).show();
          });
        }
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    onWistiaChange(e) {
      this.wistiaFile = useFileList().addLogo(e.target.files[0]);
    },
    clear() {
      this.property["_id"] = null;
      this.property.name = null;
      this.property.img = null;
      this.property.location = null;
      this.property.size = null;
      this.property.asset = null;
      this.property.walkthru = null;
      this.property.wistias = [];
      this.property.isClient = null;
      this.property.sitUrl = null;
      this.wistiaFile = null;
      this.userId = null;
      this.invitationInfo = null;
      this.user = { userName: null, email: null, id: null, isClient: null };
    },
    getWistiasList() {
      var _this = this;
      api()
        .listAllWistiaVideos()
        .then((res) => {
          if (res.data) {
            _this.wistiasList = res.data;
            _this.wistiaLoading = false;
          }
        });
    },
    getImgUrl() {
      return new URL(`../assets/edit-icon.svg`, import.meta.url).href;
    },
    filterData(text, showClient) {
      var data = JSON.parse(JSON.stringify(this.data));
      var result;
      if (text) {
        result = Object.fromEntries(
          Object.entries(data)
            .filter(
              ([key, value]) =>
                key.toLowerCase().includes(text.toLowerCase()) ||
                value.email.toLowerCase().includes(text.toLowerCase())
            )
            .sort(function (a, b) {
              return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
            })
        );
      } else {
        result = Object.fromEntries(
          Object.entries(data).sort(function (a, b) {
            return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
          })
        );
      }
      switch (showClient) {
        case 1:
          this.filteredData = Object.fromEntries(
            Object.entries(result).sort(function (a, b) {
              return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
            })
          );
          break;
        case 2:
          this.filteredData = Object.fromEntries(
            Object.entries(result)
              .filter(([key, value]) => value.isClient)
              .sort(function (a, b) {
                return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
              })
          );
          break;
        case 3:
          this.filteredData = Object.fromEntries(
            Object.entries(result)
              .filter(([key, value]) => !value.isClient)
              .sort(function (a, b) {
                return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
              })
          );
          break;
      }
    },
    loginAsClient(cliendId) {
      this.$router.push({
        name: "clientView",
        params: {
          clientID: cliendId,
        },
      });
    },
    resendInvitation() {
      var _this = this;
      this.sending = true;
      var info = {
        cliendId: this.user.id,
      };
      api()
        .resendInvitation(info)
        .then((res) => {
          if (res.data) {
            _this.sending = false;
            _this.invitationInfo = res.data.data;
          }
        });
    },
  },
  mounted() {
    this.getClientList();
    this.getWistiasList();
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
  watch: {
    searchText(newVal, oldVal) {
      this.filterData(newVal, this.showClient);
    },
    showClient(val) {
      this.filterData(this.searchText, val);
    },
  },
};
</script>
