<template>
  <div v-if="isUserLoggedIn">
    <listProperties v-if="!isAdmin" />
    <div v-else><listClients /></div>
  </div>
</template>

<style lang="scss">
</style>

<script>
// import { ref } from "vue";
import listProperties from "@/components/ListProperty.vue";
import listClients from "@/components/ListClient.vue";
import chatButton from "@/components/ChatButton.vue";

export default {
  name: "HomeView",
  setup() {
    return {};
  },
  components: { listProperties, listClients, chatButton },
  computed: {
    userId() {
      return this.$store.state.user ? this.$store.state.user.userId : null;
    },
    isAdmin() {
      return this.$store.state.user
        ? this.$store.state.user?.isAdmin
          ? this.$store.state.user.isAdmin
          : false
        : false;
    },
    isUserLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
  },
  methods: {},
  mounted() {},
};
</script>
