import { createStore } from 'vuex';
import VuexPersister from 'vuex-persister'
import Cookies from 'js-cookie'

const initial = {
    user: null,
    isUserLoggedIn: false,
    componentKey: 1,
    allowLeave: false,
}
const vuexPersister = new VuexPersister({
    key: "vuex",
    overwrite: true,
    storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { secure: true }),
        removeItem: (key) => Cookies.remove(key),
        length: Object.keys(Cookies).length,
        clear: () => Cookies.remove(),
        key: (key) => null
    },
})

const store = createStore({
    state() {
        return initial
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user?.isAdmin && user.isAdmin) {
                state.user.adminClientView = false;
                state.user.currentUserId = null;
            }
        },
        setAllowLeave(state, allowLeave) {
            state.allowLeave = allowLeave;
        },
        setComponentKey(state, key) {
            state.componentKey = key
        },
        login(state) {
            state.isUserLoggedIn = true;
            state.allowLeave = false;
            state.componentKey = 1;
            state.allowLeave = false;
        },
        logout(state) {
            state.user = null;
            state.isUserLoggedIn = false;
            state.componentKey = 1;
            state.allowLeave = false;
        },
        setAdminClientView(state, adminClientView) {
            state.user.adminClientView = adminClientView;
        },
        setCurrentUserId(state, id) {
            state.user.currentUserId = id;
        }
    },
    plugins: [vuexPersister.persist]
})

export default store