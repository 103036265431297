<template>
  <button
      type="button"
      class="btn button-contact-form wti-btn"
      data-bs-toggle="modal"
      data-bs-target="#contact"
  >
    <div class="chat-icon-wrapper">
      <img src="@/assets/chat.png" alt="">
    </div>
  </button>
</template>
<style lang="sass" scoped>
@import "@/assets/vars.sass"
.button-contact-form
  position: fixed
  bottom: 1em
  right: 1em
  border-radius: 50%
  width: 66px
  height: 66px
  box-shadow: 0px 0px 20px 0px rgba($Blue,0.5)
  transition: 0.3s ease-out
  z-index: 2
  &:hover
    box-shadow: 0px 0px 0px 0px rgba($Blue,0.5)

  .chat-icon-wrapper
    display: flex
    flex-direction: column
    align-content: center
    justify-content: center
    align-items: center
    text-align: center
</style>
<script>
export default {
  name: "ChatButton",
  data() {
    return {};
  },
  methods: {},
};
</script>
