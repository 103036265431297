<template>
  <Modal id="addAudience">
    <template v-slot:title> New Audience</template>
    <template v-slot:body>
      <form @submit.prevent="addAudience">
        <div class="type-radios row">
          <div class="type__radio col">
            <input
              style="visibility: hidden"
              id="contact_list"
              class="type__input"
              type="radio"
              name="type"
              value="1"
              v-model="type"
            />
            <label for="contact_list" class="type__label">
              <div class="view-icon">
                <img src="@/assets/contact.png" />
              </div>
              <div class="label-text">Tenant Contact Targets CSV</div>
            </label>
          </div>
          <div class="type__radio col">
            <input
              style="visibility: hidden"
              id="company_list"
              class="type__input"
              type="radio"
              name="type"
              value="2"
              v-model="type"
            />
            <label for="company_list" class="type__label">
              <div class="view-icon">
                <img src="@/assets/office.png" />
              </div>
              <div class="label-text">Tenant Company Targets CSV</div>
            </label>
          </div>
          <div class="type__radio col">
            <input
              style="visibility: hidden"
              id="broker_contact_list"
              class="type__input"
              type="radio"
              name="type"
              value="3"
              v-model="type"
            />
            <label for="broker_contact_list" class="type__label">
              <div class="view-icon">
                <img src="@/assets/contact.png" />
              </div>
              <div class="label-text">Broker Targets CSV</div>
            </label>
          </div>
        </div>
        <Input
          v-model="fileName"
          type="text"
          label="List Name"
          v-if="!audience.length > 0"
        />
        <p v-else class="upload-audience-name">
          {{ fileName }}
        </p>
        <input
          type="file"
          id="fileList"
          class="form-control hide-file-input"
          accept=".csv,.xlsx,.xls"
          @change="onInputChange($event)"
        />
        <div class="action-wrapper" v-if="!audience.length > 0">
          <label for="fileList" class="btn upload-button wti-btn-black">
            Select From Your Device
          </label>
        </div>
        <div v-else>
          <div class="file-previews">
            <div
              v-for="file of audience"
              :key="file.originalname"
              class="file-preview"
            >
              <img class="file-thumbnail" :src="getImgUrl(file)" />
              <span @click="removeCSVFile()" class="close-icon"> &times; </span>
            </div>
          </div>
          <button type="submit" class="btn btn-primary wti-btn-black">
            <span
              v-if="submitting"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ submitting ? "" : "Submit" }}
          </button>
        </div>
        <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
      </form>
    </template>
  </Modal>
</template>
<style lang="sass">
@import "@/assets/vars.sass"
.modal-content
  padding: 12px

.modal-body
  .type-radios
    margin-bottom: 2em
    .type__radio
      width: 100%
      padding: 7px
      label
        background: #FFFFFF
        border: 1px solid $LightGreyBorder
        border-radius: 8px
        width: 100%
        .view-icon
          display: inline-block
          width: 25px
          height: 25px
          background-color: $Blue
          border-radius: 50%
          position: relative
          margin: 12px 0 10px 13px
          img
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            height: 12.5px
            width: auto
        .label-text
          font-weight: 600
          font-size: 11px
          line-height: 18px
          color: $Main
          margin: 0 0 10px 17px

      &:last-child
        margin-right: 0
      input
        &:checked ~ label
          border: 3px solid $Blue
          background: $LighterGrey
          .view-icon
            margin: 9px 0 10px 13px
          .label-text
            margin: 0 0 10px 14px

  .instruction
    text-align: center
    max-width: 320px
    margin: 0 auto
    padding: 30px 0
    font-size: 18px
    line-height: 30px

  .form-label
    font-weight: 600
    font-size: 14px
    line-height: 18px
    color: $Main

  .form-control
    font-size: 14px
    line-height: 18px
    padding: 16px 20px
    margin-bottom: 1em
    border: 1px solid $LightGreyBorder
    border-radius: 12px

    &::placeholder
      color: $LightGrey !important

  .btn
    border-radius: 12px
    background-color: $Main
    font-size: 16px
    line-height: 20px
    padding: 16px 44px
    float: right
</style>
<script>
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";
// File Management
import useFileList from "@/compositions/file-list";
import Input from "@/components/Input.vue";
import * as XLSX from "xlsx";
import messages from "@/utils/messages.json";

export default {
  name: "AddAudienceForm",
  setup(props) {
    const { addFiles } = useFileList();
    return {
      audience: ref([]),
      fileName: ref(null),
      type: ref(1),
      submitting: ref(false),
      uploadErr: ref(false),
      uploadErrMsg: messages.uploadErr,
    };
  },
  props: ["userId"],
  components: {
    Input,
    Modal,
  },
  methods: {
    async onInputChange(e) {
      this.uploadErr = false;
      var files = useFileList().addFiles(
        e.target.files || e.dataTransfer.files,
        []
      );
      var fileName;
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (file.file.size > 0) {
          const fileExtension = file.file.name.split(".").pop().toLowerCase();
          var allowedExtensions = ["csv", "xlsx", "xls"];
          if (allowedExtensions.includes(fileExtension)) {
            if (this.getFileType(file.type)) {
              var rows = await this.parseFile(file.file);
              file.rows = rows;
            }
            if (this.fileName) {
              if (this.fileName.split(".").length <= 1) {
                this.fileName = this.fileName + "." + fileExtension;
              }
            } else {
              this.fileName = file.file.name;
            }
            fileName = this.fileName;
            file.file = new File([file.file], fileName);
            file.id = fileName;
            this.audience.push(file);
          } else {
            this.uploadErr = true;
            this.uploadErrMsg = messages.invalidTypeErr;
            e.target.value = "";
          }
        } else {
          this.uploadErr = true;
        }
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    parseFile(file) {
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          var data = new Uint8Array(reader.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheet = workbook.Sheets[workbook.SheetNames[0]];
          var range = XLSX.utils.decode_range(sheet["!ref"]);
          resolve(range.e.r);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    getFileType(fileType) {
      if (fileType.includes("csv")) {
        return "csv";
      } else if (fileType.includes("spreadsheetml.sheet")) {
        return "xlsx";
      } else if (fileType.includes("ms-excel")) {
        return "xls";
      }
      return null;
    },
    addAudience(e) {
      e.preventDefault();
      this.submitting = true;
      const formData = new FormData();
      formData.append("userId", this.userId);
      formData.append("type", this.type);
      formData.append("fileName", this.fileName);
      if (this.audience) {
        this.audience.forEach((file) => {
          if (file.file) {
            var obj = {};
            obj[file.id] = file.rows;
            formData.append("audience", file.file);
            formData.append("audienceInfo", JSON.stringify(obj));
          }
        });
      }
      api()
        .addAudiences(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("addAudienceclose");
            this.$emit("updateAudience");
          }
        });
    },
    rerender(id) {
      var myModal = document.getElementById(id);
      myModal.click();
    },
    clear() {
      this.audience = [];
      this.type = 1;
      this.fileName = null;
      this.submitting = false;
    },
    getImgUrl(file) {
      var fileType = this.getFileType(file.type);
      return new URL(`../assets/${fileType}.png`, import.meta.url).href;
    },
    removeCSVFile() {
      this.audience = [];
    },
  },
  mounted() {
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
        _this.uploadErr = false;
      });
    });
  },
};
</script>
