<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container campaign-container">
      <div class="campaign-header">
        <PropertyDashboardContentHeading
          :propertyId="propertyId"
          :showUploadContentButton="false"
        />
      </div>
      <div class="campaign-content position-relative">
        <loading
          v-model:active="isLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
          class="mx-auto p-0 m-0"
        />
        <div v-if="!isLoading" class="list-table">
          <DatatableComponent
            :columns="isAdmin ? columnsAdmin : columns"
            :data="campaigns"
            :options="{
              paging: false,
              ordering: true,
              searching: false,
              info: false,
              responsive: true,
            }"
            :keys="isAdmin ? keysAdmin : keys"
          />
        </div>
      </div>
    </div>
  </div>
  <Modal id="likesModal">
    <template v-slot:title
      >Add likes data for campaign {{ activeName }}</template
    >
    <template v-slot:body>
      <form @submit.prevent="updateLikes">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Job Title</th>
              <th>Company</th>
              <th>Channel</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="activeLikes">
            <tr v-for="(item, index) in activeLikes" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.jobTitle }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.channel }}</td>
              <td>
                <button
                  class="btn btn-danger"
                  @click="deleteItem(index, $event)"
                >
                  -
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input v-model="newItem.name" type="text" placeholder="Name" />
              </td>
              <td>
                <input
                  v-model="newItem.email"
                  type="email"
                  placeholder="Email"
                />
              </td>
              <td>
                <input
                  v-model="newItem.jobTitle"
                  type="text"
                  placeholder="Job Title"
                />
              </td>
              <td>
                <input
                  v-model="newItem.company"
                  type="text"
                  placeholder="Company"
                />
              </td>
              <td>
                <input
                  v-model="newItem.channel"
                  type="text"
                  placeholder="Channel"
                />
              </td>
              <td>
                <button class="btn btn-success" @click="addItem">+</button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td>
                <input v-model="newItem.name" type="text" placeholder="Name" />
              </td>
              <td>
                <input
                  v-model="newItem.email"
                  type="email"
                  placeholder="Email"
                />
              </td>
              <td>
                <input
                  v-model="newItem.jobTitle"
                  type="text"
                  placeholder="Job Title"
                />
              </td>
              <td>
                <input
                  v-model="newItem.company"
                  type="text"
                  placeholder="Company"
                />
              </td>
              <td>
                <input
                  v-model="newItem.channel"
                  type="url"
                  placeholder="Channel"
                />
              </td>
              <td>
                <button class="btn btn-success" @click="addItem">+</button>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Submit" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
table.dataTable.table-striped
  width: 100% !important
  margin-top: 50px
  +mobile
    font-size: 10px
    margin-top: 0

  thead
    tr
      color: $LightGrey

  tbody
    tr
      color: $Main

      td
        a
          color: $Main
          text-decoration: none

        button.btn
          font-size: 14px
          padding: 0.2rem 0.5rem
          margin: 0 0.2rem
          +mobile
            font-size: 10px
            padding: 0.2rem
        button.btn:disabled
          border: none
          background-color: rgba(0, 0, 0, 0.08)

    tr.odd > *
      box-shadow: none
      color: $Main

    tr:hover > *
      box-shadow: inset 0 0 0 9999px rgb(0 0 0 / 8%)

.btn.btn-blue
  color: $ButtonBlueText
  background-color: $ButtonBlue

.btn.btn-yellow
  color: $ButtonYellowText
  background-color: $ButtonYellow

.btn.btn-purple
  color: $ButtonPurpleText !important
  background-color: $ButtonPurple !important

.btn.btn-green
  color: $ButtonGreenText
  background-color: $ButtonGreen

.btn.btn-green:hover
  color: $ButtonGreenText
  background-color: $ButtonGreenDark

.btn.btn-green-dark
  color: $ButtonGreenDarkText
  background-color: $ButtonGreenDark

.btn.btn-red
  color: $ButtonRedText
  background-color: $ButtonRed

.btn.btn-red:hover
  color: $ButtonRedText
  background-color: $ButtonRedDark

.btn.btn-grey
  color: $ButtonGreyText
  background-color: $ButtonGrey

#likesModal
  .modal-dialog
    width: 80%
    max-width: 1100px
  .modal-body
    overflow: auto
  .table
    width: 1000px
    th
      white-space: nowrap
    td
      .btn-success
        background-color: #198754
        padding: 0.375rem 0.5rem
        border-radius: 0.375rem
      .btn-danger
        background-color: #dc3545
        padding: 0.375rem 0.5rem
        border-radius: 0.375rem
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import DatatableComponent from "@/components/DatatableComponent.vue";
import Modal from "@/components/Modal.vue";
import campaignData from "@/utils/campaignData.json";
import $ from "jquery";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "PropertyReporting",
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    return {
      campaigns: ref(null),
      statusList: campaignData.campaign.status,
      keys: [
        "Campaign Name",
        "Status",
        "Start Date",
        "End Date",
        "Total Spend",
        "Views",
        "Clicks",
        "Views-Hidden",
        "Clicks-Hidden",
      ],
      keysAdmin: [
        "Campaign Name",
        "Status",
        "Start Date",
        "End Date",
        "Total Spend",
        "Views",
        "Clicks",
        "Views-Hidden",
        "Clicks-Hidden",
        "Action",
      ],
      isLoading: ref(true),
      activeId: ref(null),
      activeName: ref(null),
      submitting: ref(false),
      activeLikes: ref([]),
      newItem: ref({
        name: "",
        email: "",
        jobTitle: "",
        company: "",
        channel: "",
      }),
    };
  },
  data() {
    var self = this;
    return {
      columns: [
        {
          data: "campaignName",
          orderable: true,
          className: "text-break align-middle",
          defaultContent: "",
          render: function (data, type, row, meta) {
            if (type === "display") {
              $(document).on(
                "click",
                ".campaign-report-" + row.campaignId,
                function () {
                  self.redirectLink(row.campaignId);
                }
              );
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-report-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            } else {
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-link-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            }
          },
        },
        {
          data: "status",
          defaultContent: "",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              switch (data) {
                case "Pending":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-blue" >' +
                    data +
                    "</button>"
                  );
                case "Draft":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-purple" >' +
                    data +
                    "</button>"
                  );
                case "Needs Approval":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-yellow" >' +
                    data +
                    "</button>"
                  );
                case "Active":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-green" >' +
                    data +
                    "</button>"
                  );
                case "Paused":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-red" >' +
                    data +
                    "</button>"
                  );
                case "Ended":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-grey" >' +
                    data +
                    "</button>"
                  );
              }
            } else {
              return '<div class="hidden-for-sort">' + data + "</div>";
            }
          },
        },
        {
          data: "startDateStr",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: "endDate",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: "cost",
          ordering: true,
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              return (
                '<div class="hidden-for-sort">' +
                data +
                "</div>" +
                "$" +
                data.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              );
            } else {
              if (data) {
                return '<div class="hidden-for-sort">' + data + "</div>";
              } else {
                return '<div class="hidden-for-sort">' + 0 + "</div>";
              }
            }
          },
        },
        {
          data: "impressions",
          orderData: [7],
          targets: [5],
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            }
          },
        },
        {
          data: "clicks",
          orderData: [8],
          targets: [6],
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            }
          },
        },
        {
          data: "impressions",
          visible: false,
          searchable: false,
          targets: [7],
        },
        {
          data: "clicks",
          visible: false,
          searchable: false,
          targets: [8],
        },
      ],
      columnsAdmin: [
        {
          data: "campaignName",
          orderable: true,
          className: "text-break align-middle",
          defaultContent: "",
          render: function (data, type, row, meta) {
            if (type === "display") {
              $(document).on(
                "click",
                ".campaign-report-" + row.campaignId,
                function () {
                  self.redirectLink(row.campaignId);
                }
              );
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-report-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            } else {
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-link-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            }
          },
        },
        {
          data: "status",
          defaultContent: "",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              switch (data) {
                case "Pending":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-blue" >' +
                    data +
                    "</button>"
                  );
                case "Draft":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-purple" >' +
                    data +
                    "</button>"
                  );
                case "Needs Approval":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-yellow" >' +
                    data +
                    "</button>"
                  );
                case "Active":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-green" >' +
                    data +
                    "</button>"
                  );
                case "Paused":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-red" >' +
                    data +
                    "</button>"
                  );
                case "Ended":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-grey" >' +
                    data +
                    "</button>"
                  );
              }
            } else {
              return '<div class="hidden-for-sort">' + data + "</div>";
            }
          },
        },
        {
          data: "startDateStr",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: "endDate",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: "cost",
          ordering: true,
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              return (
                '<div class="hidden-for-sort">' +
                data +
                "</div>" +
                "$" +
                data.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              );
            } else {
              if (data) {
                return '<div class="hidden-for-sort">' + data + "</div>";
              } else {
                return '<div class="hidden-for-sort">' + 0 + "</div>";
              }
            }
          },
        },
        {
          data: "impressions",
          orderData: [7],
          targets: [5],
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            }
          },
        },
        {
          data: "clicks",
          orderData: [8],
          targets: [6],
          defaultContent: "-",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            }
          },
        },
        {
          data: "impressions",
          visible: false,
          searchable: false,
          targets: [7],
        },
        {
          data: "clicks",
          visible: false,
          searchable: false,
          targets: [8],
        },
        {
          data: null,
          defaultContent: "-",
          orderable: false,
          className: "text-end",
          render: function (data, type, row) {
            if (type === "display") {
              $(document).on(
                "click",
                ".likesModal-" + row.campaignId,
                function () {
                  self.updateActiveate(row.campaignId, row.campaignName);
                }
              );
            }
            var html =
              '<button class="btn btn-success likesModal-' +
              row.campaignId +
              '" id="' +
              row.campaignId +
              '_add_likes" data-bs-toggle="modal" data-bs-target="#likesModal">Add Likes data</button>';
            return html;
          },
        },
      ],
    };
  },
  props: ["propertyId"],
  components: {
    Modal,
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    DatatableComponent,
  },
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
  },
  methods: {
    updateActiveate(val1, val2) {
      this.activeId = val1;
      this.activeName = val2;
    },
    getCampaigns() {
      var _this = this;
      var info = {
        userId: _this.$store.state.user ? _this.$store.state.user.userId : null,
        propertyId: _this.propertyId,
      };
      api()
        .listCampaignsForReport(info)
        .then((res) => {
          if (res.data) {
            _this.campaigns = res.data;
            setInterval(() => {
              _this.isLoading = false;
            }, 1500);
          }
        });
    },
    getActiveLikes() {
      var _this = this;
      var info = {
        campaignId: _this.activeId,
      };
      api()
        .getCampaignLikes(info)
        .then((res) => {
          if (res.data) {
            _this.activeLikes = res.data;
          }
        });
    },
    deleteItem(index, e) {
      e.preventDefault();
      this.activeLikes.splice(index, 1);
    },
    addItem(e) {
      e.preventDefault();
      if (this.newItem.name) {
        this.activeLikes.push({
          name: this.newItem.name,
          email: this.newItem.email,
          jobTitle: this.newItem.jobTitle,
          company: this.newItem.company,
          channel: this.newItem.channel,
        });
        this.newItem.name = "";
        this.newItem.email = "";
        this.newItem.jobTitle = "";
        this.newItem.company = "";
        this.newItem.channel = "";
      } else {
        alert("Please enter at least a name.");
      }
    },
    updateLikes(e) {
      var _this = this;
      e.preventDefault();
      this.submitting = true;
      var info = {
        campaignId: _this.activeId,
        likesArr: JSON.stringify(_this.activeLikes),
      };
      if (this.activeLikes) {
        api()
          .updateCampaignLikes(info)
          .then((res) => {
            this.submitting = false;
            var myModal = document.getElementById("likesModalclose");
            myModal.click();
          });
      } else {
        alert("Please enter data before submitting.");
        return;
      }
    },
    redirectLink(campaignId) {
      this.$router.push({
        name: "campaign_report",
        params: {
          propertyId: this.propertyId,
          campaignId: campaignId,
        },
      });
    },
  },
  mounted() {
    this.getCampaigns();
  },
  watch: {
    activeId(newVal, oldVal) {
      if (newVal) {
        this.getActiveLikes();
      }
    },
  },
};
</script>
