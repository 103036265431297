<template>
  <div class="targets row position-relative">
    <loading
      v-model:active="isLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div class="col-md-3 col-12" v-for="(item, index) in targets" :key="index">
      <div class="target border existTargers">
        <button
          type="button"
          class="btn gear-btn"
          data-bs-toggle="modal"
          data-bs-target="#deleteTarget"
          @click="updateTarget(item)"
        >
          <font-awesome-icon icon="gear" />
        </button>
        <div class="d-flex flex-column">
          <div class="target-logo-container">
            <div class="target-logo mx-auto">
              <img
                :src="imageBase + item.logo[0].key"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <div class="target-content-container">
            <div class="text-center logo-name">
              {{ item.name }}
            </div>
            <div class="row target-info">
              <div class="col p-0">
                <p class="view-title">Total Views</p>
                <p class="view-value">
                  {{ item.total }}
                </p>
              </div>
              <div class="col">
                <p class="view-title">Views Last 3 Days</p>
                <p class="view-value">
                  {{ item.last3Days }}
                </p>
              </div>
            </div>
            <div class="input-group url">
              <input
                class="form-control form-input col-9"
                type="text"
                :value="item.shortenedUrl"
                :aria-describedby="'group-' + index"
              />
              <span
                class="input-group-text popper-container"
                :id="'group-' + index"
              >
                <Popper content="Copied!" class="dark">
                  <button
                    type="button"
                    v-clipboard:copy="item.shortenedUrl"
                    class="btn btn-dark wti-btn-black"
                  >
                    Copy
                  </button>
                </Popper>
              </span>
            </div>
            <!-- <div class="url row mx-auto">
              <input
                class="form-input col-9 rounded-start"
                type="text"
                :value="item.shortenedUrl"
              />
              <div class="popper-container col-3">
                <Popper content="Copied!" class="dark">
                  <button
                    type="button"
                    v-clipboard:copy="item.shortenedUrl"
                    class="btn btn-dark wti-btn-black"
                  >
                    Copy
                  </button>
                </Popper>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div
        class="target newTarget border d-flex align-items-center justify-content-center"
      >
        <button
          class="btn align-middle"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addTarget"
        >
          <div class="fs-4 px-2 text-center">
            <div class="border rounded-circle add-logo mx-auto">
              <img src="@/assets/plus-icon.png" alt="add-logo" />
            </div>
            <p class="">Add Logo &<br />Tracking</p>
          </div>
        </button>
      </div>
    </div>
  </div>
  <Modal id="deleteTarget">
    <template v-slot:title>Would you like to delete?</template>
    <template v-slot:body>This change will be permanent.</template>
    <template v-slot:footer>
      <button type="button" class="btn btn-green" @click="deleteTarget()">
        Yes
      </button>
      <button type="button" class="btn btn-red" data-bs-dismiss="modal">
        Cancel
      </button>
    </template>
  </Modal>
  <Modal id="addTarget">
    <template v-slot:title>Add Logo & Tracking</template>
    <template v-slot:body>
      <Input v-model="target.name" type="text" label="Name" />
      <input
        type="file"
        id="logofile"
        class="form-control hide-file-input"
        accept="image/png, image/jpg"
        @change="onLogoChange"
        v-if="!target.logo"
      />
      <div class="action-wrapper" v-if="!target.logo">
        <label for="logofile" class="btn upload-button wti-btn-black">
          Select From Your Device
        </label>
      </div>
      <div v-else>
        <div class="file-previews">
          <div class="file-preview">
            <img class="file-thumbnail" :src="target.logo.url" />
            <span @click="removeFile" class="close-icon"> &times; </span>
          </div>
        </div>
        <button
          type="submit"
          data-dismiss="modal"
          class="btn btn-submit wti-btn-black"
          @click="addTarget"
        >
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Save" }}
        </button>
      </div>
      <div class="error-msg" v-if="uploadErr">{{ uploadErrMsg }}</div>
    </template>
  </Modal>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
.targets
  min-height: 280px
  margin-top: 31px
  .target
    border-radius: 20px
    height: 100%
    margin-bottom: 31px
    +mobile
      margin-bottom: 10px
  .newTarget
    .add-logo
      width: 80px
      height: 80px
      overflow: hidden
      img
        width: 38px
        margin: 20px
    p
      font-weight: 600
      font-size: 18px
      line-height: 23px
      padding-top: 27px
  .existTargers
    position: relative
    .target-logo-container
      padding: 25px 25px 0
    .gear-btn
      position: absolute
      right: 0
      z-index: 2
      padding: 12px
    .target-logo
      width: 80%
      img
        height: 71px
        width: 100%
        max-width: 191px
        object-fit: cover
        border: 1px solid $LightGreyBorder
        border-radius: 5px
    .target-content-container
      margin: 0 23px
      .logo-name
        font-weight: 400
        font-size: 16px
        line-height: 20px
        color: #000000
        padding: 26px 0 14px
        border-bottom: 1px solid $LightGreyBorder
      .target-info
        padding-top: 12px
      .view-title
        font-weight: 400
        font-size: 12px
        line-height: 15px
        text-align: center
        color: #000000
        margin-bottom: 19px
      .view-value
        font-weight: 600
        font-size: 18px
        line-height: 23px
        text-align: center
        color: $Main
        margin-bottom: 16px
      .url
        border-top: 1px solid $LightGreyBorder
        margin: 0 auto 27px
        padding-top: 25px
        .form-input
          border: 1px solid $LightGreyBorder
          padding: 12px
          font-weight: 400
          font-size: 9px !important
          line-height: 11px !important
        .popper-container
          padding: 0
          margin-left: 0 !important
          vertical-align: middle
          text-align: start
          .btn
            font-weight: 600
            font-size: 14px
            line-height: 18px
            padding: 8px
            width: 100%
          .dark
            --popper-theme-background-color: #333333
            --popper-theme-background-color-hover: #333333
            --popper-theme-text-color: white
            --popper-theme-border-width: 0px
            --popper-theme-border-radius: 6px
            --popper-theme-padding: 10px
            --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25)
            border-left: 2px solid transparent !important
.targets.row
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  flex-wrap: wrap

.targets.row > [class*='col-']
  display: flex
  flex-direction: column
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";

// File Management
import useFileList from "@/compositions/file-list";

import messages from "@/utils/messages.json";

export default {
  name: "TargetsComponent",
  emits: ["updateKey"],
  setup(props) {
    const { addLogo } = useFileList();
    return {
      imageBase:
        "https://s3.ca-central-1.amazonaws.com/walkthruit.analyticsportal.target/",
      target: ref({
        logo: null,
        name: null,
      }),

      activeTarget: ref(null),
      isLoading: ref(false),
      submitting: ref(false),
      uploadErr: ref(false),
      uploadErrMsg: messages.uploadErr,
    };
  },
  components: {
    Modal,
    Input,
  },
  props: ["project", "propertyId", "targets", "targetsLoaded"],
  computed: {
    isLoading() {
      return !this.targetsLoaded;
    },
  },
  methods: {
    onLogoChange(e) {
      var file = e.target.files[0];
      this.uploadErr = false;
      if (file.size > 0) {
        this.target.logo = useFileList().addLogo(file);
      } else {
        this.uploadErr = true;
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    addTarget(e) {
      e.preventDefault();
      var _this = this;
      _this.submitting = true;
      var info = {
        propertyId: this.propertyId,
        threeDWalkthruId: this.project._id,
        logo: this.target.logo ? this.target.logo.file : this.target.logo,
        name: this.target.name,
      };
      const formData = new FormData();
      Object.keys(info).forEach((key) => {
        formData.append(key, info[key]);
      });
      api()
        .upload3DWalkthruitTarget(formData)
        .then((res) => {
          if (res.data && res.data.success) {
            this.rerender("addTargetclose");
            _this.submitting = false;
          }
        });
    },
    deleteTarget() {
      var _this = this;
      var info = {
        propertyId: this.propertyId,
        targetId: this.activeTarget._id,
      };
      api()
        .delete3DWalkthruitTarget(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.rerender("deleteTargetclose");
          }
        });
    },
    updateTarget(item) {
      this.activeTarget = item;
    },
    rerender(id) {
      this.$emit("updateKey");
      var myModal = document.getElementById(id);
      myModal.click();
    },
    removeFile() {
      this.target.logo = null;
    },
  },
  mounted() {
    var _this = this;
    var myModals = document.getElementsByClassName("modal");
    for (let i = 0; i < myModals.length; i++) {
      var myModal = myModals[i];
      myModal.addEventListener("hidden.bs.modal", function () {
        _this.uploadErr = false;
      });
    }
  },
};
</script>
