<template></template>

<script>
import api from "@/services/BackendConnection";

export default {
  name: "LogoutView",
  mounted() {
    if (this.$store.state.user) {
      var user = {
        email: this.$store.state.user.email,
      };
      var _this = this;
      api()
        .signOut(user)
        .then((res) => {
          if (res) {
            _this.$router.push({ name: "login" });
            _this.$store.commit("logout");
          }
        });
    }
  },
};
</script>