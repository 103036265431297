<template>
  <div v-if="!isAdmin" class="list-table">
    <DatatableComponent
      :columns="columns"
      :data="campaigns"
      :options="{
        select: true,
        paging: false,
        ordering: true,
        order: [[2, 'desc']],
        searching: false,
        info: false,
        responsive: true,
      }"
      :keys="keys"
      :tooltip="true"
    />
  </div>
  <table class="table table-color table-striped table-hover" v-else>
    <thead>
      <tr>
        <th scope="col">Campaign Name</th>
        <th scope="col">Status</th>
        <th scope="col">Platform Campaigns</th>
      </tr>
    </thead>
    <tbody class="tbody-color">
      <tr v-for="(item, index) in campaigns" :key="index">
        <td>
          <router-link
            aria-current="page"
            :to="{
              name: 'edit_campaign',
              params: {
                campaignId: item.campaignId,
              },
            }"
          >
            {{ item.campaignName }}
          </router-link>
        </td>
        <td v-html="statusButton(item)"></td>
        <td>
          <router-link
            class="btn btn-info"
            aria-current="page"
            :to="{
              name: 'platform_campaigns',
              params: {
                propertyId: propertyId,
                campaignId: item.campaignId,
              },
            }"
          >
            Platform Campaigns
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
  <Modal id="pauseAd">
    <template v-slot:title> Pause ad</template>
    <template v-slot:body>
      <div class="instruction">Do you want pause {{ activeName }}?</div>
      <button
        type="button"
        class="btn btn-primary wti-btn-black"
        @click="pauseCampaign"
      >
        <span
          v-if="submitting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ submitting ? "" : "Pause!" }}
      </button>
    </template>
  </Modal>
  <!-- <Modal id="duplicateAd">
    <template v-slot:title> Duplicate ad</template>
    <template v-slot:body>
      <div class="instruction">Do you want duplicate {{ activeName }}?</div>
      <button
        type="button"
        class="btn btn-primary wti-btn-black"
        @click="duplicateCampaign"
      >
        <span
          v-if="submitting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ submitting ? "" : "Yes!" }}
      </button>
    </template>
  </Modal> -->
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.list-table
  max-height: 60vh
  min-height: 20vh
  overflow-y: auto
  .datatable
    width: 97% !important
    table.dataTable.table-striped
      thead
        th
          white-space: nowrap
      tbody
        td
          white-space: nowrap
        tr.selected >*
          box-shadow: inset 0 0 0 9999px #fff !important
          color: $Main
        tr:hover > *
          color: $Main
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import DatatableComponent from "@/components/DatatableComponent.vue";
import Modal from "@/components/Modal.vue";
import $ from "jquery";

export default {
  name: "ListCampaigns",
  setup(props) {
    return {
      activeId: ref(null),
      activeName: ref(null),
      keys: ["Campaign Name", "Status", "Start Date", "End Date", ""],
      submitting: ref(false),
    };
  },
  data() {
    var self = this;
    return {
      columns: [
        {
          data: "campaignName",
          defaultContent: "",
          orderable: true,
          width: "25%",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              $(document).on(
                "click",
                ".campaign-link-" + row.campaignId,
                function () {
                  self.redirectLink(row.campaignId, row.status);
                }
              );
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-link-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            } else {
              return (
                '<div class="hidden-for-sort">' +
                data +
                '</div><a href="javascript:void(0);" class="campaign-link-' +
                row.campaignId +
                '">' +
                data +
                " </a>"
              );
            }
          },
        },
        {
          data: "status",
          defaultContent: "",
          orderable: true,
          width: "15%",
          className: "text-break align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              switch (data) {
                case "Pending":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-blue" >' +
                    data +
                    "</button>"
                  );
                case "Draft":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-purple" >' +
                    data +
                    "</button>"
                  );
                case "Needs Approval":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-yellow" >' +
                    data +
                    "</button>"
                  );
                case "Active":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-green" >' +
                    data +
                    "</button>"
                  );
                case "Paused":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-red" >' +
                    data +
                    "</button>"
                  );
                case "Ended":
                  return (
                    '<div class="hidden-for-sort">' +
                    data +
                    '</div><button class="btn btn-grey" >' +
                    data +
                    "</button>"
                  );
              }
            } else {
              return '<div class="hidden-for-sort">' + data + "</div>";
            }
          },
        },
        {
          data: "startDateStr",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: "endDate",
          type: "date",
          defaultContent: "-",
          className: "text-break align-middle",
        },
        {
          data: null,
          defaultContent: "-",
          orderable: false,
          className: "text-end",
          render: function (data, type, row) {
            if (type === "display") {
              $(document).on(
                "click",
                ".campaignAction-" + row.campaignId,
                function () {
                  self.updateActiveate(row.campaignId, row.campaignName);
                }
              );
            }
            var html = '<div class="dropdown">';
            if (row.status != "Paused") {
              html +=
                '<button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">';
            } else {
              html +=
                '<button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled>';
            }
            html +=
              '<img src="' +
              self.getImgUrl() +
              '" style="height:12px"/></button>' +
              '<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">';
            if (row.status != "Paused") {
              html +=
                '<li><button class="btn campaignAction-' +
                row.campaignId +
                '" id="' +
                row.campaignId +
                '_pause" data-bs-toggle="modal" data-bs-target="#pauseAd">Pause Ad</button></li>';
            }
            // html +=
            //   '<li><button class="btn campaignAction-' +
            //   row.campaignId +
            //   '" id="' +
            //   row.campaignId +
            //   '_duplicate" data-bs-toggle="modal" data-bs-target="#duplicateAd">Duplicate Ad</button></li>';
            html += "</ul></div>";
            return html;
          },
        },
      ],
    };
  },
  props: ["propertyId", "campaigns"],
  components: {
    Modal,
    DatatableComponent,
  },
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
  },
  methods: {
    updateActiveate(val1, val2) {
      this.activeId = val1;
      this.activeName = val2;
    },
    redirectLink(campaignId, status) {
      if (status == "Draft") {
        this.$router.push({
          name: "edit_campaign",
          params: {
            propertyId: this.propertyId,
            campaignId: campaignId,
          },
        });
      } else {
        this.$router.push({
          name: "show_campaign",
          params: {
            propertyId: this.propertyId,
            campaignId: campaignId,
          },
        });
      }
    },
    pauseCampaign() {
      var _this = this;
      var info = {
        campaignId: this.activeId,
        status: "Paused",
      };
      _this.submitting = true;
      api()
        .updateWalkthruitCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.submitting = false;
            this.rerender("pauseAdclose");
          }
        });
    },
    duplicateCampaign() {
      var _this = this;
      var info = {
        campaignId: this.activeId,
      };
      _this.submitting = true;
      api()
        .duplicateWalkthruitCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.submitting = false;
            this.rerender("duplicateAdclose");
          }
        });
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    statusButton(item) {
      switch (item.status) {
        case "Pending":
          return '<button class="btn btn-blue" >' + item.status + "</button>";
        case "Draft":
          return '<button class="btn btn-purple" >' + item.status + "</button>";
        case "Needs Approval":
          return '<button class="btn btn-yellow" >' + item.status + "</button>";
        case "Active":
          return '<button class="btn btn-green" >' + item.status + "</button>";
        case "Paused":
          return '<button class="btn btn-red" >' + item.status + "</button>";
        case "Ended":
          return '<button class="btn btn-grey" >' + item.status + "</button>";
      }
    },
    getImgUrl() {
      return new URL(`../assets/vertical-dots.png`, import.meta.url).href;
    },
  },
  mounted() {},
};
</script>
