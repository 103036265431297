<template>
  <div class="form-group mb-3">
    <label for="password" class="form-label">{{ title }}</label>
    <input
      type="password"
      class="form-control password-input"
      :id="id"
      v-model="password"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <i class="bi bi-eye-slash" :id="toggleId" @click="toggleView()"></i>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  data() {
    return {
      password: null,
    };
  },
  props: ["title", "id", "toggleId", "placeholder"],
  methods: {
    toggleView() {
      const password = document.querySelector("#" + this.id);
      const togglePassword = document.querySelector("#" + this.toggleId);
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      // toggle the eye / eye slash icon
      togglePassword.classList.toggle("bi-eye");
    },
  },
};
</script>
