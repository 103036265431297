<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-end mb-4">
      <div class="me-2">
        <select
            class="form-select small-select"
            v-model="showClient"
            id="adminListTrending"
        >
          <option v-for="(item, key) in isClientList" :key="key" :value="item">
            {{ key }}
          </option>
        </select>
      </div>
      <div>
        <VueDatePicker
            v-model="month"
            month-picker
            :min-date="startMonth"
            :max-date="new Date()"
            format="MMMM yyyy"
        />
      </div>
    </div>
    <div class="position-relative admin-campaign-table mb-4 mx-4">
      <loading
          v-model:active="isLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
      />
      <table
          :bAutoWidth="true"
          class="table table-hover table-striped"
          id="trending-deliverable-table"
      >
        <thead>
        <tr>
          <th v-for="(key, index) in keys" :key="index">
            {{ key }}
          </th>
        </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
#trending-deliverable-table
  th
    word-break: keep-all !important
    white-space: normal
    vertical-align: middle
  tr.highlight-red
    td
      background-color: $ErrorMsg
      color: white
  .trending-details
    width: 100% !important
    thead
      visibility: collapse
    tbody
      tr:nth-child(odd)
        td
          background-color: #f2f2f2
  .col-sm-12
    padding: 0 !important
.admin-campaign-table
  min-height: 30vh
  overflow-y: auto
  .datatable
    width: 99%
    thead
      th
        white-space: nowrap
    tbody
      td
        word-wrap: break-word
    tfoot.tableTotalRow
      th
        background-color: $Main
        color: white
        white-space: nowrap
select.form-select.small-select
  padding: 10px 12px
  min-width: 120px
table.dataTable td.dt-control:before
  display: none !important
table.dataTable td.dt-control:after
  display: inline-block
  color: $Main
  content: "►"
  padding-left: 5px
table.dataTable tr.highlight-red td.dt-control:after
  color: white
</style>

<script>
import $ from "jquery";
import { ref } from "vue";

let DataTable;
if (typeof window !== "undefined") {
  import("datatables.net").then((module) => {
    DataTable = module.default;
  });
}
// import "datatables.net-dt/css/jquery.dataTables.min.css";

import api from "@/services/BackendConnection";
import moment from "moment";

export default {
  name: "TrendingDeliverablesAdmin",
  setup() {
    return {
      campaigns: ref(null),
      filterData: ref(null),
      month: ref({
        month: moment().utc().month(),
        year: moment().utc().year(),
      }),
      startMonth: moment("2023-10-01").utc().toDate(),
      isLoading: ref(true),
      keys: [
        "Campaign Name",
        "Contract Length (days)",
        "Days Active",
        "Days Remaning",
        "Contracted",
        "Impressions Delivered",
        "Impressions Remaining",
        "% toward Goal",
        "Expected % toward goal",
        "Difference in Tracking",
        "Impressions Needed Per Day",
        "Impressions being Delivered a Day",
        '"+/-" Impressions a Day',
        "EST added impressions",
        "EST added value",
      ],
      showClient: ref(2),
      isClientList: ref({
        All: 1,
        Client: 2,
        Internal: 3,
        "Free Trial": 4,
      }),
      dataTableInstance: ref(null),
    };
  },
  methods: {
    initializeDataTable() {
      var _this = this;
      this.dataTableInstance = $("#trending-deliverable-table").DataTable({
        data: [],
        columns: [
          {
            data: "name",
            defaultContent: "-",
            className: "text-break dt-control",
          },
          {
            data: "contractLength",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "daysActive",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "daysRemaining",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "contractedImpressions",
            defaultContent: 0,
            className: "text-break",
          },
          {
            data: "totalImpressions",
            defaultContent: 0,

            className: "text-break",
          },
          {
            data: "impressionsRemaining",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "percentageTowardsGoal",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "expectedPercentageTowardGoal",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "differenceInTracking",
            defaultContent: "0%",
            // type: 'num-fmt',
            className: "text-break",
          },
          {
            data: "impressionsNeededPerDay",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "impressionsBeingDeliveredPerDay",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "plusMinusImpressionsADay",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "estAddedImpressions",
            defaultContent: "0",
            className: "text-break",
          },
          {
            data: "estAddedValue",
            defaultContent: "0",
            className: "text-break",
          },
        ],
        select: true,
        paging: false,
        searching: false,
        info: false,
        responsive: true,
        order: [[9, "asc"]],

        createdRow: function (row, data, dataIndex) {
          // Adjust the index '9' to the index of your percentage column
          if (data.differenceInTracking) {
            var value = parseFloat(data.differenceInTracking.replace("%", ""));
            if (value > 15 || value < -15) {
              // $(row).css('background-color', 'red');
              row.classList.add("highlight-red");
            }
          }
        },
      });
    },
    getCampaignsList() {
      var _this = this;
      _this.isLoading = true;
      var newDate = moment.utc();
      newDate.set("year", this.month.year);
      newDate.set("month", this.month.month);
      newDate.set("date", 1);
      var endDate = newDate.clone().endOf("month");

      var info = {
        start: newDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
      };
      api()
          .getTrendingDeliverables(info)
          .then((res) => {
            if (res.data) {
              _this.campaigns = res.data.campaigns;
              _this.filterData = res.data.campaigns.filter(
                  (item) => item.clientType == 2
              );
              _this.isLoading = false;
            }
          });
    },
    format(d) {
      var headerRow = $("<tr>");
      this.keys.forEach(function (header) {
        headerRow.append($("<th>").text(header).addClass("text-bradk"));
      });
      var platformObj = {
        linkedin: "LinkedIn",
        facebook: "Facebook",
        instagram: "Instagram",
        google: "Google",
        twitter: "x",
      };
      var strVar = "";
      Object.entries(platformObj).forEach(([key, value]) => {
        strVar +=
            "<tr><td>" + value + "</td><td></td><td></td><td></td><td></td>";
        strVar +=
            "<td>" +
            d.platformImpressions[key].toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            "</td><td></td><td>" +
            d.platformTowardsGoal[key] +
            "</td><td></td>";
        strVar +=
            "<td></td><td></td><td>" +
            d.platformImpressionsPerDay[key].toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            "</td><td></td><td></td><td></td></tr>";
      });
      var childTable = $("<table>")
          .addClass("table")
          .addClass("trending-details")
          .append($("<thead>").append(headerRow))
          .append($("<tbody>").append(strVar));

      return childTable;
    },
  },
  mounted() {
    this.initializeDataTable();
    this.getCampaignsList();
    var _this = this;
    this.dataTableInstance.on("click", "td.dt-control", function (e) {
      let tr = e.target.closest("tr");
      let row = _this.dataTableInstance.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        var childTable = _this.format(row.data());
        var childDataTable = childTable.DataTable({
          select: false,
          paging: false,
          searching: false,
          info: false,
          responsive: true,
        });

        row.child(childDataTable.table().container()).show();
        childDataTable.columns.adjust();
      }
    });
  },
  watch: {
    filterData(newValue) {
      if (this.dataTableInstance) {
        this.dataTableInstance.clear().rows.add(newValue).draw();
      }
    },
    month(newVal, oldVal) {
      this.getCampaignsList();
      this.showClient = 2;
    },
    showClient(val) {
      if (val == 1) {
        this.filterData = this.campaigns;
      } else if (val == 2) {
        this.filterData = this.campaigns.filter((item) => item.clientType == 2);
      } else if (val == 3) {
        this.filterData = this.campaigns.filter(
            (item) => item.clientType != 2 && item.clientType != 4
        );
      } else if (val == 4) {
        this.filterData = this.campaigns.filter((item) => item.clientType == 4);
      }
    },
  },
};
</script>
