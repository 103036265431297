<template>
  <div class="container">
    <div class="welcome">
      <div class="welcome-text">Welcome!</div>
      <button
        type="button"
        class="btn btn-primary button-new-project wti-btn"
        data-bs-toggle="modal"
        data-bs-target="#addProject"
      >
        Order New Project
      </button>
    </div>
    <div
      class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4 cards"
    >
      <div class="col">
        <div class="WTIcarousel-container">
          <Carousel :autoplay="5000" :wrap-around="true" id="WTIcarousel">
            <Slide v-for="(val, key) in products" :key="key">
              <div class="carousel__item">
                <img
                  :src="getImgUrl(key)"
                  class="d-block w-100 WTIcarousel-image with-bg"
                  :alt="key"
                />
                <div class="carousel-caption d-block">
                  <div class="slide-label">
                    <span class="slide-label-text"> {{ val.name }}</span>
                  </div>
                  <div
                    class="d-flex slide-des"
                    :class="{
                      'des-with-icon': key == 'walkthruit-ads',
                    }"
                  >
                    <span
                      v-if="key == 'walkthruit-ads'"
                      class="slide-icons d-flex"
                    >
                      <img
                        class="digital-ads-img"
                        src="@/assets/linkedin-carousel.png"
                      />
                      <img
                        class="digital-ads-img"
                        src="@/assets/facebook-carousel.png"
                      />
                      <img
                        class="digital-ads-img"
                        src="@/assets/ins-carousel.png"
                      />
                      <img
                        class="digital-ads-img"
                        src="@/assets/youtube-carousel.png"
                      />
                      <img
                        class="digital-ads-img"
                        src="@/assets/google-carousel.png"
                      />
                    </span>
                    <p class="slide-des-text m-0" v-else>
                      {{ val.description }}
                    </p>
                  </div>
                  <div
                    class="d-flex slide-btns"
                    :class="{
                      'btns-with-icon': key == 'walkthruit-ads',
                    }"
                  >
                    <a
                      class="btn slide-button d-flex justify-content-between align-items-center p-0"
                      :href="val.url"
                      target="_blank"
                    >
                      <span class="slide-button-label">Learn More</span>
                      <span class="slide-button-link"> <span></span> </span>
                    </a>
                  </div>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
      <div
        class="col d-flex justify-content-center"
        v-for="(item, index) in properties"
        :key="index"
      >
        <div class="card">
          <div
            class="thumbnail"
            :class="{
              'thumbnail-image': item.img,
              'thumbnail-example': !item.img,
            }"
            :style="{
              backgroundImage: item.img ? `url(${item.img})` : null,
            }"
          >
            <div v-if="!item.img">
              <img :src="thumbnail_placeholder" alt="" />
            </div>
            <div class="thumbnail-info">
              <div class="name">{{ item.name }}</div>
              <div class="location">
                {{ item.location ? item.location : "N/A" }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: 'campaigns',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/manager.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">Ads Manager</div>
                </div>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: '3D_walkthru',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/walkthru.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">3D Walkthru</div>
                </div>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: 'report',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/reporting.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">Ads Reporting</div>
                </div>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: 'video',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/studio.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">Video</div>
                </div>
              </router-link>
            </div>
            <!-- <div class="col-6">
            <router-link
              class="btn btn-view"
              aria-current="page"
              :to="{
                name: 'studio',
                params: {
                  propertyId: item.id,
                },
              }"
            >
              <div class="view-button">
                <div class="view-icon">
                  <img
                    src="@/assets/icons/studio.png"
                    alt=""
                    height="12"
                    class="d-inline-block align-text-top"
                  />
                </div>
                <div class="view-name">Studio</div>
              </div>
            </router-link>
          </div> -->
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: 'audiences',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/audiences.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">Ads Audiences</div>
                </div>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                class="btn btn-view"
                aria-current="page"
                :to="{
                  name: 'contents',
                  params: {
                    propertyId: item.id,
                  },
                }"
              >
                <div class="view-button">
                  <div class="view-icon">
                    <img
                      src="@/assets/icons/content.png"
                      alt=""
                      height="12"
                      class="d-inline-block align-text-top"
                    />
                  </div>
                  <div class="view-name">Content</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import products from "@/utils/products.json";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "ListProperty",
  setup(props) {
    return {
      properties: ref(null),
      thumbnail_placeholder: new URL(
        `../assets/thumbnail-placeholder.svg`,
        import.meta.url
      ).href,
      products: products,
    };
  },
  props: ["clientID"],
  components: {
    Carousel,
    Slide,
  },
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
      }
      return isAdmin;
    },
    userId() {
      if (this.isAdmin && this.clientID) {
        this.$store.commit("setAdminClientView", true);
        this.$store.commit("setCurrentUserId", this.clientID);
        return this.clientID;
      } else {
        return this.$store.state.user ? this.$store.state.user.userId : null;
      }
    },
  },
  methods: {
    getProperties() {
      var _this = this;
      var info = {
        userId: _this.userId,
      };
      api()
        .listProperties(info)
        .then((res) => {
          if (res.data) {
            _this.properties = res.data;
          }
        });
    },
    getImgUrl(item) {
      return new URL(`../assets/${item}-bg.png`, import.meta.url).href;
    },
  },
  created() {
    this.getProperties();
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.welcome
  display: flex
  justify-content: space-between
  align-items: center
  margin: 1em auto 0
  padding: 0

  .welcome-text
    font-weight: 600
    font-size: 22px
    line-height: 28px
    +mobile
      font-size: 20px

  .links
    a
      margin: 0.5em
      display: inline-block
      font-weight: 600
      font-size: 16px
      line-height: 20px
      padding: 14px 16px
      border: 1px solid $Main
      border-radius: 12px

  .button-new-project
    font-weight: 600
    font-size: 16px
    line-height: 20px
    padding: 14px 16px
    color: white
    border-radius: 12px
    margin: 0.5em
    +mobile
      padding: 10px
      font-size: 12px
      border-radius: 0.5rem

.cards
  .card
    padding: 30px 30px
    margin: 1em 0em
    width: 350px
    border-radius: 12px
    border: 1px solid $LightGreyBorder
    +mobile
      margin: 1em 0
    .col-6
      padding: 0 10px

    .thumbnail
      height: 154px
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      background-repeat: no-repeat
      background-position: center
      background-size: cover
      border-radius: 11px
      overflow: hidden
      position: relative
      background-color: $Main
      z-index: 1
      margin-bottom: 0.5em

      .thumbnail-info
        position: absolute
        color: #ffffff
        text-align: center
        bottom: 0
    .thumbnail-image
      &::before
        content: ""
        position: absolute
        top: 0px
        right: 0px
        bottom: 0px
        left: 0px
        background-color: rgba(6,21,43,0.5)
    .thumbnail-example img
      height: 42px
      width: 42px
      margin-bottom: 20px
    .name
      text-align: center
      font-weight: 600
      font-size: 16px
      line-height: 20px
      margin: 18px 0 0

    .location
      text-align: center
      font-weight: 400
      font-size: 12px
      margin-bottom: 15px
      line-height: 15px

    .btn-view
      font-weight: 600
      font-size: 12px
      line-height: 20px
      padding: 8px
      color: $Main
      border-radius: 12px
      margin: 0.5em 0
      width: 100%
      border: 1.5px solid $LightGreyBorder
      .view-button
        text-align: left
        padding: 5px 5px 10px
        .view-icon
          display: inline-block
          width: 25px
          height: 25px
          background-color: $Blue
          border-radius: 50%
          position: relative
          margin-bottom: 10px
          img
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            height: 12.5px
            width: auto
            filter: invert(1)

      .view-name
        font-weight: 600
        width: 100px
        text-align: left
        font-size: 12px

      .view-arrow
        background-color: white
        padding: 1px 4px
        color: $Main
        border-radius: 6px
        font-size: 12px

      &:hover
        background-color: $LighterGrey
        border: 1.5px solid $BlueBtnHover
        color: $Main

    .btn-view.primary
      background-color: $Blue
      color: white
.WTIcarousel-container
  padding: 30px 30px
  border-radius: 12px
  border: 1px solid $LightGreyBorder
  margin: 1em 0em
  #WTIcarousel
    .carousel__item
      border-radius: 8px
      position: relative
      min-height: 478px
      width: 100%
      .WTIcarousel-image
        height: 472px
        background-color: $Main
        border-radius: 8px
      .WTIcarousel-image.no-bg
        background-color: white
        color: $Main
      .WTIcarousel-image.light-bg
        background-color: $LightBlue
        color: $Main
        border: 3px solid $Main
      .WTIcarousel-image.with-bg
        object-fit: cover
      .carousel-caption
        position: absolute
        right: 0
        top: 0
        left: 0
        padding-top: 0
        padding-bottom: 0
        color: #fff
        text-align: left
        height: 100%
        // display: flex !important
        // flex-direction: column
        // align-items: start
        // justify-content: space-evenly
        .slide-title
          font-weight: 600
          font-size: 10px
          line-height: 13px
          color: #D8D8D8
          margin: 19px 0 0px 45px
        .slide-title-dark
          color: #5C5C5C
        .slide-label
          margin: 0 62px 0 23px
          height: 40%
          display: flex
          .slide-label-text
            font-weight: 700
            font-size: 24px
            line-height: 30.24px
            color: #FFFFFF
            word-wrap: break-word
            word-spacing: 100px
            display: inline-block
            align-self: flex-end
        .slide-label-dark
          .slide-label-text
            color: $Main
        .slide-icons
          margin: 20px 0 0 0
          .digital-ads-img
            height: 20px
            margin: 0 16px 0 0
        .slide-des
          margin: 17px 45px 0 23px
          height: 30%
          .slide-des-text
            font-weight: 600
            font-size: 14px
            line-height: 18px
            color: #FFFFFF
          .slide-des-text-dark
            color: $Main
        .slide-des.des-with-icon
          margin: 0 45px 5px 23px
        .slide-btns
          margin: 33px 80px 0 20px
          height: 30%
          .slide-button
            width: 153px
            height: 29px
            background: #fff
            span
              display: inline-block
            .slide-button-label
              font-weight: 600
              font-size: 12px
              line-height: 15px
              color: #06001A
              padding-left: 14px
            .slide-button-link
              background: #06001A
              border-radius: 8px
              width: 21px
              height: 18px
              vertical-align: middle
              margin-right: 6.4px
              span
                height: 100%
                width: 58%
                background: url('@/assets/arrow.svg') no-repeat center
                background-size: contain
          .slide-button-dark
            background: $Main
            .slide-button-label
              color: white
            .slide-button-link
              background: white
              span
                background: url('@/assets/arrow-dark.svg') no-repeat center
        .slide-btns.btns-with-icon
          margin: 50px 80px 0 20px
</style>
