<template>
  <div class="form-group mb-3" :class="childclass">
    <label :for="id" class="form-label" v-if="label">{{ label }}</label>
    <input
      :type="type"
      class="form-control"
      :id="id"
      aria-describedby="inputHelp"
      v-model="inputModel"
      :disabled="disable"
      step="0.01"
      :maxlength="maxlength ? maxlength : -1"
      :placeholder="placeholder"
      :required="required"
    />
    <div id="inputHelp" class="form-text" v-if="helpMsg">
      {{ helpMsg }}
    </div>
    <div class="invalid-feedback">{{ errorMsg }}</div>
  </div>
</template>
<style lang="sass">
@import "@/assets/vars.sass"

.form-label
  color: $Main
  font-weight: 600
  font-size: 18px
  line-height: 18px
.form-control
  background-color: $White !important
  color: $Main !important
  font-size: 14px !important
  line-height: 18px !important
  font-weight: 400
  border: 1px solid $LightGreyBorder
  border-radius: 12px
  &::placeholder
    color: $LightGrey !important
</style>

<script>
import { computed } from "vue";
import tooltips from "@/utils/tooltips.json";

export default {
  name: "Input",
  props: [
    "type",
    "label",
    "modelValue",
    "helpMsg",
    "disable",
    "step",
    "maxlength",
    "placeholder",
    "childclass",
    "required",
  ],
  setup(props, { emit }) {
    const inputModel = computed({
      get: () => {
        if (props.modelValue) {
          return props.modelValue;
        } else {
          return "";
        }
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      inputModel,
      errorMsg: tooltips.requiredErr,
    };
  },
  computed: {
    id() {
      if (this.label || this.childclass) {
        return this.label.toLowerCase().replace(/ /g,'-') + this.childclass;
      } else {
        return null;
      }
    },
  },
  mounted() {
    (function () {
      "use strict";

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll(".needs-validation");

      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    })();
  },
};
</script>
