<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container flex-column">
      <PropertyDashboardContentHeading
        :propertyId="propertyId"
        @update-Propertyname="updatePropertyname"
      />
      <div v-show="video">
        <div class="mb-3 walkthru-heading row justify-content-between">
          <div class="header col-12 col-md-4 my-auto">
            <p v-if="videos.length == 1" class="video-name">
              {{ videos[0].name }}
            </p>
            <select
              class="form-select"
              v-if="videos.length > 1"
              v-model="video"
            >
              <option
                v-for="(item, index) in videos"
                :key="index"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-8 text-end">
            <div v-if="showBtns">
              <a
                :href="videoWebpageUrl"
                target="_blank"
                class="btn btn-primary wti-btn wti-btn-white"
                type="button"
              >
                Video Webpage
              </a>
              <button
                class="btn btn-primary wti-btn ms-3"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#embedWistiaCode"
              >
                Embed Code
              </button>
            </div>
          </div>
        </div>
        <div class="section kpi-section">
          <div class="form-label mb-2">Video Metrics</div>
          <p class="form-info no-margin-bottom">
            Metrics below are only for the video player from the embed code. If
            you downloaded the video, metrics cannot be tracked here.
          </p>
          <div class="row kpi-container position-relative">
            <loading
              v-model:active="isLoading"
              :is-full-page="false"
              loader="dots"
              :opacity="1"
            />
            <div
              class="col-12 col-md-3 kpi"
              v-for="(item, key) in kpis"
              :key="key"
            >
              <div class="kpi-border">
                <div class="title">{{ item.name }}</div>
                <div class="value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs section target-section">
          <ul class="nav nav-tabs" id="walkthruTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="feed-tab"
                data-bs-toggle="tab"
                data-bs-target="#feed"
                type="button"
                role="tab"
                aria-controls="feed"
                aria-selected="false"
                @click="tuggleFeed()"
              >
                Feed
              </button>
            </li>
            <JsonCSV
              :data="feedInfo"
              :name="video.name + '.csv'"
              v-if="showFeed && feedInfo.length > 0"
              class="export-btn"
            >
              <button class="btn btn-dark wti-btn-black my-2">
                Export to CSV
              </button>
            </JsonCSV>
          </ul>
          <div class="tab-content" id="walkthruTabContent">
            <div
              class="tab-pane fade show active"
              id="feed"
              role="tabpanel"
              aria-labelledby="feed-tab"
            >
              <div class="position-relative feed-total-container">
                <loading
                  v-model:active="feedInfoLoading"
                  :is-full-page="false"
                  loader="dots"
                  :opacity="1"
                  class="mx-auto p-0 m-0"
                />
                <div class="feed-container mt-3" v-if="!feedInfoLoading">
                  <DatatableComponent
                    :columns="columns"
                    :data="feedInfo"
                    :options="{
                      responsive: true,
                      order: [0, 'desc'],
                      dom: 'frtl',
                      lengthMenu: [
                        [10, 25, 50, -1],
                        [10, 25, 50, 'All'],
                      ],
                    }"
                    :keys="keys"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal id="embedWistiaCode">
    <template v-slot:title>Embed Code</template>
    <template v-slot:body>
      <p class="border section mt-2 text-break">{{ embedCode }}</p>
      <button
        type="button"
        v-clipboard:copy="embedCode"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="btn wti-btn-black"
      >
        Copy
      </button>
    </template>
  </Modal>
</template>

<style scoped  lang="sass">
@import "@/assets/vars.sass"
.walkthru-heading
  .header
    .video-name
      font-size: 18px
      line-height: 18px
      color: $Main
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import Input from "@/components/Input.vue";
import JsonCSV from "vue-json-csv";
import DatatableComponent from "@/components/DatatableComponent.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "PropertyVideo",
  setup(props) {
    return {
      video: ref(null),
      videos: ref([]),
      kpis: ref({
        load_count: {
          value: 0,
          name: "Tracked Plays",
        },
        visitors: {
          value: 0,
          name: "Unique Plays",
        },
        // hours_watched: {
        //   value: 0,
        //   name: "Tracked Watch Time",
        // },
        // engagement: {
        //   value: 0,
        //   name: "Engagement",
        // },
      }),
      isLoading: ref(false),
      showFeed: ref(true),
      feedInfoLoading: ref(true),
      keys: ref(["Date", "Time", "City"]),
      feedInfo: ref([]),
      columns: ref([
        {
          data: "date",
          type: "date",
          defaultContent: "-",
          className: "text-break",
        },
        {
          data: "time",
          type: "date",
          orderable: false,
          defaultContent: "",
          className: "text-break",
        },
        {
          data: "city",
          defaultContent: "-",
          className: "text-break",
        },
      ]),
      totalPlay: ref(0),
      propertyName: ref(null),
    };
  },
  components: {
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    Input,
    JsonCSV,
    DatatableComponent,
    Modal,
  },
  props: ["propertyId"],
  computed: {
    embedCode() {
      if (this.video) {
        var embedCode =
          '<script src="https://fast.wistia.com/embed/medias/' +
          this.video.mediaId +
          '.jsonp" async></' +
          'script><script src="https://fast.wistia.com/assets/' +
          'external/E-v1.js" async></' +
          'script><div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">' +
          '<div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">' +
          '<div class="wistia_embed wistia_async_' +
          this.video.mediaId +
          ' seo=false videoFoam=true" style="height:100%;position:relative;width:100%">' +
          '<div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">' +
          '<img src="https://fast.wistia.com/embed/medias/' +
          this.video.mediaId +
          '/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>';
        return embedCode;
      }
    },
    videoWebpageUrl() {
      var url = "";
      if (this.video && this.propertyName) {
        url =
          "https://video.walkthruit.com/?id=" +
          this.video.mediaId +
          "&title=" +
          this.propertyName;
      }
      return url;
    },
    showBtns() {
      var showBtns = false;
      if (this.video) {
        showBtns =this.video.showBtns;
      }
      return showBtns;
    },
  },
  methods: {
    getVideos() {
      if (this.$store.state.user) {
        var _this = this;
        var info = {
          propertyId: _this.propertyId,
        };
        api()
          .getWistiaVideos(info)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              _this.videos = res.data;
              _this.video = res.data[0];
            }
          });
      }
    },
    clear() {
      this.kpis = {
        load_count: {
          value: 0,
          name: "Tracked Plays",
        },
        visitors: {
          value: 0,
          name: "Unique Plays",
        },
        // hours_watched: {
        //   value: 0,
        //   name: "Tracked Watch Time",
        // },
        // engagement: {
        //   value: 0,
        //   name: "Engagement",
        // },
      };
      this.isLoading = true;
      this.feedInfoLoading = true;
      this.feedInfo = [];
      this.totalPlay = 0;
    },
    getKpiData() {
      var _this = this;
      var info = {
        mediaId: _this.video.mediaId,
        propertyId: _this.propertyId,
      };
      api()
        .getWistiaKpis(info)
        .then((res) => {
          if (res.data) {
            Object.keys(_this.kpis).forEach((key) => {
              if (key == "hours_watched") {
                _this.kpis[key].value = Math.ceil(res.data[key]) + " hours";
              } else if (key == "engagement") {
                _this.kpis[key].value = Math.ceil(res.data[key] * 100) + "%";
              } else {
                _this.kpis[key].value = res.data[key];
              }
            });
            _this.totalPlay = res.data.play_count;
            _this.isLoading = false;
            _this.getFeedData();
          }
        });
    },
    getFeedData() {
      var _this = this;
      var info = {
        mediaId: _this.video.mediaId,
        total: this.totalPlay,
        propertyId: _this.propertyId,
      };
      api()
        .getWistiaFeed(info)
        .then((res) => {
          if (res.data) {
            _this.feedInfo = res.data;
            _this.feedInfoLoading = false;
          }
        });
    },
    onCopy(e) {
      // console.log("You just copied: " + e.text);
    },
    onError(e) {
      // console.log("Failed to copy texts");
    },
    updatePropertyname(value) {
      this.propertyName = value;
    },
  },
  mounted() {
    this.getVideos();
  },
  watch: {
    video(val) {
      if (val) {
        this.clear();
        this.getKpiData();
      }
    },
  },
};
</script>
