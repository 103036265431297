<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-end mb-4">
      <div class="me-2">
        <select
          class="form-select small-select"
          v-model="showActive"
          id="listCampaignStatus"
        >
          <option v-for="(item, key) in isActiveList" :key="key" :value="item">
            {{ key }}
          </option>
        </select>
      </div>
      <div class="me-2">
        <select
          class="form-select small-select"
          v-model="showClient"
          id="listCampaignCategory"
        >
          <option v-for="(item, key) in isClientList" :key="key" :value="item">
            {{ key }}
          </option>
        </select>
      </div>
      <div>
        <VueDatePicker
          v-model="month"
          month-picker
          :min-date="startMonth"
          :max-date="new Date()"
          format="MMMM yyyy"
        />
      </div>
    </div>
    <div class="position-relative admin-campaign-table mb-4 mx-4">
      <loading
        v-model:active="isLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <DataTable
        v-if="!isLoading"
        :columns="columns"
        :data="filterData"
        :bAutoWidth="true"
        class="table table-hover table-striped"
        id="campaigninfo-table"
        :options="{
          select: true,
          paging: false,
          searching: false,
          info: false,
          responsive: true,
          order: [[1, 'asc']],
        }"
        ref="table"
      >
        <thead>
          <tr>
            <th v-for="(key1, index1) in keys1" :key="index1">
              {{ key1 }}
            </th>
            <th>Contract<br />Duration</th>
            <th>ContractDuration-Hidden</th>
            <th>Start Date</th>
            <th>Current<br />Contract<br />End Date</th>
            <th>Invoiced<br />Til</th>
            <th v-for="(key2, index2) in keys2" :key="index2">
              {{ key2 }}
            </th>
            <th>Campaign<br />Actual<br />Spend ($40)</th>
            <th>ActualSpend-Hidden</th>
            <th>Monthly<br />Client<br />Spend (CAD)</th>
            <th v-for="(key3, index3) in keys3" :key="index3">
              {{ key3 }}
            </th>
          </tr>
        </thead>
        <tfoot class="tableTotalRow">
          <tr>
            <th></th>
            <th>Total:</th>
            <th colspan="5"></th>
            <th class="totalViews"></th>
            <th colspan="2" class="totalFBSpend"></th>
            <th colspan="2" class="totalInsSpend"></th>
            <th colspan="2" class="totalLISpend"></th>
            <th colspan="2" class="totalGoogleSpend"></th>
            <th colspan="2" class="totalSpend"></th>
            <th colspan="2" class="totalmonthlyClientSpend"></th>
            <th colspan="2" class="totalWTIRevenue"></th>
            <th colspan="4"></th>
          </tr>
        </tfoot>
      </DataTable>
    </div>
  </div>
  <Modal id="editClient">
    <template v-slot:title> {{ activeName }}</template>
    <template v-slot:body>
      <Input
        v-model="monthlyClientSpend"
        type="number"
        label="Monthly Client Spend(CAD)"
      />
      <Input
        v-model="freeTrailUntil"
        type="date"
        label="Free Trail Until"
      />
      <div class="form-group mb-3">
        <label for="iscampaignClientSelectEdit" class="form-label"
          >Category</label
        >
        <select
          class="form-select mb-0"
          v-model="activeClientType"
          id="iscampaignClientSelectEdit"
        >
          <option
            v-for="(item, key) in isClientListEdit"
            :key="key"
            :value="item"
          >
            {{ key }}
          </option>
        </select>
      </div>
      <div class="form-group mb-3">
        <label for="contratEndDate" class="form-label"
          >Current Contract End Date</label
        >
        <input
          type="date"
          class="form-control"
          id="contratEndDate"
          v-model="activeContractEndDate"
        />
      </div>
      <div class="form-group mb-3">
        <label for="invoicedTil" class="form-label">Invoiced Til</label>
        <input
          type="date"
          class="form-control"
          id="invoicedTil"
          v-model="activeInvoicedTil"
        />
      </div>
      <button
        type="button"
        class="btn btn-primary wti-btn-black"
        @click="updateClient"
      >
        <span
          v-if="submitting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ submitting ? "" : "Update!" }}
      </button>
    </template>
  </Modal>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.admin-campaign-table
  min-height: 30vh
  overflow-y: auto
  .datatable
    width: 99%
    thead
      th
        white-space: nowrap
    tbody
      td
        word-wrap: break-word
    tfoot.tableTotalRow
      th
        background-color: $Main
        color: white
        white-space: nowrap
select.form-select.small-select
  padding: 10px 12px
  min-width: 100px
select.form-select.small-select#listCampaignStatus
  padding: 10px 30px 10px 12px
  min-width: 100px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import moment from "moment";
import $ from "jquery";

import DatatableComponent from "@/components/DatatableComponent.vue";
import Modal from "@/components/Modal.vue";
import Input from "./Input.vue";

export default {
  name: "ListCampaignsInfoAdmin",
  setup(props) {
    var self = this;
    return {
      campaigns: ref(null),
      filterData: ref(null),
      month: ref({
        month: moment().utc().month(),
        year: moment().utc().year(),
      }),
      startMonth: moment("2023-10-01").utc().toDate(),
      isLoading: ref(true),
      keys1: ["Category", "Campaign Name"],
      keys2: [
        "Views",
        "Views-Hidden",
        "Facebook",
        "Facebook-Hidden",
        "Instagram",
        "Instagram-Hidden",
        "LinkedIn",
        "LinkedIn-Hidden",
        "Google",
        "Google-Hidden",
      ],
      keys3: [
        "ClientSpend-Hidden",
        "WTI Revenue",
        "Revenue-Hidden",
        "Revenue % (80%)",
        "Revenue%-Hidden",
        "Action",
        "Category-Hidden",
      ],
      activeId: ref(null),
      activeName: ref(null),
      activeClientType: ref(null),
      activeContractEndDate: ref(null),
      activeInvoicedTil: ref(null),
      submitting: ref(false),
      showClient: ref(1),
      showActive: ref(4),
      isClientList: ref({
        All: 1,
        Client: 2,
        Internal: 3,
        "Free Trial": 4,
      }),
      isActiveList: ref({
        All: 1,
        Active: 2,
        "Needs Approval": 3,
        "Active or Needs Approval": 4,
      }),
      isClientListEdit: ref({
        Client: 2,
        Internal: 3,
        // "Free Trial": 4,
      }),
      monthlyClientSpend: ref(null),
      freeTrailUntil: ref(null),
    };
  },
  data() {
    var self = this;
    return {
      columns: [
        {
          data: "clientDisplayType",
          defaultContent: "-",
          className: "text-break",
          orderData: [26],
          targets: [0],
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data == 2
                ? "Client"
                : data == 3
                ? "Internal"
                : data == 4
                ? "Free Trial"
                : data == 5
                ? "Free Trial/Client"
                : "unknown";
            }
          },
        },
        {
          data: "name",
          defaultContent: "-",
          className: "text-break",
        },
        {
          data: "duration",
          defaultContent: "-",
          className: "text-break",
          orderData: [3],
          targets: [2],
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data + " month";
            }
          },
        },
        {
          data: "duration",
          visible: false,
          searchable: false,
          targets: [2],
        },
        {
          data: "startDateStr",
          type: "date",
          defaultContent: "-",
        },
        {
          data: "contractEndDate",
          type: "date",
          defaultContent: "null",
        },
        {
          data: "invoicedTil",
          type: "date",
          defaultContent: "null",
        },
        {
          data: "totalImpressions",
          defaultContent: "0",
          className: "text-break",
          orderData: [8],
          targets: [7],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              });
            }
          },
        },
        {
          data: "totalImpressions",
          visible: false,
          searchable: false,
          targets: [7],
        },
        {
          data: "facebook.cost",
          defaultContent: "$0",
          className: "text-break",
          orderData: [10],
          targets: [9],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              var cpm = (
                (data * 1000) /
                row.facebook.impressions
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              return "$" + cost + " (" + cpm + ")";
            }
          },
        },
        {
          data: "facebook.cost",
          visible: false,
          searchable: false,
          targets: [9],
        },
        {
          data: "instagram.cost",
          defaultContent: "$0",
          className: "text-break",
          orderData: [12],
          targets: [11],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              var cpm = (
                (data * 1000) /
                row.instagram.impressions
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              return "$" + cost + " (" + cpm + ")";
            }
          },
        },
        {
          data: "instagram.cost",
          visible: false,
          searchable: false,
          targets: [11],
        },
        {
          data: "linkedin.cost",
          defaultContent: "$0",
          className: "text-break",
          orderData: [14],
          targets: [13],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              var cpm = (
                (data * 1000) /
                row.linkedin.impressions
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              return "$" + cost + " (" + cpm + ")";
            }
          },
        },
        {
          data: "linkedin.cost",
          visible: false,
          searchable: false,
          targets: [13],
        },
        {
          data: "google.cost",
          defaultContent: "$0",
          className: "text-break",
          orderData: [16],
          targets: [15],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              var cpm = ((data * 1000) / row.google.impressions).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              );
              return "$" + cost + " (" + cpm + ")";
            }
          },
        },
        {
          data: "google.cost",
          visible: false,
          searchable: false,
          targets: [15],
        },
        {
          data: "totalCost",
          defaultContent: "$0",
          className: "text-break",
          orderData: [18],
          targets: [17],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
              var cpm = ((data * 1000) / row.totalImpressions).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              );
              return "$" + cost + " ($" + cpm + ")";
            }
          },
        },
        {
          data: "totalCost",
          visible: false,
          searchable: false,
          targets: [17],
        },
        {
          data: "monthlyClientSpendtoShow",
          defaultContent: "$0",
          className: "text-break",
          orderData: [20],
          targets: [19],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost;
              cost = data
                ? "$" +
                  data.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "unknown";
              return cost;
            }
          },
        },
        {
          data: "monthlyClientSpendtoShow",
          visible: false,
          searchable: false,
          targets: [19],
        },
        {
          data: "wtiRevenue",
          defaultContent: "$0",
          className: "text-break",
          orderData: [22],
          targets: [21],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              var cost = data.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return "$" + cost;
            }
          },
        },
        {
          data: "wtiRevenue",
          visible: false,
          searchable: false,
          targets: [21],
        },
        {
          data: "wtiRevenuePercent",
          defaultContent: "N/A",
          className: "text-break",
          orderData: [24],
          targets: [23],
          render: function (data, type, row, meta) {
            if (type === "display" && data) {
              if (data > 0) {
                return (
                  data.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + "%"
                );
              }
            }
          },
        },
        {
          data: "wtiRevenuePercent",
          visible: false,
          searchable: false,
          targets: [23],
        },
        {
          data: null,
          defaultContent: "-",
          orderable: false,
          className: "text-end",
          render: function (data, type, row) {
            if (type === "display") {
              $(document).on("click", "#editClient-" + row.id, function () {
                self.updateActiveate(
                  row.id,
                  row.name,
                  row.clientType,
                  row.monthlyClientSpend,
                  row.contractEndDate,
                  row.invoicedTil,
                  row.freeTrailUntil,
                );
              });
              var html =
                '<button class="btn btn-primary" id="editClient-' +
                row.id +
                '" data-bs-toggle="modal" data-bs-target="#editClient">' +
                '<img src="' +
                self.getImgUrl() +
                '" style="height:12px; color:white"/>' +
                "</button>";
              return html;
            }
          },
        },
        {
          data: "clientDisplayType",
          visible: false,
          searchable: false,
          targets: [0],
        },
      ],
    };
  },
  components: {
    DatatableComponent,
    Modal,
    Input,
  },
  computed: {
    activeDateRange() {
      var newDate = moment.utc();
      newDate.set("year", this.month.year);
      newDate.set("month", this.month.month);
      newDate.set("date", 1);
      var endDate = newDate.clone().endOf("month");
      if (endDate > moment().utc()) {
        endDate = moment().utc();
      }
      return { start: newDate, end: endDate };
    },
  },
  methods: {
    getCampaignsList() {
      var _this = this;
      _this.isLoading = true;
      var startDate = _this.activeDateRange.start;
      var endDate = _this.activeDateRange.end;
      var info = {
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
      };
      api()
        .getCampaignsList(info)
        .then((res) => {
          if (res.data) {
            _this.campaigns = res.data.campaigns;
            _this.filterData = res.data.campaigns.filter(
              (item) =>
                item.contractEndDate &&
                (item.status == "Active" || item.status == "Needs Approval") &&
                moment(item.startDateStr, "YYYY-MM-DD").utc() <= endDate &&
                moment(item.contractEndDate, "YYYY-MM-DD").utc() >= startDate
            );
            _this.formatedTotalVals();
            _this.isLoading = false;
          }
        });
    },
    updateActiveate(val1, val2, val3, val4, val5, val6, val7) {
      this.activeId = val1;
      this.activeName = val2;
      this.activeClientType = val3;
      this.activeContractEndDate = val5;
      this.activeInvoicedTil = val6;
      this.monthlyClientSpend = val4;
      this.freeTrailUntil = val7;
    },
    getImgUrl() {
      return new URL(`../assets/edit.svg`, import.meta.url).href;
    },
    updateClient() {
      var _this = this;
      var info = {
        campaignId: this.activeId,
        monthlyClientSpend: this.monthlyClientSpend,
        freeTrailUntil: this.freeTrailUntil,
        clientType: this.activeClientType,
        contractEndDate: this.activeContractEndDate,
        invoicedTil: this.activeInvoicedTil,
      };
      _this.submitting = true;
      api()
        .updateCampaignInfoAdmin(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.submitting = false;
            this.rerender("editClientclose");
          }
        });
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    formatedTotalVals() {
      if (this.filterData) {
        var temptotalViews = this.filterData.reduce((acc, curr) => {
          return acc + curr.totalImpressions;
        }, 0);
        var totalViews = temptotalViews.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
        var FBspend = this.filterData.reduce((acc, curr) => {
          return acc + curr.facebook.cost;
        }, 0);
        var totalFBSpend =
          FBspend == 0
            ? "$0.00"
            : "$" +
              FBspend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var InsSpend = this.filterData.reduce((acc, curr) => {
          return acc + curr.instagram.cost;
        }, 0);
        var totalInsSpend =
          InsSpend == 0.0
            ? "$" + 0
            : "$" +
              InsSpend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var LIspend = this.filterData.reduce((acc, curr) => {
          return acc + curr.linkedin.cost;
        }, 0);
        var totalLISpend =
          LIspend == 0.0
            ? "$" + 0
            : "$" +
              LIspend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var googleSpend = this.filterData.reduce((acc, curr) => {
          return acc + curr.google.cost;
        }, 0);
        var totalGoogleSpend =
          googleSpend == 0
            ? "$0.00"
            : "$" +
              googleSpend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var spend = this.filterData.reduce((acc, curr) => {
          return acc + curr.totalCost;
        }, 0);
        var totalSpend =
          spend == 0
            ? "$0.00"
            : "$" +
              spend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var monthlyClientSpend = this.filterData.reduce((acc, curr) => {
          return acc + curr.monthlyClientSpend;
        }, 0);
        var totalmonthlyClientSpend =
          monthlyClientSpend == 0
            ? "$0.00"
            : "$" +
              monthlyClientSpend.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        var WTIRevenue = monthlyClientSpend - spend;
        var totalWTIRevenue =
          WTIRevenue == 0
            ? "$0.00"
            : "$" +
              WTIRevenue.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        $(document).ready(function () {
          $("#campaigninfo-table .totalViews").text(totalViews);
          $("#campaigninfo-table .totalFBSpend").text(totalFBSpend);
          $("#campaigninfo-table .totalInsSpend").text(totalInsSpend);
          $("#campaigninfo-table .totalLISpend").text(totalLISpend);
          $("#campaigninfo-table .totalGoogleSpend").text(totalGoogleSpend);
          $("#campaigninfo-table .totalSpend").text(totalSpend);
          $("#campaigninfo-table .totalmonthlyClientSpend").text(
            totalmonthlyClientSpend
          );
          $("#campaigninfo-table .totalWTIRevenue").text(totalWTIRevenue);
        });
      }
    },
    dataByFilters(showClient, showActive) {
      var result;
      var _this = this;
      if (showClient == 1) {
        result = this.campaigns;
      } else if (showClient == 2) {
        result = this.campaigns.filter((item) => item.clientDisplayType == 2);
      } else if (showClient == 3) {
        result = this.campaigns.filter(
          (item) => item.clientDisplayType != 2 && item.clientDisplayType != 4
        );
      } else if (showClient == 4) {
        result = this.campaigns.filter((item) => (item.clientDisplayType == 4 ||item.clientDisplayType == 5) );
      }
      if (showActive == 1) {
        this.filterData = result;
      } else if (showActive == 2) {
        this.filterData = result.filter(
          (item) =>
            item.contractEndDate &&
            item.status == "Active" &&
            moment(item.startDateStr, "YYYY-MM-DD").utc() <=
              _this.activeDateRange.end &&
            moment(item.contractEndDate, "YYYY-MM-DD").utc() >=
              _this.activeDateRange.start
        );
      } else if (showActive == 3) {
        this.filterData = result.filter(
          (item) =>
            item.contractEndDate &&
            item.status == "Needs Approval" &&
            moment(item.startDateStr, "YYYY-MM-DD").utc() <=
              _this.activeDateRange.end &&
            moment(item.contractEndDate, "YYYY-MM-DD").utc() >=
              _this.activeDateRange.start
        );
      } else if (showActive == 4) {
        this.filterData = result.filter(
          (item) =>
            item.contractEndDate &&
            (item.status == "Active" || item.status == "Needs Approval") &&
            moment(item.startDateStr, "YYYY-MM-DD").utc() <=
              _this.activeDateRange.end &&
            moment(item.contractEndDate, "YYYY-MM-DD").utc() >=
              _this.activeDateRange.start
        );
      }
      this.formatedTotalVals();
    },
  },
  mounted() {
    this.getCampaignsList();
  },
  watch: {
    month(newVal, oldVal) {
      this.getCampaignsList();
      this.showClient = 1;
      this.showActive = 4;
    },
    showClient(val) {
      this.dataByFilters(val, this.showActive);
    },
    showActive(val) {
      this.dataByFilters(this.showClient, val);
    },
  },
};
</script>
