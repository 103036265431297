<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-white"
    v-if="!publicView && isAuthenticated"
  >
    <div class="container-fluid">
      <div class="navbar-brand">
        <router-link
          class="nav-link-mobile-home"
          aria-current="page"
          :to="{
            name: 'clientView',
            params: {
              clientID: currentClientId,
            },
          }"
          v-if="adminClientView"
        >
          <img
            src="@/assets/walkthruit-full-black.png"
            alt=""
            height="37"
            class="top-logo d-inline-block align-text-top"
          />
        </router-link>
        <router-link
          class="nav-link-mobile-home"
          aria-current="page"
          :to="{ name: 'home' }"
          v-else
        >
          <img
            src="@/assets/walkthruit-full-black.png"
            alt=""
            height="37"
            class="top-logo d-inline-block align-text-top"
          />
        </router-link>
      </div>

      <button
        class="navbar-toggler ms-auto"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
        v-if="!publicView && isAuthenticated"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarNavAltMarkup"
        v-if="!publicView && isAuthenticated"
      >
        <ul class="navbar-nav me-auto mb-lg-0 center-nav">
          <li class="nav-item">
            <router-link
              class="nav-link"
              aria-current="page"
              :to="{
                name: 'clientView',
                params: {
                  clientID: currentClientId,
                },
              }"
              v-if="adminClientView"
            >
              Home
            </router-link>
            <router-link
              class="nav-link"
              aria-current="page"
              :to="{ name: 'home' }"
              v-else
            >
              {{ isAdmin ? "Users" : "Home" }}
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin && !adminClientView">
            <router-link
              class="nav-link"
              aria-current="page"
              :to="{ name: 'properties' }"
            >
              Properties
            </router-link>
          </li>
          <li class="nav-item" v-if="isSuperAdmin && !adminClientView">
            <router-link
              class="nav-link"
              aria-current="page"
              :to="{ name: 'campaignsInfo' }"
            >
              Campaigns
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin && !adminClientView && !isTestAdmin">
            <router-link
              class="nav-link"
              aria-current="page"
              :to="{ name: 'trendingDeliverables' }"
            >
              Trending
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Our Products
            </a>
            <ul
              class="dropdown-menu product-list"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li v-for="(val, key) in products" :key="key">
                <a class="dropdown-item" :href="val.url" target="_blank">
                  {{ val.name }}
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              type="button"
              class="nav-link button-new-project"
              data-bs-toggle="modal"
              data-bs-target="#addProject"
            >
              New Project
            </a>
          </li>
          <li class="nav-item">
            <a
              type="button"
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#support"
            >
              Support
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-lg-0 align-items-center">
          <li class="nav-item me-4" v-if="isAdmin">
            <a
              class="nav-link back-to-admin"
              href="#"
              @click="turnOnAdminView"
              v-if="adminClientView"
            >
              Back to Admin View
            </a>
          </li>
          <li class="nav-item dropdown" v-if="isAuthenticated">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ userNameInitials }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li v-if="isAdmin">
                <router-link
                  class="dropdown-item"
                  :to="{ name: 'create_user' }"
                >
                  Create User
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'logout' }">
                  Logout
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-else>
            <a class="nav-link" aria-current="page" href="/login">Login</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <NewProjectForm :userId="userId" />
  <SupportForm :userId="userId" />
  <ContactForm :userId="userId" />
</template>

<script>
import { ref } from "vue";
import NewProjectForm from "@/components/NewProjectForm.vue";
import SupportForm from "@/components/SupportForm.vue";
import ContactForm from "@/components/ContactForm.vue";
import products from "@/utils/products.json";
import api from "@/services/BackendConnection";

export default {
  name: "TopMenu",
  setup(props) {
    return {
      publicView: ref(false),
      products: products,
      clients: ref([]),
      currentClientId: ref(null),
    };
  },
  components: { NewProjectForm, SupportForm, ContactForm },
  computed: {
    userName() {
      var userName = "";
      var user = this.$store.state.user;
      if (user && user.userName) {
        userName = user.userName;
      }
      return userName;
    },
    userId() {
      var userId = "";
      var user = this.$store.state.user;
      if (user && user.userId) {
        userId = user.userId;
      }
      return userId;
    },
    userNameInitials() {
      var userName = "";
      var user = this.$store.state.user;
      var userNameInitials = "";
      if (user && user.userName) {
        userName = user.userName;
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

        let initials = [...userName.matchAll(rgx)] || [];

        userNameInitials = (
          (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();
      }
      return userNameInitials;
    },
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
      }
      return isAdmin;
    },
    isTestAdmin() {
      var isTestAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isTestAdmin = user?.isTestAdmin ? user.isTestAdmin : false;
      }
      return isTestAdmin;
    },
    isSuperAdmin() {
      var isSuperAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isSuperAdmin = user.isSuperAdmin;
      }
      return isSuperAdmin;
    },
    isAuthenticated() {
      return this.$store.state.isUserLoggedIn;
    },
    adminClientView() {
      if (this.isAdmin) {
        var clientViwe = this.$store.state.user.adminClientView;
        if (clientViwe) {
          this.currentClientId = this.$store.state.user.currentUserId;
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getClients() {
      var _this = this;
      api()
        .listClientUsers()
        .then((res) => {
          if (res.data) {
            _this.clients = res.data;
          }
        });
    },
    turnOnAdminView() {
      if (this.isAdmin) {
        this.$store.commit("setAdminClientView", false);
        this.$store.commit("setCurrentUserId", null);
        this.currentClientId = null;
        this.$router.push({
          name: "home",
        });
      }
    },
  },
  mounted() {
    if (this.$route.name.includes("public_")) {
      this.publicView = true;
    }
    if (this.isAdmin) {
      this.getClients();
    }
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
nav
  padding: 17px 42px
  border-bottom: 1px solid $Border
  +mobile
    padding: 17px 1em
    .container-fluid
      padding: 0

  .nav-item
    color: black
    list-style-type: none
  .top-logo
    +mobile
      height: 30px

.navbar-nav
  &.center-nav
    width: 100%
    justify-content: center

    .nav-item
      margin: 0 0.5em
      +mobile
        margin: 0.5em 0
        text-align: center

      &::marker
        display: none

      .nav-link,.btn
        color: $Main
        font-size: 14px
        border: none
        font-weight: 500

        &:hover
          background-color: rgba($ButtonLightGrey, 0.5)
          border-radius: 4px

        &.router-link-active
          background-color: $ButtonLightGrey
          border-radius: 4px
          color: $Main
  .product-list
    .dropdown-item
      color: $Main
      font-size: 14px
      border: none
      font-weight: 500

#navbarDropdown
  width: 46px
  height: 46px
  background: $Main
  border-radius: 50%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  color: white
  font-weight: bold
  +mobile
    display: inline-flex
#userListDropdown
  font-size: 14px
  border: none
  font-weight: 500
  height: 46px
  align-items: center
  display: flex
.nav-item.dropdown
  +mobile
    text-align: center
    margin: 0 auto
    .dropdown-menu
      border: none
      *
        border: none
        text-align: center
</style>

