<template>
  <div
    class="resultprev linkedin-template"
    :class="{
      'mobile-preview': mobileView,
      'resultprev-carousel': adType == 3,
      'resultprev-video': adType == 1,
    }"
  >
    <div class="prev_head d-flex">
      <div class="logo">
        <img :src="logo.url" class="logo-img" v-if="logo" />
        <img src="@/assets/logo-sample.png" class="logo-img" v-else />
      </div>
      <div class="title-container">
        <span
          :class="{
            property_name: !mobileView,
            mobile_property_name: mobileView,
          }"
        >
          {{ propertyName ? propertyName : propertyNamePlaceholder }}
        </span>
        <small class="linkedin-followers">39 followers</small>
        <small class="sponsored">Promoted</small>
      </div>
      <button type="button" class="btn ellipsis">
        <img src="@/assets/linkedin-dots.png" class="linkedin-icon" />
      </button>
    </div>
    <div class="prev_text">
      <span
        :class="{
          'intro-text-mobile':
            (mobileView && adType == 1) ||
            sIAdTypes.includes(adType) ||
            sIAdTypes.includes(adType.toString()),
        }"
      >
        {{ introText ? introText : introTextPlaceholder }}
      </span>
    </div>
    <div class="prev_image px-0 m-0 pb-0">
      <span class="pb-0" v-if="adType == 1">
        <span class="pb-0 video-asset-nocontent" v-if="adVideo == null"> </span>
        <span class="pb-0" v-else>
          <video
            :src="adVideo.url"
            controls
            v-if="
              adVideo.type.includes('video') || checkType(adVideo) == 'video'
            "
            class="ad-asset-video"
          ></video>
          <img
            :src="adVideo.url"
            class="ad-asset"
            v-if="adVideo.type.includes('image') || checkType(adVideo) == 'img'"
          />
        </span>
      </span>
      <span
        class="pb-0"
        v-if="
          sIAdTypes.includes(adType) || sIAdTypes.includes(adType.toString())
        "
      >
        <span class="pb-0 ad-asset-nocontent" v-if="adImage == null"> </span>
        <span class="pb-0" v-else>
          <img
            :src="adImage.url"
            class="ad-asset"
            :class="{ 'ad-image-mobile': mobileView }"
            v-if="adImage.type.includes('image')"
          />
        </span>
      </span>
      <span v-if="adType == 3" class="prev-carousel">
        <Carousel
          :adType="adType"
          :files="carouselFiles"
          :cardInfo="cardInfo"
          :ctaType="ctaType"
          :domain="websiteUrl"
          :mobileView="mobileView"
          :currentSlide="carouseSlides[mobileView ? 1 : 0]"
          :showCarouselText="showCarouselText"
          :platform="3"
          :key="'linkedin' + componentKey"
        ></Carousel>
      </span>
    </div>
    <div
      class="prev_info d-flex"
      v-if="
        adType == 1 ||
        sIAdTypes.includes(adType) ||
        sIAdTypes.includes(adType.toString())
      "
    >
      <div
        class="content"
        :class="{
          prev_info_text_mobile: mobileView,
          prev_info_text: !mobileView,
        }"
      >
        <span class="prev_info_headline" v-if="headline">
          {{ headline }}
        </span>
        <span class="prev_info_headline" v-else>
          {{ headlinePlaceholder }}
        </span>
        <span class="prev_info_url" v-if="!videoAd">
          <span>{{ websiteUrl ? websiteUrl : urlPlaceholder }}</span>
        </span>
      </div>
      <div class="btn-cta">
        <span>
          {{ ctaType ? ctaType : "Learn More" }}
        </span>
      </div>
    </div>
    <div class="social-data">
      <div class="social-like-container">
        <span class="icon-like">
          <img src="@/assets/like-consumption-small.png" alt="" />
          <span>47</span></span
        >
      </div>
      <span class="comments">21 Comments</span>
    </div>
    <div class="linkedin-actions">
      <button class="btn linkedin-action-btn">
        <img src="@/assets/linkedin-like.png" alt="" />
        <span class="linkedin-action-btn-text">Like</span>
      </button>
      <button class="btn linkedin-action-btn">
        <img src="@/assets/linkedin-comment.png" alt="" />
        <span class="linkedin-action-btn-text">Comment</span>
      </button>
      <button class="btn linkedin-action-btn">
        <img src="@/assets/linkedin-share.png" alt="" />
        <span class="linkedin-action-btn-text">Share</span>
      </button>
      <button class="btn linkedin-action-btn" v-if="!mobileView">
        <img src="@/assets/linkedin-send.png" alt="" />
        <span class="linkedin-action-btn-text">Send</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.linkedin-template {
  -webkit-font-smoothing: subpixel-antialiased;
  --linkedin-white: #fff;
  --linkedin-black-a08: rgba(0, 0, 0, 0.08);
  --linkedin-color-border-faint: var(--linkedin-black-a08);
  --linkedin-corner-radius-medium: 8px;
  --linkedin-color-background-container: var(--linkedin-white);
  --linkedin-elevation-lined: 0 0 0 1px var(--linkedin-color-border-faint);
  --linkedin-width: 400px;
  --linkedin-mobile-width: 350px;
  --linkedin-carousel-width: 330;
  --linkedin-carousel-mobile-width: 260;
  --linkedin-video-width: 513px;
  --linkedin-video-height: 289px;
  --linkedin-video-mobile-width: 378px;
  --linkedin-video-mobile-height: 213px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Lucida Grande, Helvetica, Arial, sans-serif;
}
.resultprev {
  background: #fff;
  border: 1px solid rgb(219, 219, 219);
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  width: 95%;
  margin: 0 auto;
  .prev_head {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    padding: 12px 40px 0 16px;
    position: relative;
    .logo {
      border-radius: 6px;
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 48px;
      max-height: 100%;
      overflow: hidden;
      position: relative;
      width: 48px;
      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
    .title-container {
      flex-basis: 0;
      flex-grow: 1;
      margin-left: 8px;
      overflow: hidden;
      text-align: left;
    }
    .follow-btn {
      color: #0a66c2;
      font-size: 12px;
    }
  }
  .property_name {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mobile_property_name {
    font-size: 14px;
    color: #1d2129;
    width: 170px;
    max-height: 40px;
    overflow: hidden;
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    line-height: 18px;
    font-weight: 600;
  }
  .linkedin-followers,
  .sponsored {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ellipsis {
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 8px;
    right: 12px;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .prev_text {
    color: rgba(0, 0, 0, 0.9);
    display: block;
    line-height: 20px;
    font-size: 14px;
    margin: 0 16px 8px;
    overflow: hidden;
    position: relative;
    white-space: pre-line;
    .intro-text {
      font-size: 13px;
      word-wrap: break-word;
      white-space: -moz-pre-wrap;
      white-space: pre-wrap;
      color: var(--fds-black);
      padding: 8px 8px 0px;
    }
  }
  .prev_image {
    background: #e1e1e1;
    .video-asset-nocontent {
      display: inline-block;
    }
    .ad-asset-nocontent {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: inline-block;
    }
    .ad-asset {
      width: 100%;
    }
    .ad-asset-video {
      width: 100%;
    }
    .ad-asset.ad-image-mobile {
      overflow: hidden;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .prev_info {
    align-items: flex-start;
    background-color: #f3f6f8;
    border-top: 1px solid #e6e9ec;
    flex-grow: 1;
    justify-content: space-between;
    padding: 8px 12px;
    overflow-wrap: break-word;
    .prev_info_text_mobile {
      max-width: 250px;
      .prev_info_headline {
        max-height: 22px;
      }
    }
    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .prev_info_headline {
        color: rgba(0, 0, 0, 0.9);
        font-family: inherit !important;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .prev_info_url {
        color: rgba(0, 0, 0, 0.6);
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 8px;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
    .btn-cta {
      align-items: center;
      background-color: transparent;
      border: 0;
      border-radius: 16px;
      box-shadow: rgb(10, 102, 194) 0 0 0 1px inset;
      color: rgb(10, 102, 194);
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      font-size: 14px;
      font-weight: 600;
      min-height: 24px;
      justify-content: center;
      line-height: 20px;
      margin: 0 0 0 16px;
      padding: 2px 8px;
      text-align: center;
      max-width: 480px;
      span {
        font-weight: 600;
      }
    }
  }
  .social-data {
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 16px;
    padding: 8px 0;
    .social-like-container {
      flex-grow: 1;
      .icon-like {
        position: relative;
        top: 3px;
      }
    }
  }
  .linkedin-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    margin: 0;
    padding: 4px 12px;
    .linkedin-action-btn {
      align-items: center;
      background: none;
      background-color: transparent;
      border: 0;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      display: inline-flex;
      flex: 0 1 auto;
      font-size: 20px;
      font-weight: 600;
      justify-content: center;
      line-height: 28px;
      min-height: 32px;
      padding: 10px 8px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      max-width: 480px;
      .linkedin-action-btn-text {
        display: flex;
        flex: 1 1 auto;
        font-size: 10px;
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }
}
@media (max-width: 1272px) {
  .resultprev .linkedin-actions .linkedin-action-btn .linkedin-action-btn-text {
    font-size: 10px;
  }
}
</style>

<script>
// Tools
import { ref } from "vue";
import Carousel from "@/components/Carousel.vue";

export default {
  name: "LinkedinPreviewResult",
  setup(props) {
    return {
      carouseSlides: ref({ 0: 0, 1: 0 }),
      urlPlaceholder: ref("Destination URL here"),
      sIAdTypes: ["2", "4", "5", "6", "7", "8"],
    };
  },
  props: [
    "logo",
    "propertyName",
    "propertyNamePlaceholder",
    "introText",
    "introTextPlaceholder",
    "adType",
    "adImage",
    "adVideo",
    "files",
    "cardInfo",
    "ctaType",
    "websiteUrl",
    "headline",
    "headlinePlaceholder",
    "description",
    "mobileView",
    "componentKey",
    "showCarouselText",
  ],
  components: {
    Carousel,
  },
  computed: {
    videoAd() {
      var videoAd = false;
      if (this.files.length > 0 && this.files[0].type.includes("video")) {
        videoAd = true;
      }
      return videoAd;
    },
    carouselFiles() {
      var carouselFiles = [];
      var files = this.files;
      if (this.files.length === 0) {
        carouselFiles.push({ type: "sample" }, { type: "sample" });
      } else {
        carouselFiles = [...carouselFiles, ...files];
        if (this.files.length === 1) {
          carouselFiles.push({ type: "sample" });
        }
      }
      return carouselFiles;
    },
  },
  methods: {
    checkType(adVideo) {
      var type = false;
      if (adVideo.type.includes("stream")) {
        var fileEx = adVideo.originalname.split(".").pop().toLowerCase();
        var videoArr = ["mp4", "mov"];
        var imgArr = ["png", "jpg", "gif"];
        if (videoArr.includes(fileEx)) {
          type = "video";
        } else if (imgArr.includes(fileEx)) {
          type = "img";
        }
      }
      return type;
    },
  },
  mounted() {},
  destoyed() {},
};
</script>
