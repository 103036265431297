<template>
  <div class="row row-cols-1 row-cols-md-2">
    <div class="col-4 preview-form-container position-relative">
      <div
        class="position-absolute top-0 bottom-0 start-0 end-0"
        style="overflow-y: auto"
      >
        <div
          class="row row-cols-1 row-cols-md-2 btn-group w-100 mt-1"
          role="group"
        >
          <div class="col">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat3"
              value="3"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat3">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/carouselad.svg" />
                </div>
              </div>
              <p class="audience-title">Carousel Ad</p>
            </label>
          </div>
          <div class="col">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat1"
              value="1"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat1">
              <div
                class="audience-logo d-flex justify-content-between position-relative"
              >
                <div><img src="@/assets/videotype.svg" /></div>
              </div>
              <p class="audience-title">Video Ad</p>
            </label>
          </div>
          <div class="col">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat2"
              value="2"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat2">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - A</p>
            </label>
          </div>
          <div class="col">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat4"
              value="4"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat4">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - B</p>
            </label>
          </div>
          <div class="col" v-if="previewInfo.showExtraSAd">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat5"
              value="5"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat5">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - C</p>
            </label>
          </div>
          <div class="col" v-if="previewInfo.showExtraSAd">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat6"
              value="6"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat6">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - D</p>
            </label>
          </div>
          <div class="col" v-if="previewInfo.showExtraSAd">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat7"
              value="7"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat7">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - E</p>
            </label>
          </div>
          <div class="col" v-if="previewInfo.showExtraSAd">
            <input
              v-model="previewInfo.adType"
              type="radio"
              class="btn-check"
              id="adformat8"
              value="8"
              autocomplete="off"
            />
            <label class="btn adType-wrapper audience-wrapper" for="adformat8">
              <div class="audience-logo d-flex justify-content-between">
                <div>
                  <img src="@/assets/imagead.svg" />
                </div>
              </div>
              <p class="audience-title">Single Image Ad - F</p>
            </label>
          </div>
        </div>
        <div class="">
          <div class="mt-1" v-if="platform != 'Google Display'">
            <div class="input-group">
              <label for="pageName" class="header_label_text">Page Name</label>
            </div>
            <div class="input-group">
              <input
                type="text"
                class="form-control countdown"
                id="pageName"
                v-model="previewInfo.pageName"
                maxlength="75"
                :disabled="disabled"
                data-index="1"
              />
              <span class="input-group-text" v-if="!disabled"
                >{{ pageNameCountDown }}/75</span
              >
            </div>
          </div>
          <div class="mt-3" v-if="platform != 'Instagram'">
            <div class="input-group">
              <label for="introText" class="header_label_text">
                Introductory Text
              </label>
            </div>
            <div class="input-group">
              <textarea
                class="form-control description"
                id="introText"
                v-model="previewInfo.introText"
                maxlength="600"
                :disabled="disabled"
                data-index="2"
              />
              <span class="input-group-text description" v-if="!disabled"
                >{{ introTextCountDown }}/600</span
              >
            </div>
          </div>
          <div
            class="mt-3"
            v-if="
              previewInfo.adType != 3 ||
              (previewInfo.adType == 3 && platform == 'Instagram')
            "
          >
            <div class="input-group">
              <label for="headline" class="header_label_text">Headline</label>
            </div>
            <div class="input-group">
              <input
                type="text"
                class="form-control countdown"
                id="headline"
                v-model="previewInfo.headline"
                maxlength="50"
                :disabled="disabled"
                data-index="3"
              />
              <span class="input-group-text" v-if="!disabled"
                >{{ headlineCountDown }}/50</span
              >
            </div>
          </div>
          <!-- <div class="mt-3" v-if="previewInfo.adType != 3">
        <div class="input-group">
          <label for="description" class="header_label_text">Description</label>
        </div>
        <div class="input-group">
          <textarea
            class="form-control description"
            maxlength="500"
            id="description"
            v-model="previewInfo.description"
            :disabled="disabled"
            data-index="4"
          />
          <span class="input-group-text description" v-if="!disabled"
            >{{ descriptionCountDown }}/500</span
          >
        </div>
      </div> -->
          <div class="mt-3">
            <div class="input-group">
              <label for="websiteUrl" class="header_label_text"
                >Destination URL</label
              >
            </div>
            <input
              v-model="previewInfo.websiteUrl"
              type="url"
              class="form-control"
              id="websiteUrl"
              :disabled="disabled"
              :data-index="previewInfo.adType == 3 ? '3' : '5'"
            />
            <div
              class="form-check"
              v-if="previewInfo.adType == 3 && platform == 'Facebook'"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="previewInfo.sameUrl"
                @change="updateUrl"
                :disabled="disabled"
              />
              <label class="form-check-label" for="isAdmin">
                Use Destination URL as destination for all cards
              </label>
            </div>
          </div>
          <!-- <div class="mt-3" v-if="platform != 'Google Display'">
        <div class="input-group">
          <p class="header_label_text">Call-To-Action Button</p>
        </div>
        <Select
          v-model="previewInfo.ctaType"
          :options="ctaTypeList"
          :disable="disabled"
        />
      </div> -->
          <div class="mt-3">
            <p class="header_label_text">Logo Icon</p>
            <div class="input-group" :class="{ disabled: disabled }">
              <div
                class="input-group-text border-end-0 add-image"
                @click="$refs.favicon.click()"
              >
                <img src="@/assets/add-image.svg" />
                <input
                  type="file"
                  ref="favicon"
                  accept="image/png, image/jpg"
                  class="d-none"
                  @change="onLogoChange"
                  :disabled="disabled"
                />
              </div>
              <div class="form-control border-start-0 image-uploader-container">
                <span v-if="previewInfo.logo" class="active-image">
                  {{
                    previewInfo.logo.file
                      ? previewInfo.logo.file.name
                      : previewInfo.logo.originalname
                  }}
                </span>
                <span
                  v-if="previewInfo.logo && !disabled"
                  class="active-image-remove"
                  @click="removeFavicon()"
                ></span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <p v-if="previewInfo.adType == 1" class="header_label_text">
              Video
            </p>
            <p
              v-if="
                sIAdTypes.includes(previewInfo.adType) ||
                sIAdTypes.includes(previewInfo.adType.toString())
              "
              class="header_label_text"
            >
              Single Image
            </p>
            <p v-if="previewInfo.adType == 3" class="header_label_text">
              Video or Images(s)
            </p>
            <div v-if="previewInfo.adType == 1">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="previewInfo.adVideo ? [previewInfo.adVideo] : []"
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 2">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="previewInfo.adImage ? [previewInfo.adImage] : []"
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 4">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="
                  previewInfo.adImage2 ? [previewInfo.adImage2] : []
                "
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 5">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="
                  previewInfo.adImage3 ? [previewInfo.adImage3] : []
                "
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 6">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="
                  previewInfo.adImage4 ? [previewInfo.adImage4] : []
                "
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 7">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="
                  previewInfo.adImage5 ? [previewInfo.adImage5] : []
                "
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else-if="previewInfo.adType == 8">
              <ImageUploader
                :adType="previewInfo.adType"
                @update-files="updateFiles"
                @remove-file="removeFile"
                :parentFiles="
                  previewInfo.adImage6 ? [previewInfo.adImage6] : []
                "
                :disabled="disabled"
              >
              </ImageUploader>
            </div>
            <div v-else class="container-fluid">
              <button
                type="button"
                class="btn btn-secondary"
                @click="addCard"
                :disabled="cardNum == 10 || disabled"
              >
                <font-awesome-icon icon="plus" /> Add Card
              </button>
              <div v-if="previewInfo.showCarouselText">
                <div v-for="card in cardNum" :key="card" class="my-2">
                  <div
                    :class="'row ' + (card > 2 ? 'linkedincollapse' : '')"
                    :id="'linkedincollapse' + card"
                  >
                    <div class="col col-lg-4">
                      <ImageUploader
                        :adType="previewInfo.adType"
                        @update-files="updateFiles"
                        @remove-file="removeFile"
                        :parentFiles="previewInfo.files"
                        :cardId="card - 1"
                        :disabled="disabled"
                      >
                      </ImageUploader>
                    </div>
                    <div class="col">
                      <div class="mt-2" v-if="platform != 'Instagram'">
                        <label
                          :for="'cardHeadline-' + card"
                          class="card_label_text"
                        >
                          Card Headline
                        </label>
                        <div class="input-group">
                          <input
                            v-model="previewInfo.cardInfo[card - 1].headline"
                            class="form-control countdown cardHeadline"
                            :id="'cardHeadline-' + card"
                            type="text"
                            maxlength="30"
                            :disabled="disabled"
                          />
                          <span class="input-group-text" v-if="!disabled"
                            >{{ cardHeadlineCountDown[card - 1] }}/30</span
                          >
                        </div>
                      </div>
                      <!-- <div v-if="platform == 'Facebook'">
                        <label
                          :for="'cardDescription-' + card"
                          class="card_label_text"
                          >Card Description</label
                        >
                        <div class="input-group">
                          <input
                            v-model="previewInfo.cardInfo[card - 1].description"
                            class="form-control countdown cardDescription"
                            :id="'cardDescription-' + card"
                            type="text"
                            maxlength="80"
                            :disabled="disabled"
                          />
                          <span class="input-group-text" v-if="!disabled"
                            >{{ cardDescriptionCountDown[card - 1] }}/80</span
                          >
                        </div>
                      </div>
                      <div v-if="platform == 'Facebook'">
                        <label class="card_label_text"
                          >Card Destination URL</label
                        >
                        <Input
                          v-model="previewInfo.cardInfo[card - 1].url"
                          type="url"
                          :disable="previewInfo.sameUrl || disabled"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col col-lg-4" v-for="card in cardNum" :key="card">
                  <ImageUploader
                    :adType="previewInfo.adType"
                    @update-files="updateFiles"
                    @remove-file="removeFile"
                    :parentFiles="previewInfo.files"
                    :cardId="card - 1"
                    :disabled="disabled"
                  >
                  </ImageUploader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div id="prev-result-container">
        <p class="header_label_text">Preview</p>
        <PreviewResults
          :previewInfo="previewInfo"
          v-model="platform"
          :adType="previewInfo.adType"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"

.card_label_text
  font-weight: 700
  font-size: 14px
  line-height: 20px
  color: #06152b
  margin-bottom: 0
  +mobile
    font-size: 14px
</style>

<script>
import { computed, ref } from "vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import PreviewResults from "@/components/PreviewResults.vue";

// File Management
import useFileList from "@/compositions/file-list";
import campaignData from "@/utils/campaignData.json";

import $ from "jquery";

export default {
  name: "Preview",
  props: ["modelValue", "disabled"],
  setup(props, { emit }) {
    // File Management
    const { addLogo } = useFileList();
    const previewInfo = computed({
      get: () => {
        if (props.modelValue) {
          return props.modelValue;
        } else {
          return "";
        }
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      previewInfo,
      ctaTypeList: campaignData.campaign.call_to_action_type,
      platform: ref("LinkedIn"),
      sIAdTypes: ["2", "4", "5", "6", "7", "8"],
    };
  },
  components: {
    Input,
    Select,
    ImageUploader,
    PreviewResults,
  },
  computed: {
    adType() {
      return this.previewInfo.adType;
    },
    cardNum() {
      return this.previewInfo.cardInfo.length;
    },
    pageNameCountDown: {
      get() {
        var count = 75;
        if (this.previewInfo.pageName) {
          count -= this.previewInfo.pageName.length;
        }
        return count;
      },
      set(newValue) {
        return newValue;
      },
    },
    introTextCountDown: {
      get() {
        var count = 600;
        if (this.previewInfo.introText) {
          count -= this.previewInfo.introText.length;
        }
        return count;
      },
      set(newValue) {
        return newValue;
      },
    },
    headlineCountDown: {
      get() {
        var count = 50;
        if (this.previewInfo.headline) {
          count -= this.previewInfo.headline.length;
        }
        return count;
      },
      set(newValue) {
        return newValue;
      },
    },
    descriptionCountDown: {
      get() {
        var count = 500;
        if (this.previewInfo.description) {
          count -= this.previewInfo.description.length;
        }
        return count;
      },
      set(newValue) {
        return newValue;
      },
    },
    cardHeadlineCountDown: {
      get() {
        var counts = [];
        for (var i = 0; i < this.cardNum; i++) {
          counts.push(30);
          if (this.previewInfo.cardInfo[i].headline) {
            counts[i] -= this.previewInfo.cardInfo[i].headline.length;
          }
        }
        return counts;
      },
      set(newValue) {
        return newValue;
      },
    },
    cardDescriptionCountDown: {
      get() {
        var counts = [];
        for (var i = 0; i < this.cardNum; i++) {
          counts.push(80);
          if (this.previewInfo.cardInfo[i].description) {
            counts[i] -= this.previewInfo.cardInfo[i].description.length;
          }
        }
        return counts;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    onLogoChange(e) {
      var file = e.target.files[0];
      if (file.size > 0) {
        this.previewInfo.logo = useFileList().addLogo(file);
      } else {
        if (typeof window !== undefined) {
          import("bootstrap").then((bootstrap) => {
            new bootstrap.Modal(document.getElementById("uploadError")).show();
          });
        }
      }
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    updateFiles(value) {
      if (this.previewInfo.adType == 1) {
        this.previewInfo.adVideo = value.files[0];
      } else if (this.previewInfo.adType == 2) {
        this.previewInfo.adImage = value.files[0];
      } else if (this.previewInfo.adType == 4) {
        this.previewInfo.adImage2 = value.files[0];
      } else if (this.previewInfo.adType == 5) {
        this.previewInfo.adImage3 = value.files[0];
      } else if (this.previewInfo.adType == 6) {
        this.previewInfo.adImage4 = value.files[0];
      } else if (this.previewInfo.adType == 7) {
        this.previewInfo.adImage5 = value.files[0];
      } else if (this.previewInfo.adType == 8) {
        this.previewInfo.adImage6 = value.files[0];
      } else {
        var files = this.previewInfo.files;
        if (value.cardId != undefined) {
          if (!this.previewInfo.files[value.cardId])
            this.previewInfo.files = [...files, ...value.files];
        }
      }
    },
    removeFile(value) {
      if (this.previewInfo.adType == 1) {
        this.previewInfo.adVideo = null;
      } else if (this.previewInfo.adType == 2) {
        this.previewInfo.adImage = null;
      } else if (this.previewInfo.adType == 4) {
        this.previewInfo.adImage2 = null;
      } else if (this.previewInfo.adType == 5) {
        this.previewInfo.adImage3 = null;
      } else if (this.previewInfo.adType == 6) {
        this.previewInfo.adImage4 = null;
      } else if (this.previewInfo.adType == 7) {
        this.previewInfo.adImage5 = null;
      } else if (this.previewInfo.adType == 8) {
        this.previewInfo.adImage6 = null;
      } else {
        this.previewInfo.files = value.files;
        if (this.cardNum > 5) {
          this.previewInfo.cardInfo.splice(value.cardId, 1);
          this.cardHeadlineCountDown[-1] = 30;
          this.cardDescriptionCountDown[-1] = 80;
        }
      }
    },
    addCard(e) {
      e.preventDefault();
      if (this.cardNum < 15) {
        this.previewInfo.cardInfo.push({
          headline: null,
          description: null,
          url: null,
        });
        this.cardHeadlineCountDown.push(30);
        this.cardDescriptionCountDown.push(80);
      }
    },
    adformatSwitch() {
      this.previewInfo.files = [];
    },
    removeFavicon() {
      this.previewInfo.logo = null;
    },
  },
  mounted() {
    var _this = this;
    $(document).ready(function () {
      $("#pageName").keyup(function () {
        _this.pageNameCountDown -= $(this).val().length;
      });
      $("#introText").keyup(function () {
        _this.introTextCountDown -= $(this).val().length;
      });
      $("#headline").keyup(function () {
        _this.headlineCountDown -= $(this).val().length;
      });
      $("#description").keyup(function () {
        _this.descriptionCountDown -= $(this).val().length;
      });
    });
    $(document).on("keyup", ".cardHeadline", function () {
      var id = $(this).attr("id");
      var cardId = id.split("-")[1];
      _this.cardHeadlineCountDown[cardId - 1] -= $(this).val().length;
    });
    $(document).on("keyup", ".cardDescription", function () {
      var id = $(this).attr("id");
      var cardId = id.split("-")[1];
      _this.cardDescriptionCountDown[cardId - 1] -= $(this).val().length;
    });
    $("#campaignform").on("keydown", "input", function (event) {
      if (event.which == 13) {
        event.preventDefault();
        var $this = $(event.target);
        var index = parseFloat($this.attr("data-index"));
        $('[data-index="' + (index + 1).toString() + '"]').focus();
      }
    });
  },
};
</script>
