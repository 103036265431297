<template>
  <div class="d-flex justify-content-center my-4">
    <form class="form-app" @submit.prevent="onSubmit">
      <div class="alert alert-info" v-if="notice">{{ notice }}</div>
      <div class="alert alert-danger" v-if="error">{{ error }}</div>
      <Input v-model="userName" type="text" label="User Name" />
      <Input v-model="email" type="email" label="Email address" />
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="isAdmin"
          v-model="isAdmin"
        />
        <label class="form-check-label" for="isAdmin"> Admin? </label>
      </div> -->
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="isClient"
          v-model="isClient"
        />
        <label class="form-check-label" for="isClient"> Client? </label>
      </div>
      <button type="submit" class="btn btn-primary wti-btn-black mt-4">
        <span
          v-if="submitting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ submitting ? "" : "Submit" }}
      </button>
    </form>
  </div>
</template>

<style lang="scss">
@media (min-width: 1024px) {
}
</style>

<script>
import api from "@/services/BackendConnection";
import Input from "@/components/Input.vue";

export default {
  name: "CreateUserView",
  data() {
    return {
      userName: null,
      email: null,
      isAdmin: false,
      isClient: false,
      notice: null,
      error: null,
      submitting: false,
    };
  },
  components: {
    Input,
  },
  methods: {
    onSubmit(e) {
      var _this = this;
      e.preventDefault();
      this.submitting = true;
      var user = {
        userName: _this.userName,
        email: _this.email,
        isAdmin: _this.isAdmin,
        isClient: _this.isClient,
      };
      api()
        .createUser(user)
        .then((res) => {
          if (res.data.success) {
            _this.notice = res.data.data;
          } else {
            _this.error = res.data.data;
          }
          this.submitting = false;
        });
    },
  },
  mounted() {},
};
</script>
