import { ref } from 'vue'

export default function () {

    function addLogo(newFile) {
        let newUploadableFile = new UploadableFile(newFile)
        return newUploadableFile
    }

    function addFiles(newFiles, files) {
        let newUploadableFiles = [...newFiles].map((file) => {
            file.rows = 0;
            return new UploadableFile(file);
        });

        return files.concat(newUploadableFiles)
    }

    return { addLogo, addFiles }
}

class UploadableFile {
    constructor(file) {
        this.file = file
        this.type = file.type
        this.rows = file.rows
        this.id = `${this.cleanString(file.name)}`
        this.url = URL.createObjectURL(file)
        this.status = null
    }

    cleanString(input) {
        const lastDotIndex = input.lastIndexOf('.');
        const namePart = input.substring(0, lastDotIndex);
        const extensionPart = input.substring(lastDotIndex);
        const cleanedNamePart = namePart.replace(/[^a-zA-Z0-9.]/g, '');
        const finalNamePart = cleanedNamePart.replace(/\./g, '');
        return finalNamePart + extensionPart;
    }
}
