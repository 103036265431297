<template>
  <div class="form-group mb-3">
    <label for="selectionItem" class="form-label" v-if="label">{{
      label
    }}</label>
    <select
      id="selectionItem"
      class="form-select form-control"
      v-model="selectModel"
      :disabled="disable"
      :multiple="multiple ? multiple : false"
      :required="required"
    >
      <option v-for="(option, index) in options" :key="index" :value="option">
        {{ option }}
      </option>
    </select>
    <div class="invalid-feedback">{{ errorMsg }}</div>
  </div>
</template>

<script>
import { computed } from "vue";
import tooltips from "@/utils/tooltips.json";

export default {
  name: "Select",
  props: ["label", "options", "modelValue", "disable", "multiple", "required"],
  setup(props, { emit }) {
    const selectModel = computed({
      get: () => {
        if (props.modelValue) {
          return props.modelValue;
        } else {
          return "";
        }
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      selectModel,
      errorMsg: tooltips.requiredErr,
    };
  },
  mounted() {},
};
</script>
