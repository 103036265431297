<template>
  <div class="prev-div" :class="{ 'report-prev': reportView }">
    <div class="container prev-select">
      <div class="row py-3">
        <div class="col">
          <select class="form-select" v-model="platform">
            <option v-for="(item, index) in platforms" :key="index">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col">
          <select class="form-select" v-model="deviceType">
            <option v-for="(item, index) in devices" :key="index">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="tab-content prev-container py-3">
      <div
        class="tab-pane fade"
        :class="{ active: isFacebookActive, show: isFacebookActive }"
        id="facebook"
        role="tabpanel"
        aria-labelledby="facebook-tab"
      >
        <FacebookPreviewResult
          :logo="previewInfo.logo"
          :propertyName="previewInfo.pageName"
          :propertyNamePlaceholder="propertyNamePlaceholder"
          :primaryText="previewInfo.introText"
          :headline="previewInfo.headline"
          :headlinePlaceholder="headlinePlaceholder"
          :description="previewInfo.description"
          :showCarouselText="previewInfo.showCarouselText"
          :primaryTextPlaceholder="introTextPlaceholder"
          :adType="adType"
          :adImage="
            adType == 2
              ? previewInfo.adImage
              : adType == 4
              ? previewInfo.adImage2
              : adType == 5
              ? previewInfo.adImage3
              : adType == 6
              ? previewInfo.adImage4
              : adType == 7
              ? previewInfo.adImage5
              : adType == 8
              ? previewInfo.adImage6
              : null
          "
          :adVideo="previewInfo.adVideo"
          :files="previewInfo.files"
          :cardInfo="previewInfo.cardInfo"
          :ctaType="previewInfo.ctaType"
          :websiteUrl="previewInfo.websiteUrl"
          :mobileView="deviceType == 'Mobile'"
          :componentKey="componentKey + viewKey"
          :key="componentKey + viewKey"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ active: isInsActive, show: isInsActive }"
        id="instagram"
        role="tabpanel"
        aria-labelledby="instagram-tab"
      >
        <InstagramPreviewResult
          :logo="previewInfo.logo"
          :propertyName="previewInfo.pageName"
          :propertyNamePlaceholder="propertyNamePlaceholder"
          :primaryText="previewInfo.introText"
          :primaryTextPlaceholder="introTextPlaceholder"
          :headline="previewInfo.headline"
          :headlinePlaceholder="headlinePlaceholder"
          :adType="adType"
          :adImage="
            adType == 2
              ? previewInfo.adImage
              : adType == 4
              ? previewInfo.adImage2
              : adType == 5
              ? previewInfo.adImage3
              : adType == 6
              ? previewInfo.adImage4
              : adType == 7
              ? previewInfo.adImage5
              : adType == 8
              ? previewInfo.adImage6
              : null
          "
          :adVideo="previewInfo.adVideo"
          :files="previewInfo.files"
          :cardInfo="previewInfo.cardInfo"
          :ctaType="previewInfo.ctaType"
          :websiteUrl="previewInfo.websiteUrl"
          :mobileView="deviceType == 'Mobile'"
          :componentKey="componentKey + viewKey"
          :key="componentKey + viewKey"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ active: isLinkedinActive, show: isLinkedinActive }"
        id="linkedin"
        role="tabpanel"
        aria-labelledby="linkedin-tab"
      >
        <LinkedinPreviewResult
          :logo="previewInfo.logo"
          :propertyName="previewInfo.pageName"
          :propertyNamePlaceholder="propertyNamePlaceholder"
          :adType="adType"
          :introText="previewInfo.introText"
          :introTextPlaceholder="introTextPlaceholder"
          :showCarouselText="previewInfo.showCarouselText"
          :adImage="
            adType == 2
              ? previewInfo.adImage
              : adType == 4
              ? previewInfo.adImage2
              : adType == 5
              ? previewInfo.adImage3
              : adType == 6
              ? previewInfo.adImage4
              : adType == 7
              ? previewInfo.adImage5
              : adType == 8
              ? previewInfo.adImage6
              : null
          "
          :adVideo="previewInfo.adVideo"
          :files="previewInfo.files"
          :websiteUrl="previewInfo.websiteUrl"
          :headline="previewInfo.headline"
          :headlinePlaceholder="headlinePlaceholder"
          :ctaType="previewInfo.ctaType"
          :mobileView="deviceType == 'Mobile'"
          :cardInfo="previewInfo.cardInfo"
          :componentKey="componentKey + viewKey"
          :key="componentKey + viewKey"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ active: isDisplayActive, show: isDisplayActive }"
        id="display"
        role="tabpanel"
        aria-labelledby="display-tab"
      >
        <GoogleDisplayPreviewResult
          :logo="previewInfo.logo"
          :introText="previewInfo.introText"
          :introTextPlaceholder="introTextPlaceholder"
          :adImage="
            adType == 2
              ? previewInfo.adImage
              : adType == 4
              ? previewInfo.adImage2
              : adType == 5
              ? previewInfo.adImage3
              : adType == 6
              ? previewInfo.adImage4
              : adType == 7
              ? previewInfo.adImage5
              : adType == 8
              ? previewInfo.adImage6
              : null
          "
          :headline="previewInfo.headline"
          :headlinePlaceholder="headlinePlaceholder"
          :mobileView="deviceType == 'Mobile'"
          :componentKey="componentKey"
          :key="componentKey + viewKey"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "@/assets/vars.sass"
.required
  color: red
  font-weight: 700

.header_label_text
  font-weight: 700
  font-size: 16px
  line-height: 32px
  color: #06152b
  margin-bottom: 0
  +mobile
    font-size: 14px

p.info_label_text
  font-weight: 300
  font-size: 12px
  line-height: 24px
  color: #06152b
  margin-bottom: 0

.prev-div
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  border: 1px solid #d9e1e7
  border-radius: 10px

  &.report-prev
    border: none
    .prev-select
      padding: 0

.prev-container
  background-color: #eef1f9
  border-radius: 10px
</style>

<script>
import { computed, ref } from "vue";
import FacebookPreviewResult from "./FacebookPreviewResult.vue";
import InstagramPreviewResult from "@/components/InstagramPreviewResult.vue";
import LinkedinPreviewResult from "@/components/LinkedinPreviewResult.vue";
import GoogleDisplayPreviewResult from "@/components/GoogleDisplayPreviewResult.vue";

export default {
  name: "PreviewResults",
  setup(props, { emit }) {
    const platform = computed({
      get: () => {
        if (props.modelValue) {
          return props.modelValue;
        } else {
          return "LinkedIn";
        }
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      devices: ["Desktop", "Mobile"],
      platform,
      deviceType: ref("Desktop"),
      isFacebookActive: ref(false),
      isInsActive: ref(false),
      isLinkedinActive: ref(true),
      isDisplayActive: ref(false),
      componentKey: ref(0),
      propertyNamePlaceholder: "Page Name Here",
      introTextPlaceholder: "Introductory text goes here",
      viewKey: ref(0),
      headlinePlaceholder: ref("Headline text goes here"),
      sIAdTypes: ["2", "4", "5", "6", "7", "8"],
    };
  },
  components: {
    FacebookPreviewResult,
    InstagramPreviewResult,
    LinkedinPreviewResult,
    GoogleDisplayPreviewResult,
  },
  props: ["previewInfo", "modelValue", "adType", "reportView"],
  computed: {
    platforms() {
      if (
        this.sIAdTypes.includes(this.adType) ||
        this.sIAdTypes.includes(this.adType.toString())
      ) {
        return ["LinkedIn", "Facebook", "Instagram", "Google Display"];
      } else {
        return ["LinkedIn", "Facebook", "Instagram"];
      }
    },
  },
  methods: {
    updateTab(val) {
      this.componentKey += 1;
      switch (val) {
        case "Facebook":
          this.isInsActive = false;
          this.isLinkedinActive = false;
          this.isFacebookActive = true;
          this.isDisplayActive = false;
          break;
        case "Instagram":
          this.isFacebookActive = false;
          this.isLinkedinActive = false;
          this.isInsActive = true;
          this.isDisplayActive = false;
          break;

        case "LinkedIn":
          this.isFacebookActive = false;
          this.isInsActive = false;
          this.isLinkedinActive = true;
          this.isDisplayActive = false;
          break;
        case "Google Display":
          this.isFacebookActive = false;
          this.isInsActive = false;
          this.isLinkedinActive = false;
          this.isDisplayActive = true;
          break;
      }
    },
  },
  mounted() {},
  watch: {
    platform(val) {
      this.updateTab(val);
    },
    deviceType() {
      this.viewKey++;
    },
  },
};
</script>
