<template>
  <div class="container" v-if="data.length > 0">
    <div class="row">
      <div class="p-3">
        <h3>Twitter campaign</h3>
      </div>
    </div>
    <table class="table table-color table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Campaign Name</th>
          <th scope="col">Objective</th>
          <th scope="col">Created At</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody class="tbody-color">
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <a :href="adManagerUrl(item.campaignId)" target="_blank">
              {{ item.campaignName }}</a
            >
          </td>
          <td>{{ item.objective }}</td>
          <td>{{ item.createdAt }}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary me-4"
              @click="updateCampaign(item)"
              data-bs-toggle="modal"
              data-bs-target="#editTwitterCampaign"
            >
              <font-awesome-icon :icon="['fas', 'edit']" />
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="updateCampaign(item)"
              data-bs-toggle="modal"
              data-bs-target="#deleteTwitterCampaign"
            >
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Modal id="editTwitterCampaign">
    <template v-slot:title>
      Edit Twitter Campaign Group {{ item.campaignName }}
    </template>
    <template v-slot:body>
      <form @submit.prevent="editCampaign">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Group Name"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Update
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="deleteTwitterCampaign">
    <template v-slot:title>
      Are you sure to delete this Twitter Campaign Group?
    </template>
    <template v-slot:body>
      <form @submit.prevent="deleteCampaign">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Group Name"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Delete
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import campaignData from "@/utils/campaignData.json";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "TwitterCampaignTable",
  setup(props) {
    return {
      status: campaignData.campaign.twitter.campaignStatus,
      item: ref({
        campaignId: null,
        campaignName: null,
        objective: null,
        status: null,
      }),
      submitting: ref(false),
    };
  },
  props: ["data"],
  components: {
    Modal,
    Input,
    Select,
  },
  methods: {
    updateCampaign(item) {
      this.item = item;
    },
    editCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
        name: this.item.campaignName,
      };
      api()
        .editTwitterCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("editTwitterCampaignclose");
          }
        });
    },
    deleteCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
      };
      api()
        .deleteTwitterCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("deleteTwitterCampaignclose");
          }
        });
    },
    clear() {
      this.item.campaignId = null;
      this.item.campaignName = null;
      this.item.status = null;
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    adManagerUrl(id) {
      var twitterAccountId = import.meta.env.VITE_TWITTER_ACCOUNT_ID;
      var campaignId = parseInt(id, 36).toString(10);
      return (
        "https://ads.twitter.com/reporting_dashboard/" +
        twitterAccountId +
        "/adgroups?selected=%7B%22campaigns%22%3A%5B%22" +
        campaignId +
        "%22%5D%7D"
      );
    },
  },
  mounted() {
    var myModals = document.querySelectorAll(".modal");
    var _this = this;
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
