<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white nav-public">
    <div class="container">
      <div class="navbar-brand">
        <router-link
          class="nav-link-mobile-home"
          aria-current="page"
          :to="{ name: 'home' }"
        >
          <img
            src="@/assets/walkthruit-full-black.png"
            alt=""
            height="37"
            class="d-inline-block align-text-top"
          />
        </router-link>
      </div>
    </div>
  </nav>
  <div class="inner-dashboard">
    <div class="inner-dashboard-content container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4">
          <PropertyDashboardContentHeading
            v-if="propertyId"
            :propertyId="propertyId"
            :showUploadContentButton="false"
            :token="token"
          />
        </div>
      </div>

      <WalkthruitCampaignForm
        v-if="campaignId"
        :propertyId="propertyId"
        :campaignId="campaignId"
        :isAdmin="isAdmin"
        :viewOnly="viewOnly"
        :token="token"
        :publicView="publicView"
      />
    </div>
  </div>
</template>


<style lang="sass">
@import "@/assets/vars.sass"
nav.nav-public
  padding: 17px 42px
  border-bottom: 1px solid $Border
  +mobile
    border-bottom: 1px solid #EEEEEE
    padding: 12px 0
    .navbar-brand
      img
        height: 30px
</style>
 
<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";

// Components
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import WalkthruitCampaignForm from "@/components/WalkthruitCampaignForm.vue";

export default {
  name: "PublicWalkthruitCampaignFormView",
  setup(props) {
    return {
      propertyId: ref(null),
      campaignId: ref(null),
      isAdmin: ref(false),
      viewOnly: ref(true),
      publicView: ref(true),
      token: ref(null),
    };
  },
  components: {
    PropertyDashboardContentHeading,
    WalkthruitCampaignForm,
  },
  computed: {},
  methods: {
    getCampaignByToken() {
      var _this = this;
      var info = { token: this.token };
      api()
        .getCampaignByToken(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.propertyId = res.data.propertyId;
            _this.campaignId = res.data.campaignId;
          }
        });
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.getCampaignByToken();
  },
};
</script>

