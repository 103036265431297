<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container campaign-container">
      <div class="campaign-content">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="p-3">
                <h3>Campaign/Campaign Group List</h3>
              </div>
            </div>
            <div class="col">
              <div class="p-3 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#addCampaign"
                  v-if="!isLoading"
                >
                  <font-awesome-icon :icon="['fas', 'plus-square']" />
                </button>
              </div>
            </div>
          </div>
          <div class="paltform-campaign-container position-relative">
            <loading
              v-model:active="isLoading"
              :is-full-page="false"
              loader="dots"
              :opacity="1"
            />
            <FacebookCampaignTable
              v-if="facebookData"
              :data="facebookData"
              platform="facebook"
            />
            <!-- <FacebookCampaignTable
              v-if="instagramData"
              :data="instagramData"
              platform="instagram"
            /> -->
            <GoogleCampaignTable v-if="googleData" :data="googleData" />
            <LinkedinCampaignGroupTable
              v-if="linkedinData"
              :data="linkedinData"
              :propertyId="property._id"
            />
            <TwitterCampaignTable v-if="twitterData" :data="twitterData" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal id="addCampaign" :notice="notice" :error="error">
    <template v-slot:title>
      Create a new campaign
      {{ platform === platformList[0] ? "group" : "" }}
    </template>
    <template v-slot:body>
      <form @submit.prevent="addCampaign" class="needs-validation" novalidate>
        <Select
          v-model="platform"
          label="Platform to create the new campaign"
          :options="platformList"
          :required="true"
        />
        <div v-if="platform != null">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="campaignType1"
              id="campaignType1"
              value="1"
              v-model="item.campaignType"
            />
            <label class="form-check-label" for="campaignType1">
              Broker campaign only
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="campaignType2"
              id="campaignType2"
              value="2"
              v-model="item.campaignType"
            />
            <label class="form-check-label" for="campaignType2">
              Tenant campaign only
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="campaignType3"
              id="campaignType3"
              value="3"
              v-model="item.campaignType"
            />
            <label class="form-check-label" for="campaignType3">
              Both broker campaign and tenant campaign
            </label>
          </div>
        </div>
        <Select
          v-model="item.objective"
          label="Campaign Objective"
          :options="facebookObjective"
          v-if="platform === platformList[1]"
          :required="true"
        />
        <Select
          v-model="item.objective"
          label="Campaign Objective"
          :options="twitterObjective"
          v-else-if="platform === platformList[3]"
          :required="true"
        />
        <div
          class="form-group custom-field"
          v-if="platform === platformList[3]"
        >
          <Select
            v-model="item.bidStrategy"
            label="Bid Strategy"
            v-if="item.objective"
            :options="twitterBidStrategy[item.objective]"
            :required="true"
          />
          <div class="custom-field-wrapper">
            <label>Start Date</label>
            <input
              type="date"
              class="form-control"
              v-model="item.startDate"
              :min="todayDate"
              :required="true"
            />
          </div>
        </div>
        <div v-if="platform === platformList[2]">
          <div class="form-group mb-3">
            <label for="googleClientAccount" class="form-label">
              Please choose the Google client account<span
                v-if="item.advertising_channel_type === 'VIDEO'"
              >
                (which the campaign belongs)
              </span>
            </label>
            <select
              class="form-select"
              v-model="item.googleClientAccount"
              id="googleClientAccount"
              :required="true"
            >
              <option
                v-for="(item, index) in googleAccounts"
                :key="index"
                :value="item"
              >
                {{ item.descriptive_name }}
              </option>
            </select>
          </div>
          <Select
            v-model="item.advertising_channel_type"
            label="Advertising Channel Type"
            :options="advertisingChannelType"
            :required="true"
          />
          <Select
            v-model="item.bidding_strategy_type"
            label="Bidding Strategy Type"
            :options="biddingStrategyType[item.advertising_channel_type]"
            v-if="
              item.advertising_channel_type &&
              item.advertising_channel_type !== 'VIDEO'
            "
            :required="true"
          />
          <Input
            v-model="item.campaignId"
            type="text"
            label="Please create the video campaign through ad manager UI and enter the campaign ID here"
            v-if="item.advertising_channel_type === 'VIDEO'"
            :required="true"
          />
        </div>
        <Input
          v-model="item.name"
          type="text"
          :label="
            'Campaign' +
            (platform === platformList[0] ? ' group' : '') +
            ' Name'
          "
          v-if="platform"
          :required="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Add" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<style lang="sass">
.paltform-campaign-container
  min-height: 200px
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import FacebookCampaignTable from "@/components/FacebookCampaignTable.vue";
import GoogleCampaignTable from "@/components/GoogleCampaignTable.vue";
import LinkedinCampaignGroupTable from "@/components/LinkedinCampaignGroupTable.vue";
import TwitterCampaignTable from "@/components/TwitterCampaignTable.vue";
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import campaignData from "@/utils/campaignData.json";
import moment from "moment";

export default {
  name: "CampaignView",
  setup() {
    var today = moment().format("YYYY-MM-DD");
    return {
      facebookData: ref(null),
      instagramData: ref(null),
      googleData: ref(null),
      linkedinData: ref(null),
      twitterData: ref(null),
      googleAccounts: ref([]),
      todayDate: today,
      platformList: ["LinkedIn", "Facebook", "Google", "Twitter"],
      facebookObjective: campaignData.campaign.facebook.objective,
      twitterObjective: campaignData.campaign.twitter.objective,
      twitterBidStrategy: campaignData.campaign.twitter.bid_strategy,
      advertisingChannelType:
        campaignData.campaign.google.advertisingChannelType,
      biddingStrategyType: campaignData.campaign.google.biddingStrategyType,
      platform: ref(null),
      notice: ref(null),
      error: ref(null),
      item: ref({
        name: null,
        objective: null,
        advertising_channel_type: null,
        bidding_strategy_type: null,
        googleClientAccount: null,
        campaignId: null,
        campaignType: "1",
        startDate: today,
        bidStrategy: null,
      }),
      property: ref({
        _id: null,
        name: null,
      }),
      isLoading: ref(true),
      submitting: ref(false),
    };
  },
  props: ["propertyId", "campaignId"],
  components: {
    PropertyDashboardSidebarNav,
    FacebookCampaignTable,
    GoogleCampaignTable,
    LinkedinCampaignGroupTable,
    TwitterCampaignTable,
    Modal,
    Input,
    Select,
  },
  computed: {},
  methods: {
    async facebookCampaign() {
      var info = {
        campaignId: this.campaignId,
      };
      let res = await api().facebookCampaigns(info);
      this.facebookData = res.data.facebook;
    },
    async googleCampaign() {
      var info = {
        campaignId: this.campaignId,
      };
      if (
        this.$store.state.user &&
        this.$store.state.user?.isAdmin &&
        this.$store.state.user.isAdmin
      ) {
        info["accounts"] = this.googleAccounts;
      }
      let res = await api().googleCampaigns(info);
      if (res.data) {
        this.googleData = res.data;
      }
    },
    async linkedinCampaignGroups() {
      var info = {
        campaignId: this.campaignId,
      };
      let res = await api().linkedinCampaignGroups(info);
      if (res.data.length > 0) {
        this.linkedinData = res.data;
      }
    },
    async twitterCampaigns() {
      var info = {
        campaignId: this.campaignId,
      };
      let res = await api().twitterCampaigns(info);
      this.twitterData = res.data;
    },
    async addCampaign(e) {
      e.preventDefault();
      var info = this.item;
      info.propertyId = this.property._id;
      info.walkthruitCampaignId = this.campaignId;
      info.accountName = this.property.name;
      var res;
      if (this.platform === "Facebook") {
        if (info.objective && info.name) {
          this.submitting = true;
          res = await api().createFacebookCampaign(info);
        } else {
          return;
        }
      } else if (this.platform === "Google") {
        if (
          info.googleClientAccount &&
          info.advertising_channel_type &&
          info.name
        ) {
          if (info.advertising_channel_type === "VIDEO") {
            if (info.campaignId) {
              this.submitting = true;
              res = await api().createGoogleCampaign(info);
            } else {
              return;
            }
          } else {
            if (info.bidding_strategy_type) {
              this.submitting = true;
              res = await api().createGoogleCampaign(info);
            } else {
              return;
            }
          }
        } else {
          return;
        }
      } else if (this.platform === "LinkedIn") {
        if (info.name) {
          this.submitting = true;
          res = await api().createLinkedinCampaignGroup(info);
        } else {
          return;
        }
      } else if (this.platform === "Twitter") {
        if (info.objective && info.bidStrategy && info.startDate && info.name) {
          this.submitting = true;
          res = await api().createTwitterCampaign(info);
        } else {
          return;
        }
      }
      if (res.data && res.data.success) {
        this.rerender("addCampaignclose");
      } else {
        this.error = res.data.data;
      }
      this.submitting = false;
    },
    clear() {
      this.platform = null;
      this.item.name = null;
      this.item.objective = null;
      this.item.advertising_channel_type = null;
      this.item.bidding_strategy_type = null;
      this.item.googleClientAccount = null;
      this.item.campaignId = null;
      // this.item.includeIns = false;
      this.item.campaignType = "1";
      this.property = {
        _id: null,
        name: null,
      };
      this.notice = null;
      this.error = null;
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    async getClient() {
      var info = {
        campaignId: this.campaignId,
      };
      var res = await api().getClient(info);
      if (res.data) {
        this.property = res.data.property;
      }
    },
    async getGoogleAccounts() {
      var _this = this;
      var res = await api().googleClientAccounts();
      if (res.data) {
        _this.googleAccounts = res.data;
      }
    },
  },
  watch: {
    platform(newPlatform) {
      if (newPlatform === "Facebook") {
        this.item.advertising_channel_type = null;
        this.item.bidding_strategy_type = null;
        this.item.googleClientAccount = null;
        this.item.campaignId = null;
      } else if (newPlatform === "Google") {
        this.item.objective = null;
        this.item.campaignId = null;
        // this.item.includeIns = false;
      } else if (newPlatform === "Linkedin") {
        this.item.objective = null;
        this.item.advertising_channel_type = null;
        this.item.bidding_strategy_type = null;
        this.item.googleClientAccount = null;
        this.item.campaignId = null;
        // this.item.includeIns = false;
      }
      this.item.campaignType = "1";
    },
    property() {
      this.item.advertising_channel_type = null;
      this.item.bidding_strategy_type = null;
      this.item.googleClientAccount = null;
      this.item.campaignId = null;
    },
  },
  async mounted() {
    var _this = this;
    var user = this.$store.state.user;
    if (user?.isTestAdmin) {
      await _this.facebookCampaign();
    } else {
      //for linkedin
      await _this.getClient();
      await _this.linkedinCampaignGroups();
      // for google
      await _this.getGoogleAccounts();
      await _this.googleCampaign();

      await _this.facebookCampaign();
      await _this.twitterCampaigns();
    }

    _this.isLoading = false;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
