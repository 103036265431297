import App from './App.vue'
import router from './router'
import store from './store.js'
import Vidle from 'v-idle-3'
import { createSSRApp } from 'vue'
import FloatingVue from 'floating-vue' //tooltip
import VueClipboard from 'vue3-clipboard'
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
import Popper from "vue3-popper";

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
library.add(fas, far);

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import * as Sentry from "@sentry/vue";

export default async function () {
    const app = createSSRApp(App)
    router.beforeEach((to, from, next) => {
        let isAuthenticated = store.state.isUserLoggedIn;
        let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        if (!isAuthenticated) {
            if (requiresAuth) next({ name: 'login' });
            else next();
        } else {
            if (requiresAuth) {
                let isAdmin = store.state.user?.isAdmin ? store.state.user.isAdmin : false;
                let isSuperAdmin = store.state.user?.isSuperAdmin ? store.state.user.isSuperAdmin : false;
                let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
                let requiresSuperAdmin = to.matched.some(record => record.meta.requiresSuperAdmin);
                if (requiresAdmin && !isAdmin) next({ name: "home" }); else {
                    if (requiresSuperAdmin && !isSuperAdmin) next({ name: "home" }); else next()
                };
            } else {
                if (to.name.includes('public') || to.name.includes('logout')) {
                    next();
                } else {
                    next({ name: "home" })
                }
            };
        }
    });
    app.use(router)
    app.use(store)
    app.use(Vidle)
    app.component("font-awesome-icon", FontAwesomeIcon)
    app.component('Loading', Loading);
    app.use(FloatingVue)
    app.use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    app.component("Popper", Popper);
    app.component("VueDatePicker", VueDatePicker);
    app.use(VueSweetalert2);
    if (!import.meta.env.SSR) {
        var myDataTable = await import("datatables.net-vue3")
        const DataTable = myDataTable.default;
        var mymodule = await import("datatables.net-bs5")
        const DataTablesFactory = mymodule.default;
        DataTable.use(DataTablesFactory);
        app.component('DataTable', DataTable);
    }
    if (!import.meta.env.SSR) {
        Sentry.init({
            Vue: app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: ["localhost", "https://my.walkthruit.com"],
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                }),
                new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
    return { app, router, store, Vidle }
}
