<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4">
          <PropertyDashboardContentHeading
            :propertyId="propertyId"
            :showUploadContentButton="false"
          />
        </div>
        <div
          class="col-12 col-lg-8 buttons d-flex justify-content-end"
          v-if="viewOnly"
        >
          <button
            type="button"
            class="btn btn-primary wti-btn wti-btn-white share-button"
            data-bs-toggle="modal"
            data-bs-target="#shareURL"
          >
            Share URL
          </button>
          <router-link
            class="btn btn-edit mx-2"
            aria-current="page"
            :to="{
              name: 'edit_campaign',
              params: {
                propertyId: propertyId,
              },
            }"
          >
            Edit
          </router-link>
          <button
            type="button"
            class="btn btn-approve mx-2"
            v-if="!isAdmin && status == 'Needs Approval'"
            @click="approve"
          >
            <span
              v-if="submitting"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ submitting ? "" : "Approve" }}
          </button>
        </div>
      </div>

      <WalkthruitCampaignForm
        :propertyId="propertyId"
        :campaignId="campaignId"
        :isAdmin="isAdmin"
        :viewOnly="viewOnly"
        @campaignStatusUpdate="updateCampaignStatus"
      />
      <div class="my-4 buttons">
        <button
          type="button"
          class="btn btn-approve mx-2"
          v-if="!isAdmin && viewOnly && status == 'Needs Approval'"
          @click="approve"
        >
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Approve" }}
        </button>
      </div>
    </div>
  </div>
  <Modal id="campaignAlert">
    <template v-slot:title>Are you sure?</template>
    <template v-slot:body>
      You have unsaved changes that will be deleted if you don’t save
      them.</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-red"
        id="allowLeave"
        data-bs-dismiss="modal"
      >
        Yes
      </button>
      <button type="button" class="btn btn-green" data-bs-dismiss="modal">
        Cancel
      </button>
    </template>
  </Modal>
</template>

<style lang="sass">
@import "@/assets/style.sass"
</style>

<script>
import { ref } from "vue";
// Components
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import WalkthruitCampaignForm from "@/components/WalkthruitCampaignForm.vue";
import Modal from "@/components/Modal.vue";
import api from "@/services/BackendConnection";

export default {
  name: "WalkthruitCampaignFormView",
  setup(props) {
    return {
      viewOnly: ref(false),
      status: ref(null),
      submitting: ref(false),
      campaignEdited: ref(false),
    };
  },
  components: {
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    WalkthruitCampaignForm,
    Modal,
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
    },
  },
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
  },
  methods: {
    updateCampaignStatus(value) {
      this.campaignEdited = value;
    },
    updateView(route) {
      if (route.name === "show_campaign") {
        this.viewOnly = true;
      } else if (route.name === "edit_campaign") {
        this.viewOnly = false;
      }
    },
    approve(e) {
      var _this = this;
      e.preventDefault();
      this.submitting = true;
      var info = {
        campaignId: this.campaignId,
        status: "Pending",
      };
      api()
        .updateWalkthruitCampaign(info)
        .then((res) => {
          if (res && res.data.success) {
            _this.submitting = false;
            _this.$router.push({
              name: "campaigns",
              params: {
                propertyId: _this.propertyId,
              },
            });
          }
        });
    },
    getCampaignStatus() {
      var info = {
        campaignId: this.campaignId,
      };
      var _this = this;
      api()
        .getCampaignStatus(info)
        .then((res) => {
          if (res.data) {
            _this.status = res.data.status;
          }
        });
    },
  },
  mounted() {
    this.updateView(this.$route);
    if (this.campaignId) {
      this.getCampaignStatus();
    }
  },
  watch: {
    $route(newValue) {
      this.updateView(newValue);
    },
  },
  beforeRouteLeave(to, from, next) {
    var _this = this;
    var userAllowLeave = false;
    if (
      this.campaignEdited &&
      (from.name == "edit_campaign" || from.name == "new_campaign") &&
      to.name != "show_campaign" &&
      to.name != "edit_campaign" &&
      !_this.$store.state.allowLeave &&
      from.name != to.name
    ) {
      if (typeof window !== undefined) {
        import("bootstrap").then((bootstrap) => {
          new bootstrap.Modal(document.getElementById("campaignAlert")).show();
        });
        this.$nextTick(() => {
          document
            .getElementById("allowLeave")
            .addEventListener("click", function (event) {
              userAllowLeave = true;
            });
          document
            .getElementById("campaignAlert")
            .addEventListener("hidden.bs.modal", function (event) {
              if (!userAllowLeave) {
                return false;
              } else {
                next();
              }
            });
        });
      } else {
        const answer = window.confirm(
          "Do you really want to leave? you have unsaved changes!"
        );
        if (!answer) {
          return false;
        } else next();
      }
    } else {
      next();
    }
  },
};
</script>

