<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container flex-column studio-container">
      <PropertyDashboardContentHeading :propertyId="propertyId" />
      <form @submit.prevent="uploadStudio" v-if="isAdmin" class="mb-4">
        <div class="my-2">
          <Input v-model="studio" type="text" label="Video url" />
          <div class="alert alert-danger" v-if="error">
            {{ error }}
          </div>
        </div>
        <button v-if="isAdmin" type="submit" class="btn btn-primary wti-btn-black">
          Submit
        </button>
      </form>
      <div class="html-block container" v-html="generateHtml(studio)" />
    </div>
  </div>
</template>

<style scoped  lang="sass">
form
  max-height: 100%
.studio-container
  height: 110%
  .html-block
    height: 100%
    flex-grow: 1
    border: none
    margin: 0
    padding: 0
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import Input from "@/components/Input.vue";
export default {
  name: "PropertyStudio",
  setup(props) {
    return {
      studio: ref(null),
      error: ref(null),
    };
  },
  components: {
    PropertyDashboardSidebarNav,
    PropertyDashboardContentHeading,
    Input,
  },
  props: ["propertyId"],
  computed: {
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
  },
  methods: {
    generateHtml() {
      if (this.studio) {
        try {
          var url = new URL(this.studio);
          this.error = null;
          return (
            '<!DOCTYPE html><html><body><iframe src="' +
            url +
            '" style="border:0px #ffffff none;" name="WalkthruStudioiFrame" ' +
            'scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" ' +
            ' height="100%" width="100%" allowfullscreen></iframe></body></html>'
          );
        } catch (e) {
          this.error = "Please enter an valid url";
        }
      }
    },
    uploadStudio(e) {
      e.preventDefault();
      var info = {
        propertyId: this.propertyId,
        studio: this.studio,
      };
      var _this = this;
      api()
        .uploadStudio(info)
        .then((res) => {
          if (res.data && res.data.success) {
            var key = _this.$store.state.componentKey;
            _this.$store.commit("setComponentKey", key + 1);
          }
        });
    },
    getStudio() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .getStudio(info)
        .then((res) => {
          if (res.data) {
            _this.studio = res.data;
          }
        });
    },
  },
  mounted() {
    this.getStudio();
  },
};
</script>
