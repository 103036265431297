<template>
  <div class="property-dashboard-content-heading">
    <div class="heading-text">
      <div class="current-property">
        <button
          class="navbar-toggler ms-auto d-inline d-lg-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasMenu"
          aria-controls="offcanvasMenu"
        >
          <font-awesome-icon icon="compass" />
        </button>
        {{ propertyName }}
      </div>
      <div class="current-route">
        {{
          currentRouteName == "Manager" ||
          currentRouteName == "Reporting" ||
          currentRouteName == "Audiences"
            ? "Ads " + currentRouteName
            : currentRouteName
        }}
      </div>
    </div>
    <div
      class="switches-wrapper d-none d-lg-block"
      v-if="showCampaignSwitcher === true && !isAdmin"
    >
      <div class="switches">
        <div
          class="switch"
          :class="{ active: assetSpecific }"
          @click="updateTabName('asset')"
        >
          {{ propertyName }}
        </div>
        <div
          class="switch"
          :class="{ active: !assetSpecific }"
          @click="updateTabName('account')"
        >
          All Campaigns (Account-Wide)
        </div>
      </div>
    </div>
    <div
      v-if="showUploadContentButton === true"
      class="heading-upload-content-button"
    >
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addContent"
      >
        Upload File
      </button>
    </div>
    <div
      v-if="showCreateNewAdButton === true"
      class="heading-create-new-ad-button"
    >
      <router-link
        class="btn"
        aria-current="page"
        :to="{
          name: 'new_campaign',
          params: {
            propertyId: propertyId,
          },
        }"
        v-if="isAdmin"
      >
        Create New Ad
      </router-link>
    </div>
  </div>
  <div
    class="switches-wrapper d-block d-lg-none"
    v-if="showCampaignSwitcher === true && !isAdmin"
  >
    <div class="switches">
      <div
        class="switch"
        :class="{ active: assetSpecific }"
        @click="updateTabName('asset')"
      >
        {{ propertyName }}
      </div>
      <div
        class="switch"
        :class="{ active: !assetSpecific }"
        @click="updateTabName('account')"
      >
        All Campaigns (Account-Wide)
      </div>
    </div>
  </div>
</template>
<style lang="sass">
@import "@/assets/vars.sass"

.property-dashboard-content-heading
  font-weight: 600
  margin-top: 31px
  margin-bottom: 31px
  display: flex
  justify-content: space-between
  align-items: flex-start
  +mobile
    margin: 10px
  .btn
    background-color: $Blue
    color: white !important
    font-weight: 600
    font-size: 16px
    line-height: 20px
    padding: 16px 65px
    border: 1px solid $Blue
    +mobile
      padding: 10px
      font-size: 12px
      border-radius: 0.5rem
  .btn:hover
    background-color: $BlueBtnHover
    border: 1px solid $BlueBtnHover
  .current-property
    font-size: 22px
    line-height: 28px
    color: $Main
    font-weight: 600
    +mobile
      font-size: 16px
      word-break: break-word

  .current-route
    font-size: 14px
    line-height: 18px
    color: $LightGrey
    font-weight: 600
    +mobile
      font-size: 14px
      margin-left: 0
  .switches-wrapper
    text-align: right
</style>

<script>
import api from "@/services/BackendConnection.js";
import { ref } from "vue";

export default {
  name: "PropertyDashboardContentHeading",
  emits: ["updateTab", "updatePropertyname"],
  setup(props) {
    return {
      assetSpecific: ref(true),
      propertyName: ref(null),
    };
  },
  props: [
    "propertyId",
    "showUploadContentButton",
    "showCreateNewAdButton",
    "showCampaignSwitcher",
    "token",
  ],
  computed: {
    currentRouteName() {
      let displayName = "";
      switch (this.$route.meta.root) {
        case "campaigns":
          displayName = "Manager";
          break;
        case "report":
          displayName = "Reporting";
          break;
        case "audiences":
          displayName = "Audiences";
          break;
        case "3D_walkthru":
          displayName = "3D Walkthru";
          break;
        case "studio":
          displayName = "Studio";
          break;
        case "video":
          displayName = "Video";
          break;
        case "contents":
          displayName = "Content";
          break;
      }
      return displayName;
    },
    isAdmin() {
      var isAdmin = false;
      var user = this.$store.state.user;
      if (user) {
        isAdmin = user?.isAdmin ? user.isAdmin : false;
        if (isAdmin) {
          var clientViwe = this.$store.state.user.adminClientView;
          return isAdmin && !clientViwe;
        }
      }
      return isAdmin;
    },
  },
  methods: {
    getPropertyName() {
      var info = {
        propertyId: this.propertyId,
        token: this.token,
      };
      var _this = this;
      api()
        .getPropertyName(info)
        .then((res) => {
          if (res.data) {
            _this.propertyName = res.data;
            _this.$emit("updatePropertyname", res.data);
          }
        });
    },
    updateTabName(tab) {
      if (tab === "asset") {
        this.assetSpecific = true;
      } else if (tab === "account") {
        this.assetSpecific = false;
      }
      this.$emit("updateTab", tab);
    },
  },
  created() {
    this.getPropertyName();
  },
};
</script>
