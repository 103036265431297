<template>
  <div class="container overflow-auto" v-if="data.length > 0">
    <div class="row">
      <div class="col">
        <div class="p-3">
          <h3>Google</h3>
        </div>
      </div>
    </div>
    <table class="table table-color table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Campaign Name</th>
          <th scope="col">Advertising Channel Type</th>
          <th scope="col">Created At</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody class="tbody-color">
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <a :href="adManagerUrl + item.campaignId" target="_blank">
              {{ item.campaignName }}</a
            >
          </td>
          <td>{{ item.objective }}</td>
          <td>{{ item.createdAt }}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary me-4"
              @click="updateCampaign(item)"
              data-bs-toggle="modal"
              data-bs-target="#editGoogleCampaign"
            >
              <font-awesome-icon :icon="['fas', 'edit']" />
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="updateCampaign(item)"
              data-bs-toggle="modal"
              data-bs-target="#deleteGoogleCampaign"
              v-if="item.status !== 'REMOVED'"
            >
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Modal id="editGoogleCampaign">
    <template v-slot:title>
      Edit Google {{ item.campaignName }} campaign
    </template>
    <template v-slot:body>
      <form @submit.prevent="editCampaign">
        <Input v-model="item.campaignName" type="text" label="Campaign Name" />
        <Select
          v-model="item.objective"
          label="Advertising Channel Type"
          :options="advertisingChannelType"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Update" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="deleteGoogleCampaign">
    <template v-slot:title>
      Are you sure to delete this Google campaign?
    </template>
    <template v-slot:body>
      <form @submit.prevent="deleteCampaign">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Name"
          :disable="true"
        />
        <Select
          v-model="item.objective"
          label="Advertising Channel Type"
          :options="advertisingChannelType"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Delete" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import campaignData from "@/utils/campaignData.json";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "GoogleCampaignTable",
  setup(props) {
    return {
      status: campaignData.campaign.google.campaignStatus,
      budgetDeliveryMethod: campaignData.campaign.google.budgetDeliveryMethod,
      advertisingChannelType:
        campaignData.campaign.google.advertisingChannelType,
      item: ref({
        campaignId: null,
        budget_id: null,
        campaignName: null,
        objective: null,
        budget: null,
        delivery_method: null,
        status: null,
      }),
      adManagerUrl: "https://ads.google.com/aw/adgroups?campaignId=",
      submitting: ref(false),
    };
  },
  props: ["data"],
  components: {
    Modal,
    Input,
    Select,
  },
  methods: {
    updateCampaign(item) {
      // this.item = item;
      this.item.campaignId = item.campaignId;
      this.item.budget_id = item.budget_id;
      this.item.campaignName = item.campaignName;
      this.item.objective = item.objective;
      this.item.budget = item.budget;
      this.item.delivery_method = item.delivery_method;
      this.item.status = item.status;
    },
    editCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
        name: this.item.campaignName,
        budget: this.item.budget,
        delivery_method: this.item.delivery_method,
        status: this.item.status,
      };
      api()
        .editGoogleCampaign(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("editGoogleCampaignclose");
          }
        });
    },
    deleteCampaign(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        id: this.item.campaignId,
      };
      api()
        .deleteGoogleCampaign(info)
        .then((res) => {
          if (!res.data || !res.data.success || res.data.data) {
            alert(res.data.data);
          }
          this.submitting = false;
          this.rerender("deleteGoogleCampaignclose");
        });
    },
    clear() {
      this.item.campaignId = null;
      this.item.budget_id = null;
      this.item.campaignName = null;
      this.item.objective = null;
      this.item.budget = null;
      this.item.delivery_method = null;
      this.item.status = null;
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
  },
  mounted() {
    var myModals = document.querySelectorAll(".modal");
    var _this = this;
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
