<template>
  <div class="container overflow-auto" v-if="data.length > 0">
    <div class="row">
      <div class="col">
        <div class="p-3">
          <h3>Linkedin campaign group</h3>
        </div>
      </div>
    </div>
    <table class="table table-color table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Campaign Group Name</th>
          <th scope="col">Account Id</th>
          <th scope="col">Leads Notification Enabled</th>
          <th scope="col">Created At</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody class="tbody-color">
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <a
              :href="adManagerUrl(item.accountId, item.campaignGroupId)"
              target="_blank"
            >
              {{ item.campaignName }}</a
            >
          </td>
          <td>{{ item.accountId }}</td>
          <td>
            <font-awesome-icon
              :class="{
                checked: notificationUrlExist,
                unchecked: !notificationUrlExist,
              }"
              :icon="['fas', 'circle-check']"
            />
          </td>
          <td>{{ item.createdAt }}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary me-4"
              @click="updateCampaignGroup(item)"
              data-bs-toggle="modal"
              data-bs-target="#editLinkedinCampaignGroup"
            >
              <font-awesome-icon :icon="['fas', 'edit']" />
            </button>
            <button
              type="button"
              class="btn btn-danger me-4"
              @click="updateCampaignGroup(item)"
              data-bs-toggle="modal"
              data-bs-target="#deleteLinkedinCampaignGroup"
              v-if="item.status !== 'REMOVED'"
            >
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="updateCampaignGroup(item)"
              data-bs-toggle="modal"
              data-bs-target="#createNotificationUrl"
              v-if="!notificationUrlExist"
              :disabled="!notificationUrlCreatable"
            >
              <font-awesome-icon :icon="['fas', 'link']" />
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="updateCampaignGroup(item)"
              data-bs-toggle="modal"
              data-bs-target="#createNotificationUrl"
              v-else
              disabled
            >
              <font-awesome-icon :icon="['fas', 'link']" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Modal id="editLinkedinCampaignGroup">
    <template v-slot:title>
      Edit Linkedin Campaign Group {{ item.campaignName }}
    </template>
    <template v-slot:body>
      <form @submit.prevent="editCampaignGroup">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Group Name"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Update" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="deleteLinkedinCampaignGroup">
    <template v-slot:title>
      Are you sure to delete this Linkedin Campaign Group?
    </template>
    <template v-slot:body>
      <form @submit.prevent="deleteCampaignGroup">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Group Name"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Delete" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="createNotificationUrl">
    <template v-slot:title>
      Please make sure the Linkedin page is setup before enable leads
      notification!
    </template>
    <template v-slot:body>
      <form @submit.prevent="createNotificationUrl">
        <Input
          v-model="item.campaignName"
          type="text"
          label="Campaign Group Name"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Enable" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import campaignData from "@/utils/campaignData.json";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "LinkedinCampaignGroupTable",
  setup(props) {
    return {
      status: campaignData.campaign.linkedin.campaignStatus,
      item: ref({
        campaignGroupId: null,
        campaignName: null,
        status: null,
      }),
      notificationUrlExist: ref(false),
      notificationUrlCreatable: ref(false),
      submitting: ref(false),
    };
  },
  props: ["data", "propertyId"],
  components: {
    Modal,
    Input,
    Select,
  },
  computed: {},
  methods: {
    updateCampaignGroup(item) {
      this.item.campaignGroupId = item.campaignGroupId;
      this.item.campaignName = item.campaignName;
      this.item.status = item.status;
    },
    editCampaignGroup(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        propertyId: this.propertyId,
        id: this.item.campaignGroupId,
        name: this.item.campaignName,
        status: this.item.status,
      };
      api()
        .editLinkedinCampaignGroup(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.submitting = false;
            this.rerender("editLinkedinCampaignGroupclose");
          }
        });
    },
    deleteCampaignGroup(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        propertyId: this.propertyId,
        id: this.item.campaignGroupId,
        status: this.item.status,
      };
      api()
        .deleteLinkedinCampaignGroup(info)
        .then((res) => {
          if (!res.data || !res.data.success) {
            alert(res.data.data);
          }
          this.submitting = false;
          this.rerender("deleteLinkedinCampaignGroupclose");
        });
    },
    clear() {
      this.item.campaignGroupId = null;
      this.item.campaignName = null;
      this.item.status = null;
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    adManagerUrl(accountId, groupId) {
      return (
        "https://www.linkedin.com/campaignmanager/accounts/" +
        accountId +
        "/campaigns?campaignGroupIds=%5B" +
        groupId +
        "%5D"
      );
    },
    getNotificationUrl() {
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .getLinkedinWebhook(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.notificationUrlExist = res.data.exists;
            _this.notificationUrlCreatable = res.data.creatable;
          }
        });
    },
    createNotificationUrl(e) {
      e.preventDefault();
      this.submitting = true;
      var info = {
        propertyId: this.propertyId,
      };
      var _this = this;
      api()
        .createLinkedinWebhook(info)
        .then((res) => {
          if (res.data && res.data.success) {
            _this.notificationUrlExist = true;
            this.submitting = false;
            _this.rerender("createNotificationUrlclose");
          }
        });
    },
  },
  mounted() {
    var myModals = document.querySelectorAll(".modal");
    var _this = this;
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
    this.getNotificationUrl();
  },
};
</script>
