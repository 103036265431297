<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="inner-dashboard-content container campaign-container">
      <div class="campaign-header">
        <PropertyDashboardContentHeading
          :propertyId="propertyId"
          :showUploadContentButton="false"
          :showCreateNewAdButton="true"
          :showCampaignSwitcher="true"
          @update-Tab="updateTab"
        />
      </div>
      <div class="campaign-content position-relative">
        <loading
          v-model:active="isLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
          class="mx-auto p-0 m-0 loading-container"
        />
        <ListCampaigns
          :propertyId="propertyId"
          :campaigns="campaigns"
          v-if="!isLoading"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass">
.campaign-container
  display: flex
  flex-flow: column
  .campaign-header
    flex: 0 1 auto
  .campaign-content
    flex: 1 1 auto
    overflow: auto
    .loading-container
      height: 100%
</style>

<script setup>
import { ref, computed, onMounted } from "vue";
import api from "@/services/BackendConnection";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import PropertyDashboardContentHeading from "@/components/PropertyDashboardContentHeading.vue";
import Modal from "@/components/Modal.vue";
import ListCampaigns from "@/components/ListCampaigns.vue";
import moment from "moment";
import { useStore } from "vuex";
import chatButton from "@/components/ChatButton.vue";

const props = defineProps(["propertyId"]);
var campaigns = ref(null);
var isLoading = ref(true);
const store = useStore();

const userId = computed(() => {
  var userId = store.state.user?.userId;
  var user = store.state.user;
  var isAdmin = user?.isAdmin ? user.isAdmin : false;
  if (isAdmin) {
    var clientViwe = store.state.user.adminClientView;
    if (clientViwe) {
      userId = store.state.user.currentUserId;
    }
  }
  return userId;
});

function getCampaigns() {
  var info = {
    userId: userId.value,
    propertyId: props.propertyId,
    startDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  api()
    .listCampaigns(info)
    .then((res) => {
      if (res.data) {
        campaigns.value = res.data;
        setInterval(() => {
          isLoading.value = false;
        }, 1500);
      }
    });
}

function getAllCampaigns() {
  var info = {
    userId: userId.value,
    propertyId: props.propertyId,
    startDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  api()
    .listAllCampaigns(info)
    .then((res) => {
      if (res.data) {
        campaigns.value = res.data;
        isLoading.value = false;
      }
    });
}

function updateTab(value) {
  isLoading.value = true;
  if (value === "asset") {
    getCampaigns();
  } else if (value === "account") {
    getAllCampaigns();
  }
}

onMounted(() => {
  getCampaigns();
});
</script>
