import api from './API';

export default () => {
    return {
        signIn(info) {
            return api().post("/auth/signin", info)
        },
        signOut(info) {
            return api().post("/auth/signout", info)
        },
        resetPasswordRequest(info) {
            return api().post("/auth/requestResetPassword", info)
        },
        resendInvitation(info) {
            return api().post("/admin/resendInvitation", info)
        },
        resetPassword(info) {
            return api().post("/auth/resetPassword", info)
        },
        createUser(info) {
            return api().post("/admin/createUser", info)
        },
        editUser(info) {
            return api().post("/admin/editUser", info)
        },
        listClients() {
            return api().get("/admin/getClientList")
        },
        getPropertyList() {
            return api().get("/admin/getPropertyList")
        },
        getCampaignsList(info) {
            return api().post("/admin/getCampaignsList", info)
        },
        getTrendingDeliverables(info) {
            return api().post("/admin/getTrendingDeliverables", info)
        },
        addPropertyClient(info) {
            return api().post("/admin/addPropertyClient", info)
        },
        removePropertyClient(info) {
            return api().post("/admin/removePropertyClient", info)
        },
        getClient(info) {
            return api().post("/admin/getClient", info)
        },
        createProperty(info) {
            return api().post("/admin/createProperty", info)
        },
        updateProperty(info) {
            return api().post("/admin/updateProperty", info)
        },
        listProperties(info) {
            return api().post("/listProperties", info)
        },
        facebookCampaigns(info) {
            return api().post("/facebook/listCampaigns", info)
        },
        createFacebookCampaign(info) {
            return api().post("/admin/facebook/createCampaign", info)
        },
        editFacebookCampaign(info) {
            return api().post("/admin/facebook/editCampaign", info)
        },
        deleteFacebookCampaign(info) {
            return api().post("/admin/facebook/deleteCampaign", info)
        },
        registerFBNotification(info) {
            return api().post("/admin/facebook/registerFBNotification", info)
        },
        UserFacebookAdsets(info) {
            return api().post("/facebook/listAdsets", info)
        },
        createFacebookAdset(info) {
            return api().post("/admin/admin/facebook/createAdset", info)
        },
        editFacebookAdset(info) {
            return api().post("/admin/facebook/editAdset", info)
        },
        deleteFacebookAdset(info) {
            return api().post("/admin/admin/facebook/deleteAdset", info)
        },
        UserFacebookAds(info) {
            return api().post("/facebook/listAds", info)
        },
        createFacebookAd(info) {
            return api().post("/admin/facebook/createAd", info)
        },
        deleteFacebookAd(info) {
            return api().post("/admin/facebook/deleteAd", info)
        },
        facebookCampaignInsight(info) {
            return api().post("/facebook/campaignInsight", info)
        },
        googleClientAccounts() {
            return api().get("/admin/google/clientAccounts")
        },
        googleCampaigns(info) {
            return api().post("/google/listCampaigns", info)
        },
        createGoogleCampaign(info) {
            return api().post("/admin/google/createCampaign", info)
        },
        editGoogleCampaign(info) {
            return api().post("/admin/google/editCampaign", info)
        },
        deleteGoogleCampaign(info) {
            return api().post("/admin/google/deleteCampaign", info)
        },
        googleCampaignMetrics(info) {
            return api().post("/google/campaignMetrics", info)
        },
        linkedinCampaignGroups(info) {
            return api().post("/linkedin/listCampaignGroups", info)
        },
        createLinkedinCampaignGroup(info) {
            return api().post("/admin/linkedin/createCampaignGroup", info)
        },
        editLinkedinCampaignGroup(info) {
            return api().post("/admin/linkedin/editCampaignGroup", info)
        },
        deleteLinkedinCampaignGroup(info) {
            return api().post("/admin/linkedin/deleteCampaignGroup", info)
        },
        linkedinCampaignGroupsReport(info) {
            return api().post("/linkedin/campaignGroupReport", info)
        },
        linkedinGetCompanySuggestion(info) {
            return api().post("/linkedin/getCompanySuggestion", info)
        },
        linkedinGetJobTitleSuggestion(info) {
            return api().post("/linkedin/getJobTitleSuggestion", info)
        },
        linkedinIndustries() {
            return api().get("/linkedin/industries")
        },
        twitterCampaigns(info) {
            return api().post("/twitter/listCampaigns", info)
        },
        createTwitterCampaign(info) {
            return api().post("/admin/twitter/createCampaign", info)
        },
        editTwitterCampaign(info) {
            return api().post("/admin/twitter/editCampaign", info)
        },
        deleteTwitterCampaign(info) {
            return api().post("/admin/twitter/deleteCampaign", info)
        },
        twitterCampaignReport(info) {
            return api().post("/twitter/campaignReport", info)
        },
        getPrev(info) {
            return api().post("/public/getLinkPreview", info)
        },
        getPreviewStatus(info) {
            return api().post("/preview/getPreviewStatus", info)
        },
        requestApprove(info) {
            return api().post("/admin/preview/requestApprove", info)
        },
        postFacebookPreviewData(info) {
            return api().post("/admin/previewData/facebook", info)
        },
        postIstagramPreviewData(info) {
            return api().post("/admin/previewData/instagram", info)
        },
        postGooglePreviewData(info) {
            return api().post("/admin/previewData/google", info)
        },
        postLinkedinPreviewData(info) {
            return api().post("/admin/previewData/linkedin", info)
        },
        getFacebookPreviewData(info) {
            return api().post("/getPreviewData/facebook", info)
        },
        getInstagramPreviewData(info) {
            return api().post("/getPreviewData/instagram", info)
        },
        getGooglePreviewData(info) {
            return api().post("/getPreviewData/google", info)
        },
        getLinkedinPreviewData(info) {
            return api().post("/getPreviewData/linkedin", info)
        },
        postClientCampaignData(info) {
            return api().post("/walkthruitCampaign/store", info)
        },
        updateCampaignInfoAdmin(info) {
            return api().post("/admin/campaignIsClient/update", info)
        },
        updateWalkthruitCampaign(info) {
            return api().post("/walkthruitCampaign/update", info)
        },
        duplicateWalkthruitCampaign(info) {
            return api().post("/walkthruitCampaign/duplicate", info)
        },
        getWalkthruitCampaign(info) {
            return api().post("/public/walkthruitCampaign/get", info)
        },
        getWalkthruitCampaigns(info) {
            return api().post("/walkthruitCampaign/getList", info)
        },
        getCitySuggestion(query) {
            return api().post("/walkthruitCampaign/getCitySuggestion", query)
        },
        getAudiences(info) {
            return api().post("/walkthruitCampaign/getAudiences", info)
        },
        addAudiences(info) {
            return api().post("/walkthruitCampaign/addAudiences", info)
        },
        newProjectRequest(info) {
            return api().post("/newProject", info)
        },
        supportRequest(info) {
            return api().post("/support", info)
        },
        contactusRequest(info) {
            return api().post("/contactus", info)
        },
        uploadContents(info) {
            return api().post("/contents/upload", info)
        },
        getContents(info) {
            return api().post("/contents/get", info)
        },
        uploadStudio(info) {
            return api().post("/studio/upload", info)
        },
        getStudio(info) {
            return api().post("/studio/get", info)
        },
        listCampaigns(info) {
            return api().post("/listCampaigns", info)
        },
        listAllCampaigns(info) {
            return api().post("/listAllCampaigns", info)
        },
        listCampaignsForReport(info) {
            return api().post("/listCampaignsForReport", info)
        },
        getPropertyName(info) {
            return api().post("/public/propertyName/get", info)
        },
        getCampaignName(info) {
            return api().post("/campaignName/get", info)
        },
        getCampaignStatus(info) {
            return api().post("/campaignStatus/get", info)
        },
        totalReport(info) {
            return api().post("/public/total_report", info)
        },
        monthlyReport(info) {
            return api().post("/public/monthly_report", info)
        },
        weeklyReport(info) {
            return api().post("/public/weekly_report", info)
        },
        getCompanyReachedReport(info) {
            return api().post("/public/company_reached", info)
        },
        getLeadsForCampaign(info) {
            return api().post("/public/leads_for_campaign", info)
        },
        getWebsitesList(info) {
            return api().post("/public/websites_list", info)
        },
        getCampaignLikes(info) {
            return api().post("/public/campaign_likes", info)
        },
        updateCampaignLikes(info) {
            return api().post("/admin/update_likes", info)
        },
        generatePDF(campaignId) {
            return api().get("/campaign_pdf/" + campaignId, { responseType: 'blob' })
        },
        generateReportPDF(info) {
            return api().post("/report_pdf/", info, { responseType: 'blob' })
        },
        getCampaignByToken(info) {
            return api().post("/public/campaign/getByToken", info)
        },
        get3DWalkthruitProjects(info) {
            return api().post("/3D_projects/get", info)
        },
        update3DWalkthruitProjects(info) {
            return api().post("/3D_projects/update", info)
        },
        getGoogleAnalyticsReport(info) {
            return api().post("/google_analytics/report", info)
        },
        getGoogleAnalyticsFeedReport(info) {
            return api().post("/google_analytics/feedReport", info)
        },
        upload3DWalkthruitTarget(info) {
            return api().post("/3D_projects/addTarget", info)
        },
        get3DWalkthruitTargets(info) {
            return api().post("/3D_projects/getTargets", info)
        },
        delete3DWalkthruitTarget(info) {
            return api().post("/3D_projects/deleteTarget", info)
        },
        listAllWistiaVideos() {
            return api().get("/admin/wistia/listAll")
        },
        getWistiaVideos(info) {
            return api().post("/wistia/getVideos", info)
        },
        getWistiaKpis(info) {
            return api().post("/wistia/getKpis", info)
        },
        getWistiaFeed(info) {
            return api().post("/wistia/getFeed", info)
        },
        createLinkedinWebhook(info) {
            return api().post("/admin/linkedin/leadsNotificationurl/create", info)
        },
        getLinkedinWebhook(info) {
            return api().post("/admin/linkedin/leadsNotificationurl/get", info)
        },
        getFilePreview(info) {
            return api().post("/public/walkthruitCampaign/filePreview", info)
        },
        listClientUsers() {
            return api().get("/admin/listClients")
        },
        getVtsCampaigns(info) {
            return api().post("/walkthruitCampaign/getVtsCampaigns", info)
        },
        sendCampaignFailedNotification(info) {
            return api().post("/notification/campaignUpdateFailed", info)
        },
        getMAContacts(info) {
            return api().post("/marketing_analytics/getContacts", info)
        },
        getMACompanies(info) {
            return api().post("/marketing_analytics/getCompanies", info)
        }
    }
}
