<template>
  <Modal id="contact">
    <template v-slot:title> Contact </template>
    <template v-slot:body>
      <div class="instruction">
        Fill out the form below and we'll contact you as soon as we can
      </div>
      <form @submit.prevent="submitContact" class="needs-validation" novalidate>
        <Input
          v-model="phone"
          type="tel"
          label="Phone"
          childclass="contact-phone"
          :required="true"
        />
        <Input v-model="message" type="text" label="Message" :required="true" />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ submitting ? "" : "Submit" }}
        </button>
      </form>
    </template>
  </Modal>
</template>
<style lang="sass">
@import "@/assets/vars.sass"
.modal-content
  padding: 12px
.modal-body
  .instruction
    text-align: center
    max-width: 320px
    margin: 0 auto
    padding: 30px 0
    font-size: 18px
    line-height: 30px
  .form-label
    font-weight: 600
    font-size: 14px
    line-height: 18px
    color: $Main
  .form-control
    font-size: 14px
    line-height: 18px
    padding: 16px 20px
    margin-bottom: 1em
    border: 1px solid $LightGreyBorder
    border-radius: 12px
    &::placeholder
      color: $LightGrey !important
  .btn
    border-radius: 12px
    border: none
    background-color: $Main
    font-size: 16px
    line-height: 20px
    padding: 16px 44px
    float: right
</style>
<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";

export default {
  name: "ContactForm",
  setup(props) {
    return {
      phone: ref(null),
      message: ref(null),
      submitting: ref(false),
    };
  },
  props: ["userId"],
  components: {
    Modal,
    Input,
  },
  methods: {
    submitContact(e) {
      e.preventDefault();
      if (this.phone && this.message) {
        this.submitting = true;
        var info = {
          userId: this.userId,
          phone: this.phone,
          message: this.message,
        };
        api()
          .contactusRequest(info)
          .then((res) => {
            if (res.data && res.data.success) {
              this.submitting = false;
              this.rerender("contactclose");
            }
          });
      } else {
        return;
      }
    },
    rerender(id) {
      var key = this.$store.state.componentKey;
      this.$store.commit("setComponentKey", key + 1);
      var myModal = document.getElementById(id);
      myModal.click();
    },
    clear() {
      this.phone = null;
      this.message = null;
      var forms = document.querySelectorAll(".needs-validation");
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach(function (form) {
        if (form.classList.contains("was-validated")) {
          form.classList.remove("was-validated");
        }
      });
    },
  },
  mounted() {
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
};
</script>
