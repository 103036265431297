<template>
  <div class="welcome-screen">
    <div class="welcome-image"></div>
    <div class="form-wrapper">
      <form class="form-app form-reset-password" @submit.prevent="onSubmit">
        <img src="@/assets/walkthruit-full-black.png" class="form-logo" />
        <div class="title">Create Account</div>
        <div class="sub-title">Enter your password below:</div>
        <div class="form-content">
          <PasswordInput
            title="Password"
            id="password"
            toggleId="togglePassword"
            v-model="password"
          />
          <PasswordInput
            title="Password Confirmation"
            id="password_confirm"
            toggleId="togglePassword_confirm"
            v-model="password_confirm"
          />
          <button type="submit" class="btn btn-primary wti-btn">Submit</button>
          <div class="alert alert-info" v-if="notice">{{ notice }}</div>
          <div class="alert alert-danger" v-if="error">{{ error }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
@media (min-width: 1024px) {
}
</style>

<script>
import api from "@/services/BackendConnection";
import PasswordInput from "@/components/PasswordInput.vue";

export default {
  name: "SetPasswordView",
  data() {
    return {
      password: null,
      password_confirm: null,
      notice: null,
      error: null,
    };
  },
  components: {
    PasswordInput,
  },
  methods: {
    onSubmit(e) {
      var _this = this;
      e.preventDefault();
      if (_this.password !== _this.password_confirm) {
        _this.error = "Password must match!";
      } else {
        var user = {
          userId: _this.$route.query.id,
          password: _this.password,
          token: _this.$route.query.token,
          newAccount: true,
        };
        api()
          .resetPassword(user)
          .then((res) => {
            if (res.data) {
              var userInfo = res.data;
              if (userInfo.success) {
                _this.notice = userInfo.data;
                setTimeout(() => {
                  this.$router.push({
                    name: "login",
                  });
                }, 2000);
              } else {
                _this.error = userInfo.data;
              }
            }
          });
      }
    },
  },
  mounted() {},
};
</script>
