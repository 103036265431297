<template>
  <Carousel
    v-bind="settings"
    v-model="currentSlideToShow"
    :class="{
      mobile_carousel: mobileView,
      desktop_carousel: !mobileView,
      'facebook-carousel': platform === 1,
      'ins-carousel': platform === 5,
      'linkedin-carousel': platform === 3,
    }"
  >
    <Slide v-for="(file, slide) in files" :key="slide">
      <div class="carousel__item">
        <div v-if="platform == 1">
          <!-- <div v-if="slide < files.length - 1"> -->
          <div class="ad-container position-relative">
            <img
              :src="file.url"
              class="ad-asset position-absolute top-50 start-50 translate-middle w-100"
              v-if="file.type.includes('image')"
            />
            <video
              :src="file.url"
              controls
              v-else-if="file.type.includes('video')"
              class="ad-asset position-absolute top-50 start-50 translate-middle"
            ></video>
          </div>
          <div class="prev_info d-flex" v-if="showCarouselText">
            <div class="prev_info_text">
              <div
                class="link-headline"
                :class="{
                  mobile_link_headline: mobileView,
                }"
              >
                {{
                  cardInfo[slide].headline
                    ? cardInfo[slide].headline
                    : cardHeadlinePlaceholder
                }}
              </div>
              <div
                class="link-description"
                :class="{
                  mobile_link_description: mobileView,
                }"
              >
                <!-- {{
                  cardInfo[slide].description
                    ? cardInfo[slide].description
                    : cardDesPlaceholder
                }} -->
              </div>
            </div>
            <div class="cta">
              <button type="button" class="btn" v-if="ctaType !== 'No button'">
                <span class="cta-button">
                  {{ ctaType ? ctaType : "Learn more" }}</span
                >
              </button>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div v-else>
            <div class="ad-container">
              <img
                :src="file.url"
                class="position-absolute top-50 start-50 translate-middle logo-img"
                v-if="file.type.includes('image')"
              />
              <img
                src="@/assets/logo-sample.png"
                class="position-absolute top-50 start-50 translate-middle logo-img"
                v-else
              />
            </div>
            <div class="prev_info d-flex">
              <div class="prev_info_text">
                <div class="link-headline">See more at {{ domain }}</div>
              </div>
            </div>
          </div> -->
        </div>
        <div v-else-if="platform == 5">
          <div class="ad-container">
            <img
              :src="file.url"
              class="ad-asset position-absolute top-50 start-50 translate-middle"
              v-if="file.type.includes('image')"
            />
            <video
              :src="file.url"
              controls
              v-else-if="file.type.includes('video')"
              class="ad-asset position-absolute top-50 start-50 translate-middle"
            ></video>
          </div>
        </div>
        <div v-else-if="platform == 3">
          <div class="ad-container position-relative">
            <img
              :src="file.url"
              class="ad-asset position-absolute top-50 start-50 translate-middle w-100"
              v-if="file.type.includes('image')"
            />
            <video
              :src="file.url"
              controls
              v-else-if="file.type.includes('video')"
              class="ad-asset position-absolute top-50 start-50 translate-middle"
            ></video>
          </div>
          <div class="prev_info d-flex" v-if="showCarouselText">
            <div class="prev_info_text">
              <div class="link-headline">
                {{
                  cardInfo[slide].headline
                    ? cardInfo[slide].headline
                    : cardHeadlinePlaceholder
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Pagination v-if="platform == 5" />
      <Navigation />
    </template>
  </Carousel>
</template>

<style  lang="scss">
.prev-carousel {
  .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    .ad-container {
      background-color: #e1e1e1;
      min-height: 195px;
      overflow: hidden;
      aspect-ratio: 1 / 1;
      video.ad-asset {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
      .logo-img {
        max-width: 120px;
      }
    }
    .prev_info {
      color: #1c2028;
      max-height: 80px;
      min-height: 66px;
      font-size: 12px;
      overflow-wrap: break-word;
      align-items: center;
      background-color: #f0f2f5;
      // border-bottom: 1px solid #dddfe2;
      cursor: pointer;
      padding: 8px;
      position: relative;
      .prev_info_text {
        flex: 1;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        .link-headline {
          color: rgb(5, 5, 5);
          font-size: 15px;
          font-weight: 600;
          letter-spacing: normal;
          line-height: 20px;
          white-space: normal;
        }
        .link-description {
          font-size: 13px;
          font-weight: 400;
          letter-spacing: normal;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        .mobile_link_headline {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          font-size: 14px;
          font-weight: 700;
          max-height: 36px;
          -webkit-line-clamp: 2;
          line-height: 18px;
          position: relative;
          overflow: hidden;
        }
        .mobile_link_description {
          font-size: 14px;
          font-weight: 400;
          max-height: 32px;
          line-height: 16px;
          overflow: hidden;
          margin: 0;
          text-overflow: ellipsis;
        }
      }
      .cta-button {
        align-items: center;
        background-color: #e4e6eb;
        border: 0;
        border-radius: 6px;
        color: var(--facebook-primary-color);
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        flex-wrap: nowrap;
        font-size: 15px;
        font-weight: 600;
        height: 36px;
        justify-content: center;
        letter-spacing: normal;
        line-height: 19px;
        overflow-wrap: break-word;
        padding: 0 12px;
        position: relative;
        text-align: start;
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }
  .mobile_carousel {
    background: white;
    .carousel__slide {
      // width: 232px !important;
      padding: 0;
      .carousel__item {
        min-height: 200px;
        border: 1px solid #ccc;
        box-shadow: none;
        margin: 0 10px 0 0;
        // width: 232px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        white-space: normal;
        // aspect-ratio: 1 / 1;
        .ad-container {
          min-height: 195px;
          background: #e1e1e1;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
  .desktop_carousel {
    background: white;
    .carousel__slide {
      // width: 302px !important;
      padding: 0;
      .carousel__item {
        min-height: 200px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-width: 0 0 1px;
        display: block;
        text-align: center;
        background-color: #fff;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 0 4px;
        overflow: hidden;
        position: relative;
        white-space: normal;
        .ad-container {
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
  .carousel__slide {
    padding: 10px 0;
  }
  .carousel {
    .carousel__next--disabled,
    .carousel__prev--disabled {
      display: none;
    }
    svg.carousel__icon {
      align-items: center;
      background-color: #fff;
      border: 1px solid rgb(0, 0, 0, 0.1);
      border-radius: 50%;
      color: rgb(101, 103, 107);
      cursor: pointer;
      font-size: 12px;
      height: 48px;
      justify-content: center;
      line-height: 16px;
      opacity: 1;
      margin: 0;
      padding: 0;
      pointer-events: all;
      position: absolute;
      top: 50%;
      z-index: 9;
      text-indent: -99999px;
      transform: translateY(-50%);
      transition: all 1.5s;
      width: 48px;
    }
    .carousel__next svg.carousel__icon {
      right: 8px;
    }
    .carousel__prev svg.carousel__icon {
      left: 8px;
    }
    svg.carousel__icon:hover {
      fill: hsla(0, 0%, 100%, 0.9);
      background: rgba(0, 0, 0, 0.75);
    }
  }
  .ins-carousel {
    .carousel__slide {
      // width: 470px !important;
      // max-width: 100%;
      .carousel__item {
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border: none;
        .ad-container {
          aspect-ratio: 1 / 1;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .mobile_carousel .carousel__slide {
      // width: 320px !important;
      // max-width: 100%;
    }
    .carousel__pagination-item {
      button {
        padding: 0;
      }
    }
    .carousel__pagination-button::after {
      -webkit-appearance: none;
      background: none;
      background-color: #a8a8a8;
      border: 0;
      border-radius: 50%;
      height: 6px;
      margin: 0 2px;
      transition: all 0.2s ease-in-out;
      width: 6px;
    }
    .carousel__pagination-button:hover::after,
    .carousel__pagination-button--active::after {
      background-color: #3897eb;
    }
  }
  .linkedin-carousel {
    .carousel__slide {
      // width: 65% !important;
      // max-width: 100%;
      .carousel__item {
        width: 100%;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        height: 100%;
        margin: 0 4px;
        overflow: hidden;
        .ad-container {
          width: 100%;
          min-height: 200px;
          background: #e1e1e1;
          aspect-ratio: 1 / 1;
          img {
            object-fit: contain;
          }
        }
        .prev_info {
          align-items: center;
          height: 56px;
          min-height: 56px;
          padding: 8px 16px;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: #fff;
          color: rgba(0, 0, 0, 0.9);
          .prev_info_text {
            align-self: center;
            display: flex;
            flex: 1 1 0;
            max-height: 40px;
            -webkit-line-clamp: 2;
            line-height: 20px;
            margin: 0 auto;
            // max-width: 280px;
            .link-headline {
              color: rgba(0, 0, 0, 0.9);
              font-family: inherit !important;
              font-size: 14px !important;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              align-self: center;
              display: flex;
              flex: 1 1 0;
              max-height: 40px;
              -webkit-line-clamp: 2;
              line-height: 20px;
              margin: 0 auto;
              // max-width: 280px;
            }
          }
        }
      }
    }
    svg.carousel__icon {
      background-color: rgba(0, 0, 0, 0.6);
      border: 0;
      color: rgba(255, 255, 255, 0.9);
      height: 40px;
      transition: none;
      width: 40px;
    }
    .carousel__next {
      margin: 0;
      svg.carousel__icon {
        right: 0;
      }
    }
    .carousel__prev {
      margin: 0;
      svg.carousel__icon {
        left: 0;
      }
    }
  }
  // .mobile_carousel.linkedin-carousel {
  //   .carousel__slide {
  //     // width: 85% !important;
  //   }
  // }
}
</style>

<script>
import { ref } from "vue";
// Components
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  name: "CarouselComponent",
  setup(props) {
    return {
      currentSlideToShow: ref(props.currentSlide),
      cardHeadlinePlaceholder: ref("Card headline goes here"),
      cardDesPlaceholder: ref("Card description goes here"),
    };
  },
  props: [
    "adType",
    "files",
    "cardInfo",
    "ctaType",
    "domain",
    "mobileView",
    "currentSlide",
    "platform",
    "showCarouselText",
  ],
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  computed: {
    settings() {
      var itemsToShow = 1;
      var snapAlign = "center";
      if (this.mobileView) {
        snapAlign = "start";
        if (this.platform == 1) {
          itemsToShow = 1.5;
        } else if (this.platform == 3) {
          itemsToShow = 1.6;
        }
      } else {
        if (this.platform == 1) {
          itemsToShow = 1.6;
        } else if (this.platform == 3) {
          itemsToShow = 1.5;
        }
      }

      return {
        itemsToShow: itemsToShow,
        snapAlign: snapAlign,
      };
    },
  },
  methods: {},
  mounted() {},
};
</script>

