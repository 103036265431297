<template>
  <div class="container">
    <div class="my-4 d-flex justify-content-end align-items-center">
      <div class="me-3 is-client-box">
        <select
          class="form-select mb-0"
          v-model="showClient"
          id="adminListProperty"
        >
          <option v-for="(item, key) in isClientList" :key="key" :value="item">
            {{ key }}
          </option>
        </select>
      </div>
      <div class="d-flex align-items-center">
        <label for="admin-propery-search">search: </label>
        <input
          class="form-control client-search"
          type="search"
          v-model="searchText"
          id="admin-propery-search"
        />
      </div>
    </div>
    <div class="position-relative admin-info-container">
      <loading
        v-model:active="isLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div class="accordion mb-4" id="listProperties" v-if="!isLoading">
        <div
          class="accordion-item"
          v-for="(property, name) in filteredData"
          :key="name"
        >
          <h2
            class="accordion-header"
            :id="'heading' + name.replace(/[^A-Z0-9]/gi, '')"
          >
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + name.replace(/[^A-Z0-9]/gi, '')"
              :aria-expanded="false"
              :aria-controls="'collapse' + name.replace(/[^A-Z0-9]/gi, '')"
            >
              <div class="d-flex w-100 align-items-center">
                <div class="flex-grow-1">
                  <router-link
                    type="button"
                    aria-current="page"
                    :to="{
                      name: 'campaigns',
                      params: {
                        propertyId: property._id,
                      },
                    }"
                  >
                    <p class="mb-0 client-info">
                      <b>
                        {{ name }}
                      </b>
                    </p>
                  </router-link>
                </div>
                <div class="me-4">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#addClient"
                    @click.stop="setProperty(property)"
                  >
                    <font-awesome-icon :icon="['fas', 'plus-square']" />
                  </button>
                </div>
              </div>
            </button>
          </h2>
          <div
            :id="'collapse' + name.replace(/[^A-Z0-9]/gi, '')"
            class="accordion-collapse collapse"
            :aria-labelledby="'heading' + name.replace(/[^A-Z0-9]/gi, '')"
            data-bs-parent="#listProperties"
          >
            <div class="accordion-body">
              <DatatableComponent
                :data="property.users"
                :options="{
                  select: true,
                  paging: false,
                  searching: false,
                  info: false,
                  responsive: true,
                }"
                :keys="keys"
                :columns="columns"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="addClient">
    <template v-slot:title> Add Client </template>
    <template v-slot:body>
      <form @submit.prevent="addClient">
        <label for="clients" class="form-label">Client</label>
        <select class="form-select" v-model="user" id="clients">
          <option
            v-for="(client, index) in newClients"
            :key="index"
            :value="client"
          >
            {{ client.userName }} - {{ client.email }}
          </option>
        </select>
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="uploading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ uploading ? "" : "Add" }}
        </button>
      </form>
    </template>
  </Modal>
  <Modal id="removeClient">
    <template v-slot:title>
      Are you sure to remove {{ user.userName }} from access this propertys?
    </template>
    <template v-slot:body>
      <form @submit.prevent="removeClient">
        <Input
          v-model="user.userName"
          type="text"
          label="User Name"
          :disable="true"
        />
        <Input
          v-model="user.email"
          type="text"
          label="User Email"
          :disable="true"
        />
        <button type="submit" class="btn btn-primary wti-btn-black">
          <span
            v-if="uploading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ uploading ? "" : "Remove" }}
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Modal from "@/components/Modal.vue";
import { ref } from "vue";
import api from "@/services/BackendConnection";
import DatatableComponent from "@/components/DatatableComponent.vue";
import $ from "jquery";
import { useRoute } from "vue-router";

export default {
  name: "ListPropertyAdmin",
  setup(props) {
    const route = useRoute();
    const searchText = route.query.searchText || "";
    const showClient = Number(route.query.showClient) || 2;
    return {
      data: ref(null),
      clients: ref([]),
      property: ref(null),
      user: ref({
        _id: null,
        userName: null,
        email: null,
      }),
      uploading: ref(false),
      keys: ["Client Name", "Client Email", "Action"],
      searchText: ref(searchText),
      filteredData: ref(null),
      isClientList: ref({
        All: 1,
        Client: 2,
        Internal: 3,
      }),
      showClient: ref(showClient),
      isLoading: ref(true),
    };
  },
  data() {
    var self = this;
    return {
      columns: [
        {
          data: "userName",
          className: "text-break align-middle",
          defaultContent: "",
        },
        {
          data: "email",
          className: "text-break align-middle",
          defaultContent: "",
        },
        {
          data: null,
          defaultContent: "-",
          orderable: false,
          className: "text-end",
          render: function (data, type, row) {
            if (type === "display") {
              $(document).on(
                "click",
                ".clientAction-" + row.property._id + row._id,
                function () {
                  self.updateUser(row);
                }
              );
            }
            var html =
              ' <button type="button" data-bs-toggle="modal" data-bs-target="#removeClient" class="btn btn-danger clientAction-' +
              row.property._id +
              row._id +
              '" >' +
              '<img src="' +
              self.getImgUrl() +
              '" style="height:12px"/>' +
              "</button>";
            return html;
          },
        },
      ],
    };
  },
  components: {
    Input,
    Select,
    Modal,
    DatatableComponent,
  },
  computed: {
    newClients() {
      var lists = [];
      if (this.clients && this.property) {
        var arrayOne = this.clients;
        var arrayTwo = this.property.users;
        lists = arrayOne
          .filter(
            ({ _id: id1 }) => !arrayTwo.some(({ _id: id2 }) => id2 === id1)
          )
          .sort(function (a, b) {
            return a.userName
              .toLowerCase()
              .localeCompare(b.userName.toLowerCase());
          });
      }
      return lists;
    },
  },
  methods: {
    getPropertyList() {
      var _this = this;
      _this.isLoading = true;
      let user = this.$store.state.user;
      api()
        .getPropertyList()
        .then((res) => {
          if (res.data) {
            _this.data = res.data;
            if (user.isTestAdmin) {
              _this.showClient = 1;
            }
            _this.filteredData = Object.fromEntries(
              Object.entries(res.data).filter(([key, value]) => value.isClient)
            );
            if (_this.searchText != "" || _this.showClient != 2) {
              _this.filterData(_this.searchText, _this.showClient);
            }
            _this.isLoading = false;
          }
        });
    },
    getClientList() {
      var _this = this;
      api()
        .listClientUsers()
        .then((res) => {
          if (res.data) {
            _this.clients = res.data;
          }
        });
    },
    setProperty(property) {
      this.property = property;
    },
    updateUser(item, property) {
      var user = JSON.parse(JSON.stringify(item));
      this.user = user;
      this.property = user.property;
    },
    async addClient(e) {
      e.preventDefault();
      this.uploading = true;
      var info = { ...{ propertyId: this.property._id }, ...this.user };
      api()
        .addPropertyClient(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.rerender("addClientclose");
            this.uploading = false;
          }
        });
    },
    async removeClient(e) {
      e.preventDefault();
      this.uploading = true;
      var info = { ...{ propertyId: this.property._id }, ...this.user };
      api()
        .removePropertyClient(info)
        .then((res) => {
          if (res.data && res.data.success) {
            this.rerender("removeClientclose");
            this.uploading = false;
          }
        });
    },
    rerender(id) {
      const currentSearchText = this.$route.query.searchText || "";
      const currentShowClient = Number(this.$route.query.showClient) || 2;
      if (
        currentSearchText === this.searchText &&
        currentShowClient === this.showClient
      ) {
        var key = this.$store.state.componentKey;
        this.$store.commit("setComponentKey", key + 1);
      } else {
        this.$router.push({
          name: "properties",
          query: { searchText: this.searchText, showClient: this.showClient },
        });
      }
      var myModal = document.getElementById(id);
      myModal.click();
    },
    clear() {
      this.user._id = null;
      this.user.userName = null;
      this.user.email = null;
    },
    getImgUrl() {
      return new URL(`../assets/remove-icon.svg`, import.meta.url).href;
    },
    filterData(text, showClient) {
      var data = JSON.parse(JSON.stringify(this.data));
      var result;
      if (text) {
        result = Object.fromEntries(
          Object.entries(data).filter(([key, value]) =>
            key.toLowerCase().includes(text.toLowerCase())
          )
        );
      } else {
        result = data;
      }
      switch (showClient) {
        case 1:
          this.filteredData = result;
          break;
        case 2:
          this.filteredData = Object.fromEntries(
            Object.entries(result).filter(([key, value]) => value.isClient)
          );
          break;
        case 3:
          this.filteredData = Object.fromEntries(
            Object.entries(result).filter(([key, value]) => !value.isClient)
          );
          break;
      }
    },
  },
  mounted() {
    this.getPropertyList();
    this.getClientList();
    var _this = this;
    var myModals = document.querySelectorAll(".modal");
    myModals.forEach((myModalEl) => {
      myModalEl.addEventListener("hidden.bs.modal", function (event) {
        _this.clear();
      });
    });
  },
  watch: {
    searchText(newVal, oldVal) {
      this.filterData(newVal, this.showClient);
    },
    showClient(val) {
      this.filterData(this.searchText, val);
    },
  },
};
</script>
