<template>
  <div class="d-flex flex-column h-100v w-100">
    <v-idle
      class="d-none"
      @idle="onidle"
      :loop="true"
      :wait="5"
      :duration="idleDuration"
      v-if="isAuthenticated"
    />
    <top-menu />
    <RouterView :key="$route.fullPath + componentKey" />
  </div>
  <Modal id="uploadError">
    <template v-slot:title> Please check! </template>
    <template v-slot:body>
      <div class="instruction">
        {{ uploadErrMsg }}
      </div>
      <button type="button" class="btn wti-btn-black" data-bs-dismiss="modal">
        Close
      </button>
    </template>
  </Modal>
  <Modal id="invalidFileTypeError">
    <template v-slot:title> Please check! </template>
    <template v-slot:body>
      <div class="instruction">
        {{ invalidTypeErr }}
      </div>
      <button type="button" class="btn wti-btn-black" data-bs-dismiss="modal">
        Close
      </button>
    </template>
  </Modal>
</template>

<style lang="sass">
@import "@/assets/vars.sass"

.h-100v
  height: 100vh !important

form
  .password-input
    display: inline-block
  i
    margin-left: -30px
    cursor: pointer

  table.dataTable.table-striped
    thead
      tr
        border-color: transparent
    tbody
      tr
        border-color: transparent
.tabs.channel-section
  padding: 25px 30px 0
  ul
    border-bottom: none
    margin-bottom: 1.5rem !important
  .nav-link
    color: $Main
    border: none
    font-weight: 400
    font-weight: 600
    font-size: 18px
    line-height: 20px
    padding: 0 0 26px
    margin: 0 30px 0 0
    +mobile
      font-size: 14px
      padding: 0 0 15px
      margin: 0 14px 0 0
  .nav-link.active
    color: $Main
    border-bottom: 3px solid $Blue
  .tab-content
    margin-bottom: 32px
    overflow: auto
    .tab-pane
      width: 100%
      position: relative
      height: 250px
      .channel-data-container
        // min-width: 1100px
        min-width: 1025px
        position: absolute
        height: 100%
        width: 98%
        +laptop
          min-width: 810px
        +mobile
          min-width: 525px
  +mobile
    padding: 25px 15px 0
.tabs.prev-section
  ul
    border-bottom: none
    margin-bottom: 0.5rem !important
  .nav-link
    color: $Main
    border: none
    font-weight: 600
    font-size: 14px
    line-height: 16px
    padding: 0 5px 10px 0
    margin: 0 15px 0 0
  .nav-link.active
    color: $Main
    border-bottom: 3px solid $Blue
</style>

<script>
import { RouterLink, RouterView } from "vue-router";
import TopMenu from "./components/TopMenu.vue";
import Modal from "@/components/Modal.vue";
import messages from "@/utils/messages.json";

export default {
  name: "App",
  data() {
    return {
      userlogin: false,
      idleDuration: Number(import.meta.env.VITE_IDLE_DURATION),
      uploadErrMsg: messages.uploadErr,
      invalidTypeErr: messages.invalidTypeErr,
    };
  },
  components: { TopMenu, RouterLink, RouterView, Modal },
  computed: {
    isAuthenticated() {
      return this.$store.state.isUserLoggedIn;
    },
    componentKey() {
      return this.$store.state.componentKey;
    },
  },
  methods: {
    onidle() {
      if (this.isAuthenticated) {
        this.$store.commit("setAllowLeave", true);
        this.$router.push({ name: "logout" });
      }
    },
  },
  mounted() {},
};
</script>
